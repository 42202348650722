import _ from 'lodash'
import CoreApi from '../../api/core'
import { aiServiceApiV2 } from '@/api/aiService'
import TasrNewsModel from '../../model/TasrNews.js'
import TasrNewsFilterService from '../../services/tasr/TasrNewsFilterService'
import config from '../../config'
import TasrNewsFilter from '../../model/TasrNewsFilter'

const API_NAME = '/tasrNews'
const API_NAME_GENERATOR = '/generator'

/**
 * Remove old tasr content from local storage if there are more than 20 items
 */
const removeOldTasrContentLocalStorage = () => {
  const keys = Object.keys(window.localStorage)
  const tasrOriginalContentKeys = keys.filter(key => key.startsWith('tasrOriginalContent_article_'))
  if (tasrOriginalContentKeys.length > 20) {
    const oldestKey = tasrOriginalContentKeys.reduce((prev, curr) => {
      return window.localStorage.getItem(prev) < window.localStorage.getItem(curr) ? prev : curr
    })
    window.localStorage.removeItem(oldestKey)
  }
}

const state = {
  error: null,
  detail: TasrNewsModel,
  list: [],
  totalCount: 0,
  page: 1,
  filter: _.cloneDeep(TasrNewsFilter),
  valueObjectServiceType: [],
  valueObjectStatus: [],
  refactoredArticleGenerating: false,
  tasrOriginalContent: {
    articleId: null,
    content: ''
  }
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  storeValueObjectServiceType (state, responseData) {
    state.valueObjectServiceType = responseData
  },
  storeValueObjectStatus (state, responseData) {
    state.valueObjectStatus = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  setRefactoredArticleGenerating (state, status) {
    state.refactoredArticleGenerating = status
  },
  setTasrOriginalContent (state, { articleId, content }) {
    state.tasrOriginalContent = { articleId, content }
    window.localStorage.setItem(`tasrOriginalContent_article_${articleId}`, content)
    removeOldTasrContentLocalStorage()
  }
}

const actions = {
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * config.defaults.list.limit) - config.defaults.list.limit
    const url = API_NAME +
      '?limit=' + config.defaults.list.limit +
      '&offset=' + offset +
      '&order[contentCreated]=desc' + TasrNewsFilterService.buildFilterQuery(store.state.filter)
    CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchOne (store, id) {
    await CoreApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
  },
  async deleteRecord (store, record) {
    return await CoreApi().delete(API_NAME + '/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async fetchValueObjectServiceType (store) {
    return await CoreApi().get(API_NAME + '/valueObject/serviceType')
      .then(response => {
        store.commit('storeValueObjectServiceType', response.data)
      })
      .catch(error => console.log(error))
  },
  async fetchValueObjectStatus (store) {
    return await CoreApi().get(API_NAME + '/valueObject/status')
      .then(response => {
        store.commit('storeValueObjectStatus', response.data)
      })
      .catch(error => console.log(error))
  },
  async update (store, record) {
    return await CoreApi().put(API_NAME + '/' + record.id, JSON.stringify(record))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async fetchRefactoredArticle (store, record) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    store.commit('setRefactoredArticleGenerating', true)
    try {
      const response = await aiServiceApiV2().post(API_NAME_GENERATOR + '/refactor', JSON.stringify(record))
      store.commit('setRefactoredArticleGenerating', false)
      if (response.status === 200) {
        store.commit('setError', null)
        return response.data
      } else {
        store.commit('setError', 'Error')
        return null
      }
    } catch (error) {
      store.commit('setRefactoredArticleGenerating', false)
      if (error.response && error.response.status === 500) {
        store.commit('setError', error.response.status)
      } else if (error.response && error.response.data) {
        store.commit('setError', error.response.data)
      } else {
        store.commit('setError', error.message || 'Unknown error')
      }
      console.error(error)
      return null
    }
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  valueObjectServiceType (state) {
    return state.valueObjectServiceType
  },
  valueObjectStatus (state) {
    return state.valueObjectStatus
  },
  refactoredArticleGenerating (state) {
    return state.refactoredArticleGenerating
  },
  tasrOriginalContent (state) {
    return state.tasrOriginalContent
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
