const messages = {
  city: 'Mesto',
  region: 'Kraj',
  district: 'Okres',
  street_number: 'Ulica a číslo',
  create_new_city: 'Vytvor',
  create_new_city_message: 'Nové mesto bolo pridané',
  confirm: 'Nastav'
}

export default messages
