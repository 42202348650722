<template>
  <div :class="`eagle-preloader ${onTop ? 'on-top' : ''}`">
    <div>
      <svg
        class="circular"
        viewBox="25 25 50 50"
      >
        <circle
          class="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke-width="2"
          stroke-miterlimit="10"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Preloader',
  props: {
    onTop: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.on-top {
  position: relative;
}
</style>
