<template>
    <span>
        <button type="button"
                v-if="article.setting.status === 'published'"
                @click="clone"
                class="btn btn-success"
                data-test="article_btn_edit_as_new_draft"
        >
            <i class="fas fa-exchange-alt"></i> {{ $t("buttons.edit_as_new_draft") }}
        </button>
        <button type="button"
                v-if="article.setting.status === 'published'"
                @click="changeArticleStatusToDraft"
                class="btn btn-danger"
                data-test="article_btn_unpublish"
        >
            <i class="fas fa-exchange-alt"></i> {{ $t("buttons.unpublish") }}
        </button>
        <button type="button"
                v-if="article.setting.status === 'review'"
                @click="changeArticleStatusToReady"
                class="btn btn-green"
                data-test="article_btn_send_to_publish"
        >
            <i class="fas fa-long-arrow-alt-up"></i> {{ $t("buttons.send_to_publish") }}
        </button>
        <button type="button"
                v-if="article.setting.status === 'review' || article.setting.status === 'ready'"
                @click="changeArticleStatusToDraft"
                class="btn btn-success"
                data-test="article_btn_send_to_draft"
        >
            <i class="fas fa-exchange-alt"></i> {{ $t("buttons.send_to_draft") }}
        </button>
        <button type="button"
                v-if="article.setting.status === 'draft'"
                @click="changeArticleStatusToReadyFromDraft"
                class="btn btn-green test123"
                data-test="article_btn_force_publish"
        >
            <i class="fas fa-long-arrow-alt-up"></i> {{ $t("buttons.send_to_publish") }}
        </button>
        <button type="button"
                v-if="article.setting.status === 'draft'"
                @click="changeArticleStatusToReview"
                class="btn btn-success"
                data-test="article_btn_send_to_review"
        >
            <i class="fas fa-exchange-alt"></i> {{ $t("buttons.send_to_review") }}
        </button>
    </span>
</template>
<script>
import CoreApi from '../../api/core'
import NotifyService from '../../services/NotifyService'
import ArticleValidationService from '../../services/article/ArticleValidationService'
import NmhEditorBlockValidation from '@/components/nmhEditor/BlockValidation'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'ArticleTransitionsButtons',
  props: {
    updateDraft: {
      type: Function
    }
  },
  computed: {
    ...mapState({
      nmhEditorStoreData: state => state.nmhEditorStore.data
    }),
    ...mapGetters(['vlm']),
    article () {
      return this.$store.getters['article/detail']
    }
  },
  methods: {
    clone () {
      const article = this._.cloneDeep(this.article)
      article.setting.status = 'draft'
      this.$store.dispatch('article/create', article)
        .then(() => {
          if (this.$store.getters['article/error']) {
            if (this.$store.getters['article/error'] === 'error_article_in_draft_exists') {
              NotifyService.setErrorMessage(this.$t('article.notify.article_in_draft_exists'))
            } else if (this.$store.getters['article/error'] === 'error_article_in_review_exists') {
              NotifyService.setErrorMessage(this.$t('article.notify.article_in_review_exists'))
            } else if (this.$store.getters['article/error'] === 'error_article_in_ready_exists') {
              NotifyService.setErrorMessage(this.$t('article.notify.article_in_ready_exists'))
            } else {
              NotifyService.setErrorMessage(this.$store.getters['article/error'])
            }
          } else {
            NotifyService.setSuccessMessage(this.$t('article.notify.new_draft_created_from_published'))
            this.$router.push('/article/' + this.article.id + '/edit')
          }
        })
        .catch((error) => {
          NotifyService.setErrorMessage(error)
        })
    },
    changeArticleStatusToDraft () {
      CoreApi().patch('article/' + this.article.id + '/status/draft')
        .then((response) => {
          if (response.status !== 200) {
            NotifyService.setErrorMessage(response.statusText)
            return
          }
          NotifyService.setSuccessMessage(this.$t('article.notify.article_status_changed_to_draft'))
          this.$router.push('/article/' + this.article.id + '/edit')
        })
        .catch((error) => {
          if (error.response.status === 403) {
            NotifyService.setErrorMessage(this.$t('article.notify.article_status_changed_to_draft_forbidden'))
          } else {
            NotifyService.setErrorMessage(error)
          }
        })
    },
    changeArticleStatusToReview () {
      if (this.vlm) {
        const validBlocks = this.validateBlock()
        this.article.bodyBlocks = [...validBlocks]
      }
      if (!this.vlm || this.isValid()) {
        this.updateDraft()
          .then(() => {
            if (this.isValid()) {
              CoreApi().patch('article/' + this.article.id + '/status/review')
                .then((response) => {
                  if (response.status !== 200) {
                    NotifyService.setErrorMessage(response.statusText)
                    return
                  }
                  NotifyService.setSuccessMessage(this.$t('article.notify.article_status_changed_to_review'))
                  this.$router.push('/article/' + this.article.id)
                })
                .catch((error) => {
                  this.parseErrorResponse(error.response)
                })
            }
          })
      }
    },
    changeArticleStatusToReady () {
      CoreApi().patch('article/' + this.article.id + '/status/ready')
        .then((response) => {
          if (response.status !== 200) {
            NotifyService.setErrorMessage(response.statusText)
            this.$router.push('/article/' + this.article.id)
            return
          }
          // TODO
          NotifyService.setSuccessMessage(this.$t('article.notify.article_status_changed_to_ready'))
          this.$router.push('/article')
        })
        .catch((error) => {
          if (error.response.status === 403) {
            NotifyService.setErrorMessage(this.$t('article.notify.article_status_changed_to_ready_forbidden'))
          } else {
            NotifyService.setErrorMessage(error)
          }
          this.$router.push('/article/' + this.article.id)
        })
    },
    changeArticleStatusToReadyFromDraft () {
      if (this.vlm) {
        const validBlocks = this.validateBlock()
        this.article.bodyBlocks = [...validBlocks]
      }
      if (!this.vlm || this.isValid(true)) {
        this.updateDraft()
          .then(() => {
            if (this.isValid(true)) {
              CoreApi().patch('article/' + this.article.id + '/status/review')
                .then((response) => {
                  if (response.status !== 200) {
                    NotifyService.setErrorMessage(response.statusText)
                    return
                  }
                  NotifyService.setSuccessMessage(this.$t('article.notify.article_status_changed_to_review'))
                  this.changeArticleStatusToReady()
                })
                .catch((error) => {
                  NotifyService.setErrorMessage(error)
                })
            }
          })
      }
    },
    validateBlock () {
      return this.nmhEditorStoreData.blocks.filter(block => {
        const currentBlock = NmhEditorBlockValidation.blocks.find(item => item.type === block.type)
        const isValid = currentBlock && currentBlock.validation(block.properties)
        if (isValid && currentBlock.transform) {
          currentBlock.transform(block.properties)
        }
        return isValid
      })
    },
    isValid (toReady = false) {
      const errors = ArticleValidationService.hasErrors(this.article, toReady)
      if (errors) {
        this.$emit('show-errors', errors)
        return false
      }
      return true
    },
    parseErrorResponse (errorResponse) {
      let fields = ''
      for (const field in errorResponse.data) {
        fields = fields + field + ', '
      }

      NotifyService.setErrorMessage(this.$t('article.notify.please_fill_all_required_fields'), fields)
    }
  }
}
</script>
