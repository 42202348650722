const messages = {
  city: 'City',
  region: 'Region',
  district: 'District',
  street_number: 'Street and number',
  create_new_city: 'Create',
  create_new_city_message: 'New city was created',
  confirm: 'Set'
}

export default messages
