<template>
  <button
    class="btn-search"
    type="submit"
    @click="$emit('click', $event.target.value)"
  >
    <i class="btn-search__icon fa fa-search"></i>
  </button>
</template>

<script>
export default {
  name: 'ButtonSearch'
}
</script>

<style scoped lang="scss">
  .btn-search {
    width: rem(40px);
    height: rem(40px);
    border-radius: rem(6px);
    background: #6599fe;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background 100ms;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: darken(#6599FE, 10%);
    }
    &__icon {
      font-size: rem(14px)
    }
  }
</style>
