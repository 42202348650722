import KpiArticlesCounter from './KpiArticlesCounter'
import KpiVideosCounter from './KpiVideosCounter'

export default {
  userId: 0,
  userDepartmentId: 0,
  userName: '',
  printQualityA: 0.0,
  printQualityB: 0.0,
  printQualityC: 0.0,
  pageViews: 0.0,
  pageViewsNormalized: 0.0,
  articles: 0.0,
  galleryViews: 0.0,
  engagedPageView: 0.0,
  subscriptions: 0.0,
  disqus: 0.0,
  fbShares: 0.0,
  videoViews: 0.0,
  videoPlaythrough: 0.0,
  printQuantityP1Author: 0.0,
  printQuantityP1Agency: 0.0,
  printQuantityP2Author: 0.0,
  printQuantityP2Agency: 0.0,
  onlineAuthor: 0.0,
  fromPrint: 0.0,
  agency: 0.0,
  republished: 0.0,
  quiz: 0.0,
  photostory: 0.0,
  videoInternal: 0.0,
  videoSlideshow: 0.0,
  videoSocial: 0.0,
  videoAgency: 0.0,
  aiAssisted: 0.0,
  images: 0,
  videos: 0,
  departmentName: '',
  articlesWithVideo: 0,
  articleTypesCounter: KpiArticlesCounter,
  videoTypesCounter: KpiVideosCounter,
  articlesTotal: 0.0,
  articleCompletion: 0.0,
  dailyUsers: 0.0,
  galleryCompletion: 0.0,
  timeSpentPerUserArticle: 0.0,
  timeSpentPerUserGallery: 0.0,
  productArticlesCounter: 0,
  avgArticleCompletion: 0,
  avgDailyUsers: 0,
  avgGalleryCompletion: 0,
  avgTimeSpentPerUserArticle: 0,
  avgTimeSpentPerUserGallery: 0,
  articlesAuthored: 0
}
