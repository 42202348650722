import { dateTimeNow } from '../services/DateTimeService'
import Flag from './Flag'
import { TYPE_DEFAULT } from './ValueObject/ContentBlockItemTypes'
import { SOURCE_USER_DEFINED } from './ValueObject/ContentBlockItemSources'

export default {
  id: null,
  contentBlock: 0,
  type: TYPE_DEFAULT,
  articleDocumentId: '',
  articlePublished: 0,
  alternativeTitle: '',
  position: 0,
  publishedSince: dateTimeNow(),
  publishedUntil: null,
  title: '',
  url: '',
  image: null,
  firstParagraph: '',
  externalSiteName: '',
  siteUrl: '',
  siteRubricName: '',
  siteRubricUrl: '',
  externalAuthor: '',
  flag: Flag,
  prArticle: false,
  source: SOURCE_USER_DEFINED,
  video: null,
  videoInfo: {}
}
