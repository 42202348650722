<template>
  <span class="checkbox checkbox-success" :style="`display: ${styleDisplay}`" @mousedown="$emit('mousedown')">
    <input
      type="checkbox"
      :id="id"
      class="filled-in"
      @change="$emit('input', $event.target.checked)"
      :checked="value"
      :disabled="disabled"
      :data-test="dataAttr ? dataAttr : id"
    >
    <label class="custom-control custom-checkbox" :for="id">
      {{ label }}
    </label>
    <app-tooltip v-if="tooltip" :title="tooltip"></app-tooltip>
  </span>
</template>

<script>
import Tooltip from '../tooltip/Tooltip'

export default {
  name: 'Checkbox',
  props: {
    btnLook: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    status: {
      type: String
    },
    value: {},
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: ''
    },
    styleDisplay: {
      type: String,
      default: 'block'
    },
    dataAttr: {
      type: String,
      required: false,
      default: ''
    }
  },
  components: {
    appTooltip: Tooltip
  }
}
</script>

<style lang="scss">
  .checkbox {
    &__label {
      font-family: "Roboto", sans-serif;
      font-size: 0.8125rem;
      font-weight: 500;
      color: #fff;
    }
  }
  [type=checkbox].filled-in:checked+label:after {
    border: 2px solid #4caf50;
    background-color: #4caf50;
  }
</style>
