<template>
  <div class="col-lg-6">
    <div class="card card-body">
      <h3>{{ contentBlock.title }}</h3>
      <app-preloader v-if="loading"></app-preloader>
      <div style="height: 300px;" class="m-b-20">
        <app-line-chart
          :chart-data="playlistChartData"
          :height="300"
          :max-labels="24"
        >
        </app-line-chart>
      </div>
      <table class="table table-hover" v-if="positions.length > 0">
        <thead>
        <tr>
          <th>Player</th>
          <th>Performance - VideoPlays</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(player, index) in positions" :key="`item-${index}`">
          <td>{{ player.position }}</td>
          <td>
            <app-progress-bar :value="player.videoPlays" :max="getMax"></app-progress-bar>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Preloader from '@/components/preloader/Preloader'
import LineChart from '@/components/chart/LineChart'
import EdonApi from '@/api/edon'
import ProgressBar from '../videoStats/ProgressBar'

export default {
  name: 'PlaylistChart',
  props: {
    contentBlock: {
      type: Object
    }
  },
  data () {
    return {
      loading: false,
      playlistChartData: {
        labels: [],
        datasets: []
      },
      positions: []
    }
  },
  computed: {
    getMax () {
      let maxInitialPlays = 0
      this.positions.forEach(player => {
        if (player.initialPlays > maxInitialPlays) {
          maxInitialPlays = player.initialPlays
        }
      })
      return maxInitialPlays
    }
  },
  components: {
    appLineChart: LineChart,
    appPreloader: Preloader,
    appProgressBar: ProgressBar
  },
  methods: {
    formatTimeGroupDates (timeGroups, format = 'DD.MM HH:mm', addDay = 0) {
      return timeGroups.map(timeGroup => {
        if (addDay > 0) {
          return moment(timeGroup).add(addDay, 'days').format(format)
        }
        return moment(timeGroup).format(format)
      })
    },
    getVideoStatsPlaylist () {
      this.loading = true
      EdonApi().get('/videoStats/playlist/' + this.contentBlock.identifier)
        .then(response => {
          this.playlistChartData = {
            labels: [
              '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00',
              '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00',
              '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'
            ],
            datasets: [
              {
                label: 'Today',
                borderColor: '#1693c1',
                backgroundColor: 'rgba(22, 147, 193, 0.4)',
                data: response.data.today.metrics
              },
              {
                label: 'Last week',
                backgroundColor: 'rgba(204, 204, 204, 0.5)',
                data: response.data.lastWeek.metrics
              }
            ]
          }
          this.positions = response.data.positions
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.getVideoStatsPlaylist()
  }
}
</script>

<style lang="scss">

</style>
