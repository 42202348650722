export const VIDEO_STATUS_NEW = 'new'
export const VIDEO_STATUS_UPLOADING = 'uploading'
export const VIDEO_STATUS_PROCESSING = 'processing'
export const VIDEO_STATUS_LIVE = 'live'
export const VIDEO_STATUS_PUBLISHED = 'published'
export const VIDEO_STATUS_ERROR = 'error'

export const VIDEO_STATUS_DEFAULT = VIDEO_STATUS_NEW

export default {
  computed: {
    videoStatusValues () {
      return [
        {
          id: VIDEO_STATUS_NEW,
          title: this.$t('video.video_status_value.new')
        },
        {
          id: VIDEO_STATUS_UPLOADING,
          title: this.$t('video.video_status_value.uploading')
        },
        {
          id: VIDEO_STATUS_PROCESSING,
          title: this.$t('video.video_status_value.processing')
        },
        {
          id: VIDEO_STATUS_LIVE,
          title: this.$t('video.video_status_value.live')
        },
        {
          id: VIDEO_STATUS_PUBLISHED,
          title: this.$t('video.video_status_value.published')
        },
        {
          id: VIDEO_STATUS_ERROR,
          title: this.$t('video.video_status_value.error')
        }
      ]
    }
  }
}
