<template>
  <ModuleForm
    ref="quizForm"
    :module-name="$t('moduleForm.quiz')"
    :module="quiz"
    :show-header-info="disabled"
    :tabs="tabs"
    @tab-change="(index) => tabIndex = index"
  >
    <template #header-buttons>
      <span
        class="article-exists-info"
        v-if="quizArticles.length > 0"
      >
        <i class="fas fa-info-circle"></i> {{ $t('quiz.quiz_article_exists') }}
      </span>
      <QuizCreateArticleButton
        v-if="quiz.id"
        :quiz="quiz"
        @set-enabled-and-save="setEnabledAndSave"
      />
      <ModuleFormButton
        v-if="disabled"
        icon="edit"
        @click="goToEdit"
      />
      <ModuleFormButton
        v-else
        :title="$t('buttons.save')"
        @click="save"
      />
      <ModuleFormDeleteButton
        v-if="quiz.id"
        :record-id="quiz.id"
        store-name="quiz"
        list-router-path="quiz"
      />
      <ModuleFormButton
        icon="close"
        @click="$router.push('/quiz')"
      />
    </template>
    <template #left-column>
      <QuizFormMainTabLeftColumn
        v-show="tabIndex === 0"
        ref="mainLeftColumn"
        :quiz="quiz"
        :saved-quiz-type="savedQuizType"
        :saved-quiz-layout="savedQuizLayout"
        :disabled="isDisabledOrGenerating"
        @generate-quiz="handleGenerateQuiz"
        :isGenerating="quizGenerating"
      />
      <QuizFormQuestionTabLeftColumn
        v-show="tabIndex === 1"
        ref="questionLeftColumn"
        :quiz="quiz"
        :disabled="isDisabledOrGenerating"
        @answer-points-change="countScoreMaxPoints"
      />
    </template>
    <template #right-column>
      <div v-show="tabIndex === 0">
        <QuizFormMainTabRightColumn
          ref="mainRightColumn"
          :quiz="quiz"
          :disabled="isDisabledOrGenerating"
        />
        <div v-if="quiz.id" class="used-in-articles">
          <UsedInArticlesTable
            filter="quiz"
            :id="quiz.id"
            @set-data="setQuizArticles"
          />
        </div>
      </div>
      <QuizFormQuestionTabRightColumn
        v-show="tabIndex === 1"
        ref="questionRightColumn"
        :quiz="quiz"
        :disabled="isDisabledOrGenerating"
        :score-max-points="scoreMaxPoints"
      />
    </template>
  </ModuleForm>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { shuffle } from 'lodash'
import QuizModel from '@/model/QuizModel'
import NotifyService from '@/services/NotifyService'
import QuizCreateArticleButton from '@/components/quiz/QuizCreateArticleButton'
import UsedInArticlesTable from '@/components/article/UsedInArticlesTable'
import ModuleForm from '@/components/shared/ModuleForm'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import QuizFormQuestionTabRightColumn from '@/components/quiz/form/QuizFormQuestionTabRightColumn'
import QuizFormMainTabRightColumn from '@/components/quiz/form/QuizFormMainTabRightColumn'
import QuizFormMainTabLeftColumn from '@/components/quiz/form/QuizFormMainTabLeftColumn'
import QuizFormQuestionTabLeftColumn from '@/components/quiz/form/QuizFormQuestionTabLeftColumn'

export default {
  name: 'QuizNewView',
  data () {
    return {
      quiz: this._.cloneDeep(QuizModel),
      quizArticles: [],
      tabs: [
        { name: this.$t('quiz.main_tab'), error: false },
        { name: this.$t('quiz.questions_results_tab'), error: false }
      ],
      tabIndex: 0,
      scoreMaxPoints: 0,
      savedQuizType: null,
      savedQuizLayout: null,
      quizResultsScore: [0, 3, 7, 9],
      result: null
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    QuizFormQuestionTabLeftColumn,
    QuizFormMainTabLeftColumn,
    QuizFormMainTabRightColumn,
    QuizFormQuestionTabRightColumn,
    ModuleForm,
    ModuleFormDeleteButton,
    ModuleFormButton,
    QuizCreateArticleButton,
    UsedInArticlesTable
  },
  computed: {
    ...mapState('quiz', ['quizGenerating']),
    ...mapGetters('quiz', ['generatedQuiz', 'quizTheme']),
    ...mapActions('aiStore', ['logAiFeaturesUsage']),
    aiFeaturesUsagePayload () {
      return {
        aiFeatureType: 'quiz',
        userId: this.$store.getters['user/currentUser'].id,
        quizTheme: this.quizTheme
      }
    },
    isDisabledOrGenerating () {
      return this.disabled || this.quizGenerating
    }
  },
  methods: {
    getQuiz () {
      this.$store.dispatch('quiz/fetchOne', this.$route.params.id)
        .then(() => {
          this.quiz = this.$store.getters['quiz/detail']
          this.savedQuizType = this.quiz.type
          this.savedQuizLayout = this.quiz.setting.layout
          this.countScoreMaxPoints()
        })
        .catch(() => {
          NotifyService.setErrorMessage(this.$store.getters['quiz/error'])
        })
    },
    prepareQuizRequest (originalQuiz) {
      const quiz = this._.cloneDeep(originalQuiz)
      if (quiz.publishedUntil === '') {
        delete quiz.publishedUntil
      }
      quiz.categories = quiz.categories.map(category => {
        return category.id
      })
      return quiz
    },
    prepareQuizQuestionRequest (question) {
      question.quiz = this.quiz.id
      question.answers.map(answer => {
        if (answer.imageInfo) {
          answer.image = answer.imageInfo.id
        }
      })
      return question
    },
    isFormInvalid () {
      const formRefs = [this.$refs.mainLeftColumn, this.$refs.mainRightColumn,
        this.$refs.questionLeftColumn, this.$refs.questionRightColumn]
      let invalid = false
      formRefs.forEach((ref, idx) => {
        if (ref?.$v) {
          const tabIndex = [0, 1].includes(idx) ? 0 : 1
          this.tabs[tabIndex].error = ref.$v.$invalid
          ref.$v.$touch()
          invalid = invalid || ref.$v.$invalid
        }
      })
      return invalid
    },
    async saveQuestions () {
      for (const question of this.quiz.questions) {
        const action = question.id ? 'update' : 'create'
        await this.$store.dispatch(`quizQuestion/${action}`, this.prepareQuizQuestionRequest(question))
          .then(() => {
            if (this.$store.getters['quizQuestion/error'] !== null) {
              console.error(this.$store.getters['quizQuestion/error'])
              NotifyService.setErrorMessage(this.$store.getters['quizQuestion/error'])
            }
          })
      }
    },
    async save () {
      if (this.isFormInvalid()) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      const action = this.quiz.id ? 'update' : 'create'
      const successNotify = this.quiz.id ? 'record_was_updated' : 'record_was_created'
      this.$store.dispatch(`quiz/${action}`, this.prepareQuizRequest(this.quiz))
        .then(async () => {
          if (this.$store.getters['quiz/error'] === null) {
            NotifyService.setSuccessMessage(this.$t(`notify.${successNotify}`))
            this.quiz.id = this.$store.getters['quiz/detail'].id
            await this.saveQuestions()
            this.goToEdit()
          } else {
            NotifyService.setErrorMessage(this.$store.getters['quiz/error'])
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
    setEnabledAndSave () {
      this.quiz.enabled = true
      this.$store.dispatch('quiz/update', this.prepareQuizRequest(this.quiz))
    },
    setQuizArticles (articles) {
      this.quizArticles = articles
    },
    goToEdit () {
      const path = '/quiz/' + this.quiz.id + '/edit'
      if (this.$router.currentRoute.path === path) {
        // reloads quiz with all its questions
        this.getQuiz()
      } else {
        this.$store.commit('quiz/setPreviousTabIndex', this.tabIndex)
        this.$router.push(path)
      }
    },
    countScoreMaxPoints () {
      this.scoreMaxPoints = 0
      this.quiz.questions.forEach(question => {
        let maxAnswerPoints = 0
        question.answers.forEach(answer => {
          if (maxAnswerPoints < answer.points) {
            maxAnswerPoints = answer.points
          }
        })
        this.scoreMaxPoints = +this.scoreMaxPoints + +maxAnswerPoints
      })
    },
    async handleGenerateQuiz (quizTheme) {
      this.fetchAndProcessQuizByTheme(quizTheme)
        .then(this.processQuizResult)
        .then(this.logAiFeatureUsage)
        .catch(error => {
          console.error(error)
          NotifyService.setErrorMessage(error)
        })
    },
    async fetchAndProcessQuizByTheme (quizTheme) {
      try {
        await this.$store.dispatch('quiz/fetchByTheme', quizTheme)
        return this.generatedQuiz
      } catch (error) {
        throw new Error(this.$t('notify.fetch_quiz_error'))
      }
    },
    processQuizResult (result) {
      const apiQuestions = result.questions
      const apiQuizResultMessages = result.quizResultMessages
      const transformedQuestions = this.transformApiQuestions(apiQuestions)
      const transformedResultMessages = this.transformApiResultMessages(apiQuizResultMessages)
      this.$set(this.quiz, 'title', result.quizTitle)
      this.$set(this.quiz, 'description', result.quizLeadParagraph)
      this.$set(this.quiz, 'questions', transformedQuestions)
      this.$set(this.quiz, 'results', transformedResultMessages)
      this.countScoreMaxPoints()
    },
    logAiFeatureUsage () {
      this.$store.dispatch('aiStore/logAiFeaturesUsage', this.aiFeaturesUsagePayload)
    },
    transformApiQuestions (apiQuestions) {
      return apiQuestions.map((apiQuestion, index) => {
        let answers = [
          ...apiQuestion.trueAnswers.map((answer) => ({
            id: null,
            title: answer,
            description: '',
            image: null,
            imageInfo: null,
            position: 0,
            points: 1, // True answers get 1 point
            voted: 0
          })),
          ...apiQuestion.falseAnswers.map((answer) => ({
            id: null,
            title: answer,
            description: '',
            image: null,
            imageInfo: null,
            position: 0,
            points: 0, // False answers get 0 points
            voted: 0
          }))
        ]

        answers = shuffle(answers)

        return {
          id: null,
          title: apiQuestion.question,
          description: apiQuestion.trueAnswerParagraph,
          image: null,
          imageInfo: null,
          position: 0,
          answers: answers
        }
      })
    },
    transformApiResultMessages (apiQuizResultMessages) {
      return apiQuizResultMessages.map((apiResultMessage, index) => ({
        id: null,
        title: apiResultMessage.shortMessage,
        description: apiResultMessage.infoMessage,
        image: null,
        score: this.quizResultsScore[index]
      }))
    }
  }
}
</script>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
.article-exists-info {
  font-size: 75%;
  color: #FFFFFF;
  background-color: #FFB22B;
  border-radius: rem(4px);
  padding: rem(5px);
}
.used-in-articles {
  margin-top: 1rem;
}
</style>
