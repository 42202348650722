<template>
  <div class="ai-select-modal__form">
    <div v-if="generatorType === 'title'" class="title-wrapper">
      <div
        v-for="(item, index) in itemsWithValues"
        :key="`AiSelectModalForm_input_${index}`"
        class="ai-select-modal__item"
      >
        <span class="checkbox-title">B</span>
        <Checkbox
          v-model="item.selectedB"
          @input="emitSelections(index, 'B')"
          :id="`AiSelectModalForm_checkboxB_${index}`"
        />
        <span class="checkbox-title">C</span>
        <Checkbox
          v-model="item.selectedC"
          @input="emitSelections(index, 'C')"
          :id="`AiSelectModalForm_checkboxC_${index}`"
        />
        <Input
          show-counter
          v-model="item.value"
          :id="`AiSelectModalForm_input_${index}`"
          @blur="emitSelections()"
        />
      </div>
    </div>
    <div v-if="generatorType === 'keyword'" class="keyword-wrapper">
      <div
        v-for="(item, index) in itemsWithValues"
        :key="`AiSelectedModalForm_keyword_${index}`"
        class="ai-select-modal__keyword"
      >
        <div class="keyword-inputs">
          <Checkbox
            v-model="item.selected"
            @input="emitSelectKeywords()"
            :disabled="item.err"
            :id="`AiSelectModalForm_checkbox_keyword_${index}`"
          />
          <Input
            v-model="item.value"
            :id="`AiSelectModalForm_input_keyword_${index}`"
            @input="validateInputLength(item, index)"
            @blur="emitSelectKeywords()"
            :class="{'disabled': item.err}"
          />
        </div>
        <span v-if="item.err" class="err-msg">
          <i class="fa fa-exclamation-triangle"></i>
          {{ $t(item.errMsg) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/form/inputs/Input'
import Checkbox from '@/components/form/Checkbox'

export default {
  name: 'AiSelectModalForm',
  props: {
    generatorType: {
      type: String, // 'title, 'perex', 'keyword'
      required: true
    },
    generatedItems: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Input,
    Checkbox
  },
  data () {
    return {
      itemsWithValues: this.createItemsWithValues(this.generatedItems),
      keywordMinLength: 2
    }
  },
  methods: {
    createItemsWithValues (items) {
      if (this.generatorType === 'title') {
        return items.map(item => ({
          value: item,
          selectedB: false,
          selectedC: false
        }))
      } else if (this.generatorType === 'keyword') {
        return items.map(item => ({
          value: item,
          selected: true,
          err: false,
          errMsg: null
        }))
      } else return []
    },
    emitSelections (itemIndex, variant) {
      const newItems = this.itemsWithValues.map((item, index) => {
        if (variant === 'B' && index !== itemIndex) {
          return { ...item, selectedB: false }
        } else if (variant === 'C' && index !== itemIndex) {
          return { ...item, selectedC: false }
        }
        return item
      })

      this.itemsWithValues = newItems

      const selectedBValue = this.itemsWithValues.find(item => item.selectedB)?.value
      const selectedCValue = this.itemsWithValues.find(item => item.selectedC)?.value
      const updates = []

      if (selectedBValue !== undefined) {
        updates.push({ variant: 'b', selectedBValue })
      }
      if (selectedCValue !== undefined) {
        updates.push({ variant: 'c', selectedCValue })
      }
      this.$emit('updateSelections', updates)
    },
    validateInputLength (item, index) {
      const otherKeywordValues = this.itemsWithValues
        .map(k => k.value)
        .filter((_, idx) => idx !== index)
      const found = otherKeywordValues.includes(item.value)

      if (item.value.length < this.keywordMinLength) {
        item.selected = false
        item.err = true
        item.errMsg = 'article.ai_service.ai_modal.modal_input_err_min'
      } else if (found) {
        item.selected = false
        item.err = true
        item.errMsg = 'article.ai_service.ai_modal.modal_input_err_duplicate'
      } else {
        item.selected = true
        item.err = false
        item.errMsg = null
      }
    },
    emitSelectKeywords () {
      const newItems = this.itemsWithValues.filter(keyword => keyword.selected)
      this.$emit('updateSelections', newItems)
    }
  },
  created () {
    this.emitSelectKeywords()
  }
}
</script>

<!-- Global CSS -->
<style lang="scss">

.ai-select-modal__form {
  .input-area__label {
    display: none;
  }
}
.ai-select-modal__keyword .disabled input {
  border: 1px solid #fc4b6c;
}

</style>

<!-- Scoped CSS -->
<style lang="scss" scoped>

.ai-select-modal__form {
  margin: 1rem 0.75rem;
  .ai-select-modal__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0.5rem 0;
    .checkbox-title {
      margin: 0 1rem;
      font-size: 1rem;
      font-weight: bolder;
    }
  }
  .keyword-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .ai-select-modal__keyword {
    flex: 0 1 calc(50% - 1rem);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0.5rem;
  }
  .keyword-inputs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-group {
    margin: 0 1rem;
    width: 100%;
  }
  .checkbox {
    height: 1.2rem;
  }
}

.err-msg {
  margin-top: 0.2rem;
  color: #fc4b6c;
  font-size: 0.85rem;
}

</style>
