<template>
  <div class="card">
    <div class="card-body">
      <table class="table table-striped">
        <thead>
        <tr>
          <th></th>
          <th class="text-right">{{ $t('don.npa.original_daily_calculation') }}</th>
          <th class="text-right">{{ $t('don.npa.first_day_estimate') }}</th>
          <th class="text-right">{{ $t('don.npa.second_day_estimate') }}</th>
          <th class="text-right">{{ $t('don.npa.third_day_estimate') }}</th>
          <th class="text-right">{{ $t('don.npa.fourth_day_estimate') }}</th>
          <th class="text-right">{{ $t('don.npa.fifth_day_estimate') }}</th>
          <th class="text-right">{{ $t('don.npa.sixth_day_estimate') }}</th>
          <th class="text-right">{{ $t('don.npa.seventh_day_estimate') }}</th>
          <th class="text-right"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, index) in articleRows" :key="`item-${index}`">
          <td :class="{ 'text-green' : row.status === 4 }">{{ row.title }}</td>
          <td class="text-right" :class="{ 'text-green' : row.status === 4 }">{{ row.originalEstimate }}</td>
          <td class="text-right" :class="{ 'text-green' : row.status === 4 }"
              v-for="(dailyEstimate, index) in row.dailyEstimates" :key="`item-${index}`">{{ dailyEstimate }}
          </td>
          <td></td>
        </tr>
        <tr>
          <td class="font-weight-bold">{{ $t('don.npa.total_daily_campaign_performance') }}</td>
          <td></td>
          <td class="text-right" :class="highlightPerformance(dailyPerformance, index)"
              v-for="(dailyPerformance, index ) in totalCampaignPerformance" :key="`item-${index}`">{{ dailyPerformance }}
          </td>
          <td></td>
        </tr>
        <tr>
          <td class="font-weight-bold">{{ $t('don.npa.predicted_estimate_by_campaigns') }}</td>
          <td></td>
          <td class="text-right font-weight-bold"
              v-for="(predictedEstimate, index) in predictedEstimateByCampaigns" :key="`item-${index}`">{{ predictedEstimate }}
          </td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PredictionsTable',
  data () {
    return {}
  },
  props: {
    data: {
      type: Object
    }
  },
  components: {},
  computed: {
    articleRows () {
      for (const row of this.data.articleRows) {
        const dailyEstimates = row.dailyEstimates

        for (let i = 0; i < 7; i++) {
          const dailyEstimate = dailyEstimates[i]

          if (!dailyEstimate) {
            dailyEstimates.splice(i, 0, '-')
          }
        }
      }

      return this.data.articleRows
    },
    totalCampaignPerformance () {
      const dailyPerformances = this.data.totalCampaignPerformance

      for (let i = 0; i < 7; i++) {
        const dailyPerformance = dailyPerformances[i]

        if (!dailyPerformance) {
          dailyPerformances.splice(i, 0, '-')
        }
      }

      return this.data.totalCampaignPerformance
    },
    predictedEstimateByCampaigns () {
      return this.data.predictedEstimateByCampaigns
    }
  },
  methods: {
    highlightPerformance (dailyCampaignsPerformance, predictedEstimateIdx) {
      if (dailyCampaignsPerformance === '-') {
        return
      }

      const predictedEstimate = this.predictedEstimateByCampaigns[predictedEstimateIdx]

      if (dailyCampaignsPerformance > predictedEstimate) {
        const ratio = dailyCampaignsPerformance / predictedEstimate

        if (ratio > 1.2) {
          return 'text-danger'
        } else if (ratio <= 1.2 && ratio > 1) {
          return 'text-warning'
        }
      }
    }
  }
}
</script>

<style lang="scss">
table {
  th {
    &:nth-child(1) {
      width: 26%;
    }

    &:nth-child(2) {
      width: 10%;
    }

    &:nth-child(n+3) {
      width: 8%;
    }
  }
}

.text-green {
  color: #41b883;
}
</style>
