<template>
  <div style="text-align: right">
    <button
      class="btn btn-xs btn-info m-b-5"
      :title="$t('article.create_new_tag')"
      v-if="searchedTag !== ''"
      @click="createTag"
    >
      <i class="fa fa-plus"></i>
      {{ $t('article.create_new_tag') }} <strong>{{ searchedTag }}</strong>
    </button>
  </div>
</template>

<script>
import CoreApi from '../../api/core'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'CreateTagButton',
  props: {
    searchedTag: {
      type: String
    }
  },
  methods: {
    createTag () {
      CoreApi().post('/tag', { title: this.searchedTag })
        .then((response) => {
          NotifyService.setSuccessMessage(this.$t('article.create_new_tag_message'))
          this.$emit('set-tag', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
