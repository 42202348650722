<template>
  <div
    class="checkbox"
    :class="{ 'checkbox--enabled': !disabled, 'checkbox--no-label-margin': noLabelMargin }"
    @click="checked"
  >
    <div class="checkbox__label">
      {{ label }}
      <Tooltip v-if="tooltip" :title="tooltip"></Tooltip>
    </div>
    <input
      type="checkbox"
      :id="id"
      class="filled-in"
      @click="checked"
      :checked="value"
      :disabled="disabled"
      :data-test="dataAttr ? dataAttr : id"
    >
    <label :for="id" class="checkbox__checkbox" />
  </div>
</template>

<script>
import Tooltip from '@/components/tooltip/Tooltip'

export default {
  name: 'OutlinedCheckbox',
  props: {
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: ''
    },
    dataAttr: {
      type: String,
      required: false,
      default: ''
    },
    noLabelMargin: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Tooltip
  },
  methods: {
    checked () {
      if (!this.disabled) {
        this.$emit('input', !this.value)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.checkbox {
  display: flex;
  border: rem(1px) solid #D1DBE4;
  border-radius: rem(5px);
  padding: rem(4px) rem(10px) rem(4px) rem(10px);
  margin-top: 1rem;
  &--no-label-margin {
    margin-top: 2.7rem;
  }
  &--enabled {
    background-color: #FFFFFF;
    cursor: pointer;
  }
  &__label {
    padding-top: rem(3px);
    padding-right: rem(3px);
    color: #8A96AC;
    font-size: rem(14px);
    flex: 0 0 95%;
  }
  &__checkbox {
    margin-top: rem(3px);
  }
}
</style>
