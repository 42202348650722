import CoreApi from '../../../api/core'
import _ from 'lodash'
import NativePerfArticleFilter from '../../../model/NativePerfArticleFilter'
import DonFilterService from '../../../services/don/DonFilterService'

const API_NAME = '/don/nativePerf/prediction'

const state = {
  error: null,
  data: {
    articles: [],
    prediction: {
      articleRows: [],
      totalCampaignPerformance: [],
      predictedEstimateByCampaigns: []
    },
    totalCount: 0
  },
  refreshUrl: null,
  filter: _.cloneDeep(NativePerfArticleFilter),
  dataLoaded: false
}

const mutations = {
  storeData (state, responseData) {
    state.data.articles = responseData.articles
    state.data.prediction.articleRows = responseData.predictionData.articleRows
    state.data.prediction.totalCampaignPerformance = responseData.predictionData.totalCampaignPerformance
    state.data.prediction.predictedEstimateByCampaigns = responseData.predictionData.predictedEstimateByCampaigns
    state.data.totalCount = responseData.totalCount
  },
  setRefreshUrl (state, url) {
    state.refreshUrl = url
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  resetFilter (state) {
    state.filter = _.cloneDeep(NativePerfArticleFilter)
  },
  setDataLoaded (state, dataLoaded) {
    state.dataLoaded = dataLoaded
  }
}

const actions = {
  async fetch (store, init) {
    store.commit('TOGGLE_LOADING', null, { root: true })

    let url = API_NAME + '?'

    url += DonFilterService.buildFilterQuery(store.state.filter).substring(1)

    store.commit('setRefreshUrl', url)

    await CoreApi().get(url)
      .then(res => {
        store.commit('storeData', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async refresh (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })

    const url = store.state.refreshUrl

    await CoreApi().get(url)
      .then(res => {
        store.commit('storeData', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  }
}

const getters = {
  data (state) {
    return state.data
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  isDataLoaded (state) {
    return state.dataLoaded
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
