<template>
  <app-module-view class="m-b-50">
    <template slot="body">
      <app-preloader-full v-if="!dataLoaded"></app-preloader-full>
      <section class="m-b-10 action-toolbar" @mousedown="onUserAction">
        <div class="row">
          <div class="col-lg-4">
            <div class="pull-left">
              <app-article-transitions-buttons
                :update-draft="updateDraft"
                @show-errors="showErrors"
              >
              </app-article-transitions-buttons>
              <app-button-copy-to-clipboard
                data-test="article_btn_copy_to_clipboard"
                label="ID"
                :data="article.documentId"
                :notify-message="$t('notify.id_was_copied')"
              >
              </app-button-copy-to-clipboard>
              <button
                v-if="!vlmDotyk"
                :disabled="disableWoodWingExportButton"
                type="button"
                class="btn btn-warning"
                :class="{disabled: disableWoodWingExportButton}"
                @click="toggleShowWoodWingExportModal"
              >
                {{ $t("woodWing.export.open") }}
              </button>
              <ArticleTransferToExportButton :article="article" />
            </div>
          </div>
          <div class="col-lg-4 m-t-10 text-right">
            <app-article-status v-if="false" :article="article" class="m-r-20"></app-article-status>
            <app-article-last-change-info :article="article"></app-article-last-change-info>
            <app-article-history-button :article="article"></app-article-history-button>
            <app-article-version-info v-if="dataLoaded" :selectedArticle="article"></app-article-version-info>
          </div>
          <div class="col-lg-4">
            <div class="article-edit-view__right-buttons m-r-5">
              <BtnCreateCampaign :article="article" />
              <BtnArticlePreview
                :article="article"
                data-test="article_btn_preview"
                :save-before-preview="true"
                :save-callback="updateDraft"
              />
              <app-article-save-draft-button
                @click="updateDraft"
                data-test="article_btn_save_draft"
                :disabled="!dataLoaded"
              >
              </app-article-save-draft-button>
              <app-button-delete
                v-if="article.setting.status==='draft'"
                @deleteRecord="deleteArticle"
                data-test="article_btn_delete"
              >
              </app-button-delete>
              <app-button-close
                route-name="article_list"
                data-test="article_btn_close"
              >
              </app-button-close>
            </div>
          </div>

          <div
            v-if="articleLogSaveError"
            class="article-log-save-error"
          >
            <i class="fa fa-exclamation-triangle" />
            {{ articleLogSaveError }}
          </div>
        </div>
      </section>

      <section class="content form-horizontal article-edit" @mousedown="onUserAction">
        <div class="row">
          <div class="col-lg-8">
            <div class="card card-body article-edit-view-blocks">
              <app-article-activity-tracking
                v-if="dataLoaded"
                :article="article"
                @auto-save="autoSaveAndRedirect"
              >
              </app-article-activity-tracking>
              <input
                type="hidden"
                v-model="article.documentId"
                id="article_documentId"
              >
              <input
                type="hidden"
                v-model="article.id"
                id="article_id"
              >
              <div class="row">
                <div class="col-lg-4">
                  <h3 class="article-edit-header">{{ $t('article.main_content') }}</h3>
                </div>
                <div class="col-lg-8 text-right">
                  <label for="article_type" class="m-r-10">{{ $t('article.article_type_title') }}</label>
                  <label
                    v-for="(articleType, index) in articleTypeValuesFiltered"
                    :key="`item-${index}`"
                    class="btn btn-secondary m-r-5"
                    :class="{ 'active': article.type === articleType.id }"
                  >
                    <input
                      type="radio"
                      v-model="article.type"
                      :value="articleType.id"
                      :data-test="'type_' + articleType.id"
                      :name="'type-' + articleType.id"
                    >
                    {{ articleType.title }}
                  </label>
                </div>
              </div>
              <div>
                <ArticleOriginalContentRatio
                  :article="article"
                  ref="articleOriginalContentRatio"
                />
                <app-input
                  v-if="originalArticle"
                  v-model="originalArticle.field.title"
                  id="original_article_field_title"
                  :label="$t('topicOffer.original.title')"
                  disabled
                >
                </app-input>
              </div>
              <div>
                <small
                  v-if="article.field.title.length < fieldsCharsLimit[company].title.min"
                  class="form-control-feedback text-warning float-right"
                >
                  <i class="fa fa-exclamation-triangle"></i>
                  {{ $t('article.title_is_too_short') }}
                </small>
                <small
                  v-if="article.field.title.length > fieldsCharsLimit[company].title.max"
                  class="form-control-feedback text-warning float-right"
                >
                  <i class="fa fa-exclamation-triangle"></i>
                  {{ $t('article.title_is_too_long') }}
                </small>
                <app-input
                  v-model.trim="article.field.title"
                  @blur="$v.article.field.title.$touch()"
                  :error="$v.article.field.title.$error"
                  id="article_field_title"
                  ref="article_field_title"
                  :label="$t('article.title')"
                  show-counter
                  required
                  :class="{'error': transitionErrors.field.title}"
                >
                </app-input>
              </div>
              <app-seo-title
                :article="article"
                :transitionErrors="transitionErrors"
                :fields-chars-limit="fieldsCharsLimit"
              ></app-seo-title>
              <div class="row">
                <div class="col-lg-8">
                  <div v-if="article.type === 'external'">
                    <app-input
                      v-model.trim="article.external.url"
                      id="article_external_url"
                      :label="$t('article.external_url')"
                      :disabled="articleEditDisabled"
                      placeholder="https://"
                      @blur="$v.article.external.url.$touch()"
                      :error="$v.article.external.url.$error"
                      :class="{'error': transitionErrors.external.url}"
                    >
                    </app-input>
                  </div>
                  <div class="form-group" v-if="article.type !== 'external'">
                    <small
                      v-if="article.field.shortTitle.length < fieldsCharsLimit[company].titleForUrl.min"
                      class="form-control-feedback text-warning float-right"
                    >
                      <i class="fa fa-exclamation-triangle"></i>
                      {{ $t('article.short_title_is_too_short') }}
                    </small>
                    <small
                      v-if="article.field.shortTitle.length > fieldsCharsLimit[company].titleForUrl.max"
                      class="form-control-feedback text-warning float-right"
                    >
                      <i class="fa fa-exclamation-triangle"></i>
                      {{ $t('article.short_title_is_too_long') }}
                    </small>
                    <app-input
                      v-model.trim="article.field.shortTitle"
                      @blur="$v.article.field.shortTitle.$touch()"
                      :error="$v.article.field.shortTitle.$error"
                      id="article_field_shortTitle"
                      :label="$t('article.short_title')"
                      :disabled="articleEditDisabled"
                      show-counter
                      required
                      disable-form-group
                      :class="{'error': transitionErrors.field.shortTitle}"
                    >
                    </app-input>
                  </div>
                  <div v-if="originalArticle">
                    <app-textarea
                      v-model="filteredOriginalArticleFirstParagraph"
                      id="original_article_field_firstParagraph"
                      :label="$t('topicOffer.original.first_paragraph')"
                      :rows="5"
                      disabled
                    >
                    </app-textarea>
                  </div>
                  <div class="form-group">
                    <div v-if="showFirstParagraph">
                      <small
                        v-if="article.field.firstParagraph.length < fieldsCharsLimit[company].perex.min"
                        class="form-control-feedback text-warning float-right"
                      >
                        <i class="fa fa-exclamation-triangle"></i>
                        {{ $t('article.first_paragraph_is_too_short') }}
                      </small>
                      <small
                        v-if="article.field.firstParagraph.length > fieldsCharsLimit[company].perex.warningMax"
                        class="form-control-feedback text-warning float-right"
                      >
                        <i class="fa fa-exclamation-triangle"></i>
                        {{ $t('article.first_paragraph_is_too_long') }}
                      </small>
                      <app-textarea
                        v-model="filteredArticleFirstParagraph"
                        disable-form-group
                        id="article_field_firstParagraph"
                        :label="$t('article.first_paragraph')"
                        :rows="5"
                        show-counter
                        required
                        :class="{'error': transitionErrors.field.firstParagraph}"
                      >
                        <app-tooltip
                          :title="$t('article.first_paragraph_seo_info')"
                          icon="fab fa-google"
                          customInfoClass="seo"
                        ></app-tooltip>
                        <template #afterLabel>
                          <!-- Perex -->
                          <AiGenerator
                            v-if="!vlm && dataLoaded"
                            type="perex"
                            class="m-b-5"
                            :body-blocks="nmhEditorStoreData.blocks"
                            :article="article"
                            :submit-button-label="$t('article.ai_service.generate_from_ai')"
                            :original-text="filteredArticleFirstParagraph"
                            :open-modal-with-results="true"
                            @selected="onAiGeneratedPerexSelected"
                            @aiAssistLock="() => aiAssistedLocked = true"
                          />
                        </template>
                      </app-textarea>
                    </div>
                    <div v-if="!vlmDotyk && showReducedMainContent && showFirstParagraph">
                      <app-checkbox
                        v-model="listingTeaserOn"
                        :disabled="articleEditDisabled"
                        id="article_listingTeaser_on"
                        :label="$t('article.show_listing_teaser')"
                        class="m-t-10 m-b-20"
                      >
                      </app-checkbox>
                      <small
                        v-if="article.field.listingTeaser.length > 300  && listingTeaserOn"
                        class="form-control-feedback text-warning float-right"
                      >
                        <i class="fa fa-exclamation-triangle"></i>
                        Listing teaser is too long.
                      </small>
                      <app-textarea
                        v-if="listingTeaserOn"
                        v-model="filteredArticleListingTeaser"
                        :disabled="articleEditDisabled"
                        id="article_field_listingTeaser"
                        :label="$t('article.listing_teaser')"
                        :rows="8"
                        show-counter
                      >
                      </app-textarea>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="m-b-5" v-if="isHeroVideoAllowed">
                    <button
                      class="btn btn-sm"
                      :class="{'btn-inverse': !heroVideoBox, 'btn-secondary': heroVideoBox}"
                      @click="hideHeroVideoBox"
                    >
                      <i class="fas fa-images"></i> {{ $t('article.hero_image') }}
                    </button>
                    <button
                      class="btn btn-sm"
                      :class="{'btn-inverse': heroVideoBox, 'btn-secondary': !heroVideoBox}"
                      @click="showHeroVideoBox"
                    >
                      <i class="fas fa-video"></i> {{ $t('article.hero_video') }}
                    </button>
                  </div>
                  <label v-if="['article', 'disease', 'longform', 'video'].indexOf(article.type) < 0">
                    {{ $t('article.hero_image') }}
                  </label>
                  <label v-if="article.type === 'video'">
                    {{ $t('article.hero_video') }}
                  </label>
                  <app-media-editable
                    v-if="article.expanded.heroImage"
                    :media="article.expanded.heroImage"
                    :width="300"
                    :height="168"
                    @remove-media="removeHeroImage"
                    show-add-media-to-gallery
                    @add-media-to-gallery="addMediaToGallery"
                  >
                  </app-media-editable>
                  <div v-if="hasShowHeroImage">
                    <app-media-select-button
                      @set-media="setHeroImage"
                      :selected-dam-media="article.expanded.heroImage"
                      show-drop-zone
                      data-test="article_btn_select_hero_image_from_dam"
                    >
                    </app-media-select-button>
                    <app-media-upload-button
                      @set-media="setHeroImage"
                      data-test="article_btn_select_hero_image_from_pc"
                    >
                    </app-media-upload-button>
                    <app-media-url-button
                      @set-media="setHeroImage"
                      data-test="article_btn_select_hero_image_from_url"
                    >
                    </app-media-url-button>
                  </div>
                  <div v-if="hasShowHeroVideo">
                    <app-video-select-button
                      @set-video="setVideo"
                      :selected-dam-media="article.expanded.heroImage"
                      show-drop-zone
                      data-test="article_btn_select_hero_image_from_dam"
                    >
                    </app-video-select-button>
                    <app-youtube-select-button
                      @set-video="setYoutubeVideo"
                      :selected-dam-media="article.expanded.heroImage"
                      show-drop-zone
                      data-test="article_btn_select_hero_image_from_dam"
                    >
                    </app-youtube-select-button>
                    <app-checkbox
                      v-model="article.setting.dynamicPreview"
                      id="article_heroVideodynamicPreview_on"
                      :label="$t('article.hero_video_dynamic_preview')">
                    </app-checkbox>
                  </div>
                  <EagleVideo
                    v-if="vlm && hasShowHeroVideo && article.field.videoType === 'livebox' && article.field.video"
                    :video="{ id: article.field.video }"
                  />
                  <YoutubeVideo
                    v-if="vlm && hasShowHeroVideo && article.field.videoType === 'youtube' && article.field.video"
                    :id="article.field.video"
                  />
                  <div class="form-group m-t-5">
                    <app-checkbox
                      v-if="showReducedMainContent"
                      v-model="listingImageOn"
                      :disabled="articleEditDisabled"
                      id="article_listingImage_on"
                      :label="$t('article.another_image_in_the_list')"
                    />
                  </div>
                  <div class="form-group" v-if="showReducedMainContent && listingImageOn">
                    <label>{{ $t('article.listing_image') }}</label><br>
                    <app-media-editable
                      v-if="article.expanded.listingImage"
                      :disabled="articleEditDisabled"
                      :media="article.expanded.listingImage"
                      @remove-media="removeListingImage"
                      :width="300"
                      :height="168"
                      show-add-media-to-gallery
                      @add-media-to-gallery="addMediaToGallery"
                    >
                    </app-media-editable>
                    <app-media-select-button
                      @set-media="setListingImage"
                      :selected-dam-media="article.expanded.listingImage"
                      show-drop-zone
                      data-test="article_btn_select_listing_image_from_dam"
                      :hide-button="articleEditDisabled"
                    >
                    </app-media-select-button>
                    <app-media-upload-button
                      @set-media="setListingImage"
                      data-test="article_btn_select_listing_image_from_pc"
                      :hide-button="articleEditDisabled"
                    >
                    </app-media-upload-button>
                    <app-media-url-button
                      @set-media="setListingImage"
                      data-test="article_btn_select_listing_image_from_url"
                      :hide-button="articleEditDisabled"
                    >
                    </app-media-url-button>
                  </div>
                </div>
              </div>
              <div class="row" v-if="hasShowHeroVideo">
                <div class="col-sm-10">
                  <app-input
                    v-model="article.field.video"
                    :error="transitionErrors.field.video"
                    disabled
                    id="article_field_video"
                    :label="$t('article.video_id')"
                  >
                  </app-input>
                </div>
                <div class="col-sm-2">
                  <button
                    class="btn btn-sm btn-danger p-l-10 p-r-10 m-t-30"
                    :title="$t('article.remove_ooyala_id')"
                    @click="removeVideo"
                  >
                    <span class="fa fa-times"></span>
                  </button>
                </div>
              </div>
              <div class="row" v-if="article.type === ARTICLE_TYPE_QUIZ">
                <div class="col-lg-2">
                  <div class="form-group">
                    <app-input
                      v-model="article.quiz"
                      @blur="$v.article.quiz.$touch()"
                      :error="$v.article.quiz.$error"
                      id="article_quiz"
                      disabled="disabled"
                      :label="$t('article.quiz_id')"
                      disable-form-group
                    >
                    </app-input>
                  </div>
                </div>
                <div class="col-lg-2">
                  <label>&nbsp;</label>
                  <div class="form-group">
                    <button
                      v-if="!articleEditDisabled"
                      class="btn btn-info"
                      @click="showQuizModal"
                    >
                      <i class="mdi mdi-arrange-send-backward"></i> {{ $t('buttons.select_quiz') }}
                    </button>
                  </div>
                </div>
                <div class="col-lg-8">
                  <label>{{ $t('article.quiz_title') }}</label>
                  <p class v-if="article.quiz">{{ article.expanded.quiz.title }}</p>
                </div>
              </div>
              <div class="row" v-if="article.type === 'recipe'">
                <div class="col-lg-2">
                  <div class="form-group">
                    <app-input
                      v-model="article.recipe"
                      @blur="$v.article.recipe.$touch()"
                      :error="$v.article.recipe.$error"
                      id="article_recipe"
                      disabled="disabled"
                      :label="$t('article.recipe_id')"
                      disable-form-group
                    >
                    </app-input>
                  </div>
                </div>
                <div class="col-lg-2">
                  <label>&nbsp;</label>
                  <div class="form-group">
                    <button
                      class="btn btn-info"
                      @click="showRecipeModal"
                    >
                      <i class="mdi mdi-arrange-send-backward"></i> {{ $t('buttons.select_recipe') }}
                    </button>
                  </div>
                </div>
                <div class="col-lg-8">
                  <label>{{ $t('article.recipe_title') }}</label>
                  <p class v-if="article.recipe">{{ article.expanded.recipe.title }}</p>
                </div>
              </div>
              <div class="row" v-if="article.type === 'test'">
                <div class="col-lg-2">
                  <div class="form-group">
                    <app-input
                      v-model="article.articleTest"
                      @blur="$v.article.articleTest.$touch()"
                      :error="$v.article.articleTest.$error"
                      id="article_test"
                      disabled="disabled"
                      :label="$t('article.test_id')"
                      disable-form-group
                    >
                    </app-input>
                  </div>
                </div>
                <div class="col-lg-2">
                  <label>&nbsp;</label>
                  <div class="form-group">
                    <button
                      v-if="!articleEditDisabled"
                      class="btn btn-info"
                      @click="showTestModal"
                    >
                      <i class="mdi mdi-arrange-send-backward"></i> {{ $t('buttons.select_test') }}
                    </button>
                  </div>
                </div>
                <div class="col-lg-8">
                  <label>{{ $t('article.test_title') }}</label>
                  <p class v-if="article.articleTest">{{ article.expanded.articleTest.title }}</p>
                </div>
              </div>
              <div v-if="vlm" class="form-group">
                <app-checkbox
                  v-model="alternativeTitleCheckbox"
                  id="article_field_alternative_title"
                  :label="$tc('article.alternative_title')"
                  :disabled="articleEditDisabled"
                />
                <div v-if="alternativeTitleCheckbox">
                  <small
                    v-if="article.field.alternativeTitle.length < fieldsCharsLimit[company].alternativeTitle.min"
                    class="form-control-feedback text-warning float-right"
                  >
                    <i class="fa fa-exclamation-triangle"></i>
                    {{ $t('article.title_is_too_short') }}
                  </small>
                  <small
                    v-if="article.field.alternativeTitle.length > fieldsCharsLimit[company].alternativeTitle.max"
                    class="form-control-feedback text-warning float-right"
                  >
                    <i class="fa fa-exclamation-triangle"></i>
                    {{ $t('article.title_is_too_long') }}
                  </small>
                  <app-input
                    v-model.trim="article.field.alternativeTitle"
                    @blur="$v.article.field.alternativeTitle.$touch()"
                    :error="$v.article.field.alternativeTitle.$error"
                    id="article_field_alternative_title"
                    ref="article_field_alternative_title"
                    :label="$t('article.title_seznam')"
                    show-counter
                    required
                    :class="{'error': $v.article.field.alternativeTitle.$error}"
                    :disabled="articleEditDisabled"
                  >
                  </app-input>
                </div>
              </div>
              <div v-if="!vlm" class="form-group">
                <div class="form-ai-heading">
                  <app-checkbox
                    v-model="article.setting.abTested"
                    id="article_setting_abTested"
                    :label="$t('article.ab_test.title')"
                    :tooltip="$t('article.ab_test.info.title')"
                    :disabled="articleEditDisabled"
                />
                  <!-- Title -->
                  <AiGenerator
                    v-if="!vlm && article.setting.abTested && dataLoaded"
                    type="title"
                    :article="article"
                    :body-blocks="nmhEditorStoreData.blocks"
                    :submit-button-label="$t('article.ai_service.generate_from_ai')"
                    :open-modal-with-results="true"
                    @onSelectedTitles="onAiGeneratedTitles"
                    @aiAssistLock="() => aiAssistedLocked = true"
                  />
                </div>

                <div v-if="article.setting.abTested">
                  <div
                    v-for="(variant, index) in ['B', 'C']" :key="`abTest_variant_${index}`"
                    class="form-group"
                  >
                    <small
                      v-if="article.abTestVariants[index + 1].title.length < fieldsCharsLimit[company].title.min"
                      class="form-control-feedback text-warning float-right"
                    >
                      <i class="fa fa-exclamation-triangle"></i>
                      {{ $t('article.title_is_too_short') }}
                    </small>
                    <small
                      v-if="article.abTestVariants[index + 1].title.length > fieldsCharsLimit[company].title.max"
                      class="form-control-feedback text-warning float-right"
                    >
                      <i class="fa fa-exclamation-triangle"></i>
                      {{ $t('article.title_is_too_long') }}
                    </small>
                    <app-input
                      v-model="article.abTestVariants[index + 1].title"
                      :id="`article_ab_test_variants_${index}_title`"
                      :label="`${$t('article.title')} ${variant}`"
                      show-counter
                      required
                      :maxlength="250"
                    >
                    </app-input>
                    <label>{{ $t('article.hero_image') }} {{ variant }}</label>
                    <app-tooltip :title="$t('article.ab_test.info.image')"></app-tooltip>
                    <app-media-editable
                      v-if="article.abTestVariants[index + 1].image"
                      :media="article.abTestVariants[index + 1].image"
                      :width="300"
                      :height="168"
                      @remove-media="removeAbTestVariantImage[index]"
                      :disabled="articleEditDisabled"
                    >
                    </app-media-editable>
                    <div>
                      <app-media-select-button
                        @set-media="setAbTestVariantImage[index]"
                        :selected-dam-media="article.abTestVariants[index + 1].image"
                        show-drop-zone
                        data-test="article_btn_select_ab_test_variants_0_image_from_dam"
                        :hide-button="articleEditDisabled"
                      >
                      </app-media-select-button>
                      <app-media-upload-button
                        @set-media="setAbTestVariantImage[index]"
                        data-test="article_btn_select_ab_test_variants_0_image_from_pc"
                        :hide-button="articleEditDisabled"
                      >
                      </app-media-upload-button>
                      <app-media-url-button
                        @set-media="setAbTestVariantImage[index]"
                        data-test="article_btn_select_ab_test_variants_0_image_from_url"
                        :hide-button="articleEditDisabled"
                      >
                      </app-media-url-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="!vlm && tasrOriginalContent"
              class="card card-body article-edit-view-blocks"
            >
              <h2 class="article-edit-view__section-title">{{ $t('article.original_text') }}</h2>
              <app-textarea
                v-model="tasrOriginalContent"
                id="article_field_originalText"
                :rows="7"
                :disabled="true"
                :show-counter="true"
              >
              </app-textarea>
            </div>
            <div class="card card-body article-edit-view-blocks">
              <div v-if="articleLocked" class="col-lg-10 unlock-article">
                <button
                  type="button"
                  class="btn btn-success"
                  @click="unlockArticleForEdit"
                >
                  <i class="fas fa-lock-open" />
                  {{ $t('article.unlock_for_edit') }}
                </button>
              </div>
              <h2 class="article-edit-view__section-title">{{ $t('article.body_text') }} *</h2>
              <NmhEditor v-if="hasShowBodyText && dataLoaded" :spellcheck="true" />
            </div>
            <div class="card card-body article-edit-view-blocks">
              <div class="form-group" v-if="article.type === 'test' && articleSiteName === 'autobild'">
                <label>{{ $t('article.page_titles_car_test_generated_info') }}</label>
              </div>

              <div v-if="(article.field.pageTitles.length > 0) || (displayPageTitles)">
                <label>{{ $t('article.page_titles') }}</label>
                <app-page-titles
                  :titles="article.field.pageTitles">
                </app-page-titles>
              </div>
              <div v-if="article.type === 'external'">
                <app-input
                  v-model="article.external.author"
                  id="article_external_author"
                  :label="$t('article.external_author')"
                  :disabled="articleEditDisabled"
                >
                </app-input>
                <div class="row">
                  <div class="col-lg-6">
                    <app-input
                      v-model="article.external.siteName"
                      id="article_external_siteName"
                      :label="$t('article.external_site_name')"
                      :disabled="articleEditDisabled"
                    >
                    </app-input>
                  </div>
                  <div class="col-lg-6">
                    <app-input
                      v-model="article.external.siteUrl"
                      @blur="$v.article.external.siteUrl.$touch()"
                      :error="$v.article.external.siteUrl.$error"
                      id="article_external_siteUrl"
                      :label="$t('article.external_site_url')"
                      placeholder="https://"
                      :disabled="articleEditDisabled"
                    >
                    </app-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <app-input
                      v-model="article.external.rubricName"
                      id="article_external_rubricName"
                      :label="$t('article.external_rubric_name')"
                      :disabled="articleEditDisabled"
                    >
                    </app-input>
                  </div>
                  <div class="col-lg-6">
                    <app-input
                      v-model="article.external.rubricUrl"
                      @blur="$v.article.external.rubricUrl.$touch()"
                      :error="$v.article.external.rubricUrl.$error"
                      id="article_external_rubricUrl"
                      :label="$t('article.external_rubric_url')"
                      placeholder="https://"
                      :disabled="articleEditDisabled"
                    >
                    </app-input>
                  </div>
                </div>
              </div>
              <div class="form-group" v-if="showGallery">
                <label>{{ $t('article.gallery') }}</label><br>
                <app-media-select-button
                  @set-media="addMediaToGallery"
                  skip-creating-media
                  multiple
                  data-test="article_btn_select_gallery_from_dam"
                  :site="article.site"
                  @set-gallery-group="setGalleryGroup"
                  :insertButtonLabel="$t('dam.insert_into_article')"
                  :hide-button="articleEditDisabled"
                >
                </app-media-select-button>
                <app-media-upload-button
                  @set-media="addMediaToGallery"
                  multiple
                  data-test="article_btn_select_gallery_from_pc"
                  :hide-button="articleEditDisabled"
                >
                </app-media-upload-button>
                <app-media-url-button
                  @set-media="addMediaToGallery"
                  data-test="article_btn_select_gallery_from_url"
                  :hide-button="articleEditDisabled"
                >
                </app-media-url-button>
                <app-media-url-button
                  @set-media="addMediaToGallery"
                  data-test="article_btn_select_gallery_from_url_embed"
                  :btn-text="$t('buttons.upload_from_url_social')"
                  :font-awesome-icon="'fas fa-external-link-alt'"
                  type-of-btn="embed"
                  :media-url-modal-label-desc="$t('buttons.upload_from_url_social_desc')"
                  :hide-button="articleEditDisabled"
                >
                </app-media-url-button>
                <app-media-edit-button
                  :selectedMedia="article.expanded.gallery"
                  :site="article.site"
                  @set-gallery-group="setGalleryGroup"
                  :insertButtonLabel="$t('dam.insert_into_article')"
                  :hide-button="articleEditDisabled"
                ></app-media-edit-button>
                <app-gallery-select-button
                  @add-media-to-gallery="addMediaToGallery"
                  :article="article"
                  :hide-button="articleEditDisabled"
                />
                <app-checkbox
                  v-model="galleryMultiDrag"
                  id="article_gallery_multi-drag"
                  style-display="inline-block"
                  :label="$t('article.multi_drag')"
                  :disabled="articleEditDisabled"
                />
                <div class="m-t-10">
                  <div v-if="addingToGallery" class="m-t-30">
                    <h5 class="text-center">{{ `${$t('modal.uploading')} ${mediasAddedToGalleryProgress} / ${mediasAddedToGalleryCount}` }}</h5>
                    <Progressbar />
                  </div>
                  <div v-if="failedAddedMediaToGallery.length > 0" class="failed-files">
                    <div>
                      <span class="failed-files__label">
                        {{ $t('media.failed_uploaded_files') }}
                      </span>
                      {{ failedAddedMediaToGallery.map(f => f.id).join(', ') }}
                    </div>
                    <button
                      v-if="!addingToGallery"
                      @click="tryAddToGalleryAgain"
                      type="button"
                      class="btn btn-green"
                      data-test="try-upload-again"
                    >
                      <i class="fa fa-redo"></i> {{ $t('media.try_upload_again') }}
                    </button>
                  </div>
                  <app-article-gallery
                    :disabled="galleryMultiDrag || articleEditDisabled"
                    :gallery="article.expanded.gallery"
                    :multi-drag="galleryMultiDrag"
                    @set-hero-image="setHeroImageFromGallery"
                    @add-media-to-bodytext="addMediaToBodyText"
                  >
                  </app-article-gallery>
                </div>
              </div>
              <div class="form-group" v-if="showReducedMainContent">
                <label>{{ $t('article.related_articles') }}</label><br>
                <button
                  v-if="!articleEditDisabled"
                  class="btn btn-info"
                  data-test="article_btn_select_related_articles"
                  @click="showRelatedArticleModal"
                >
                  {{ $t('buttons.select_articles') }}
                </button>
                <app-related-articles-modal
                  v-if="relatedArticleModal"
                  :body-blocks="nmhEditorStoreData.blocks"
                  @close="closeRelatedArticleModal"
                  @set-data="addRelatedArticles"
                  @articleAiSupported="articleAiSupported"
                  multiple
                />
                <div class="m-t-10">
                  <app-related-article-list />
                </div>
              </div>
              <div class="form-group"
                   v-show="articleSiteName === 'zdravie' && ['external', 'quiz'].indexOf(article.type) < 0">
                <label>{{ $t('article.related_disease_articles') }}</label><br>
                <button
                  v-if="!articleEditDisabled"
                  class="btn btn-info"
                  data-test="article_btn_select_related_disease_articles"
                  @click="showRelatedDiseaseArticleModal"
                >
                  {{ $t('buttons.select_disease_articles') }}
                </button>
                <app-related-disease-articles-modal
                  v-if="relatedDiseaseArticleModal"
                  @close="closeRelatedDiseaseArticleModal"
                  @set-data="addRelatedDiseaseArticles"
                  multiple
                >
                </app-related-disease-articles-modal>
                <div class="m-t-10">
                  <app-related-disease-article-list></app-related-disease-article-list>
                </div>
              </div>
              <div v-if="!vlmDotyk" class="form-group">
                <div class="row">
                  <div class="col-lg-6">
                    <label>{{ $t('article.print_publication') }}</label>
                    <p class v-if="article.printPublication && article.expanded.printPublication.printTitleInfo">
                      {{ article.expanded.printPublication.printTitleInfo.title }}&nbsp;
                      {{ article.expanded.printPublication.title }}
                    </p>
                  </div>
                  <div class="col-lg-6" v-if="article.printPublication || article.field.printCategory.length > 0">
                    <app-input
                      v-model="article.field.printCategory"
                      @blur="$v.article.field.printCategory.$touch()"
                      :error="$v.article.field.printCategory.$error"
                      id="article_field_printCategory"
                      :label="$t('article.print_publication_category')"
                      :maxlength="250"
                      :disabled="articleEditDisabled"
                    >
                    </app-input>
                  </div>
                </div>
                <button
                  v-if="!articleEditDisabled"
                  class="btn btn-info"
                  @click="showPrintPublicationModal"
                >
                  <i class="mdi mdi-arrange-send-backward"></i> {{ $t('buttons.select_print_publication') }}
                </button>
                <button
                  v-if="!articleEditDisabled"
                  class="btn btn-secondary"
                  @click="removePrintPublication"
                >
                  <i class="fa fa-trash"></i> {{ $t('buttons.remove_print_publication') }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card card-body">
              <h3 class="article-edit-header">{{ $t('article.main_settings') }}</h3>
              <div class="row">
                <div class="col-6">
                  <app-select
                    v-model="article.site"
                    :disabled="articleEditDisabled"
                    :options="sites"
                    no-empty-value
                    id="article_site"
                    :label="$t('article.site')"
                    required
                    @change="loadRubricsBySite(); loadAuthorsBySite(); clearRubrics()"
                  >
                  </app-select>
                </div>
                <div class="col-6">
                  <label for="article_mainRubric">
                    {{ $t('article.main_rubric') }}
                    <span class="required">*</span>
                  </label>
                  <select v-model="article.mainRubric" id="article_mainRubric" class="form-control" :disabled="articleEditDisabled">
                    <option
                      v-for="(rubric, index) in rubricsBySite"
                      :key="`item-${index}`"
                      :value="rubric.id"
                      :selected="isRubricSelected(rubric, index)"
                    >
                      <template v-if="rubric.lvl === 1">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </template>
                      <template v-if="rubric.lvl === 2">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </template>
                      {{ rubric.title }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label>{{ $t('article.other_rubrics') }}</label>
                <app-multi-select
                  v-model="article.rubrics"
                  :options="rubrics"
                  label="title"
                  track-by="id"
                  id="article_rubrics"
                  :disabled="articleEditDisabled"
                >
                </app-multi-select>
              </div>

              <div v-if="showPaidContentCheckbox" class="form-group">
                <app-checkbox
                  v-model="article.setting.paidContent"
                  id="article_setting_paidContent"
                  :label="$tc('article.paid_content')"
                  :disabled="articleEditDisabled"
                >
                </app-checkbox>
              </div>

              <div class="row m-b-20">
                <div class="col-lg-12">
                  <label for="article_external_orderDate">
                    {{ $t('article.order_date') }}
                  </label>
                </div>
                <div class="col-lg-8">
                  <app-datepicker
                    v-model="article.orderDate"
                    @blur="$v.article.orderDate.$touch()"
                    :error="$v.article.orderDate.$error"
                    id="article_external_orderDate"
                    no-label
                    disable-form-group
                    :disabled="articleEditDisabled"
                  >
                  </app-datepicker>
                </div>
                <div class="col-lg-4">
                  <div class="orderDate-set-now">
                    <span @click="setOrderDateToNow">{{ $t('article.set_order_date_to_now') }}</span>
                  </div>
                </div>
              </div>
              <div v-if="vlm" class="row">
                <div class="col-lg-12">
                  <app-checkbox
                    v-model="article.flag.hiddenOnHp"
                    id="article_hiddenOnHp"
                    :label="$t('article.hiddenOnHp')"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <app-checkbox
                    v-model="showPublishedUntil"
                    id="quiz_showPublishedUntil"
                    :label="$t('article.set_published_until')"
                    :disabled="articleEditDisabled"
                  >
                  </app-checkbox>
                </div>
                <div class="col-lg-8">
                  <app-datepicker
                    v-if="showPublishedUntil"
                    v-model="article.publishedUntil"
                    id="article_publishedUntil"
                    no-label
                    :disabled="articleEditDisabled"
                  >
                  </app-datepicker>
                </div>
              </div>
              <div class="row m-b-20">
                <div class="col-lg-12">
                  <app-checkbox
                    v-model="showPublishedSince"
                    id="quiz_showPublishedSince"
                    :label="$t('article.postpone')"
                    :disabled="articleEditDisabled"
                  >
                  </app-checkbox>
                </div>
                <div class="col-lg-8">
                  <app-datepicker
                    v-if="showPublishedSince"
                    v-model="article.publishedSince"
                    id="article_publishedSince"
                    disable-form-group
                    no-label
                    :disabled="articleEditDisabled"
                  >
                  </app-datepicker>
                </div>
              </div>
              <div class="form-group" v-if="article.type !== 'external'">
                <label :class="{'error': transitionErrors.authors}">
                  {{ $t('article.authors') }}
                  <span v-if="vlm" class="required">*</span>
                </label>
                <app-multi-select
                  v-model="article.expanded.authors"
                  :options="authors"
                  :custom-label="getAuthorCustomName"
                  track-by="id"
                  id="article_authors"
                  :disabled="articleEditDisabled"
                >
                </app-multi-select>
              </div>
              <div class="form-group" v-if="articleSiteName === 'zdravie'">
                <label>{{ $t('article.doctors') }}</label>
                <app-multi-select
                  v-model="article.expanded.doctors"
                  :options="doctors"
                  label="name"
                  track-by="id"
                  id="article_doctors"
                  :disabled="articleEditDisabled"
                >
                </app-multi-select>
              </div>
              <div class="form-group" v-if="showGardeners">
                <label>{{ $t('article.gardeners') }}</label>
                <app-multi-select
                  v-model="article.expanded.doctors"
                  :options="gardeners"
                  label="name"
                  track-by="id"
                  id="article_gardeners"
                  :disabled="articleEditDisabled"
                >
                </app-multi-select>
              </div>

              <div class="form-group" v-if="false && mainAuthors.length > 1">
                <app-select
                  v-model="article.mainAuthor"
                  :options="mainAuthors"
                  id="article_mainAuthor"
                  :label="$t('article.main_author')"
                  no-empty-value
                  :disabled="articleEditDisabled"
                >
                </app-select>
              </div>

              <div class="form-group" v-if="article.type !== 'external'">
                <app-nature-of-the-article
                  :article="article"
                  :error="transitionErrors.natureOfTheArticle"
                >
                </app-nature-of-the-article>
              </div>

              <div class="form-group">
                <app-source-type
                  v-if="article.type !== 'external'"
                  :article="article"
                  :error="transitionErrors.sourceType"
                >
                </app-source-type>
                <ArticleAiTools v-if="!vlm && dataLoaded" :article="article" :aiAssistedLocked="aiAssistedLocked"/>
              </div>

              <div class="form-group" v-if="article.type !== 'external'">
                <app-safety-topics
                  :article="article"
                  :error="transitionErrors.safetyTopics"
                >
                </app-safety-topics>
              </div>

              <div
                class="form-group"
                v-if="article.type !== 'external'"
              >
                <label :class="{'error': transitionErrors.categories}">
                  {{ $t('article.categories') }}
                  <span class="required">*</span>
                </label>
                <app-multi-select
                  v-model="article.expanded.categories"
                  :options="categories"
                  label="title"
                  track-by="id"
                  id="article_categories"
                  data-attr="content_categories"
                  :disabled="articleEditDisabled"
                >
                </app-multi-select>
              </div>

              <div class="form-group">
                <label :class="{'error': transitionErrors.sharedUsers}">
                  {{ $t('article.sharedUsers') }}
                  <span class="required">*</span>
                  <app-tooltip
                    position="absolute"
                    :title="$tc('topicOffer.original.tooltip.shared_users')"
                  >
                  </app-tooltip>
                </label>
                <app-multi-select
                  v-model="article.expanded.sharedUsers"
                  :options="sharedUsers"
                  label="username"
                  track-by="id"
                  id="article_sharedUsers"
                  data-attr="authors_share"
                  :disabled="articleEditDisabled"
                >
                </app-multi-select>
              </div>

              <div class="form-group" v-if="article.type !== 'external'">
                <label>{{ $t('article.owners') }}</label>
                <app-multi-select
                  v-model="article.expanded.owners"
                  :options="owners"
                  label="username"
                  track-by="id"
                  id="article_owners"
                  :disabled="articleEditDisabled"
                >
                </app-multi-select>
              </div>

              <div class="form-group" v-if="article.type !== 'external'">
                <app-article-url-info
                  v-if="article.id"
                  :article="article"
                  :hide-refresh-url-button="articleEditDisabled"
                >
                </app-article-url-info>
              </div>
            </div>
            <div class="card card-body">
              <h3 class="article-edit-header">{{ $t('article.meta_info') }}</h3>
              <div v-if="article.type === 'longform'">
                <app-select
                  v-model="article.setting.layout"
                  :options="articleLayoutValues"
                  no-empty-value
                  id="article_layout"
                  :label="$t('article.layout')"
                  :disabled="articleEditDisabled"
                >
                </app-select>
              </div>
              <GeneeaTags
                v-if="vlm && article.type !== 'external'"
                :article="article"
              />
              <div class="form-group" v-if="!vlmDotyk && article.type !== 'external'">
                <div class="row">
                  <div class="col-lg-5">
                    <label>{{ $t('article.tags') }}</label>
                    <!-- Keywords -->
                    <AiGenerator
                      v-if="!vlm && dataLoaded"
                      type="keyword"
                      class="keyword-generator-button"
                      :article="article"
                      :body-blocks="nmhEditorStoreData.blocks"
                      :submit-button-label="$t('article.ai_service.generate_from_ai')"
                      :open-modal-with-results="true"
                      :unique-keywords="generatedKeywordsView"
                      @generated="onAiGeneratedKeywords"
                      @addTags="addTags"
                      @aiAssistLock="() => aiAssistedLocked = true"
                    />
                  </div>
                  <div class="col-lg-7 create-tag-button">
                    <app-create-tag-button
                      v-if="searchedTag && !articleEditDisabled"
                      :searchedTag="searchedTag"
                      @set-tag="addTag"
                    >
                    </app-create-tag-button>
                  </div>
                </div>
                <app-multi-select
                  v-model="article.expanded.tags"
                  :options="tags"
                  :custom-label="tag => `${tag.title} (${tag.articleCount})`"
                  track-by="id"
                  :preselect-first="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :options-limit="300"
                  :limit="99999"
                  :max-height="600"
                  :show-no-results="false"
                  :disabled="articleEditDisabled"
                  open-direction="bottom"
                  @search-change="findTag"
                  @remove="removeTag"
                  id="article_tags"
                >
                </app-multi-select>
              </div>
              <app-article-selected-topics
                v-if="!vlmDotyk"
                :selectedTopics="article.expanded.selectedTopics"
                :article-tags="article.expanded.tags"
                :article-type="article.type"
                :article-site="article.site"
                :article-site-name="articleSiteName"
                @set-data="setSelectedTopics($event)"
              >
              </app-article-selected-topics>
              <label>{{ $t('article.flags') }}</label>
              <app-flag-buttons
                :article="article"
              >
              </app-flag-buttons>
              <div v-if="article.type !== 'external'">
                <label>{{ $t('article.settings') }}</label>
                <div class="form-group">
                  <app-checkbox
                    v-if="!vlmDotyk"
                    v-model="article.setting.disableComments"
                    id="article_setting_disableComments"
                    :label="$t('article.disable_comments')"
                    :disabled="articleEditDisabled"
                  />
                  <app-checkbox
                    v-model="article.setting.disableTransfer"
                    id="article_setting_disableTransfer"
                    :label="$t('article.disable_transfer')"
                    :disabled="articleEditDisabled"
                  />
                </div>
              </div>
            </div>
            <!-- temporary hidden - see CMS-3740 and CMS-2592-->
            <div v-if="false" class="card card-body">
              <h3 class="article-edit-header">{{ $t('article.antares_info') }}</h3>
              <app-article-antares-info v-if="dataLoaded" :article="article"></app-article-antares-info>
            </div>
            <app-hp-topic-offer :article="article"></app-hp-topic-offer>
            <OneSignalArticleNotification v-if="vlm" :article="article" />
            <app-wood-wing-export-modal
              v-if="showWoodWingExportModal"
              :siteId="article.site"
              @close="toggleShowWoodWingExportModal"
              @export="exportWoodWing"
            ></app-wood-wing-export-modal>
          </div>
        </div>
      </section>

      <section class="content article-toolbar-bottom" id="article-toolbar-bottom" @mousedown="onUserAction">
        <div class="row">
          <div class="col-md-5 col-sm-6">
            <div class="pull-left">
              <app-article-transitions-buttons
                :update-draft="updateDraft"
                @show-errors="showErrors"
              ></app-article-transitions-buttons>
              <app-button-copy-to-clipboard
                label="ID"
                :data="article.documentId"
                data-test="article_btn_copy_to_clipboard"
              >
              </app-button-copy-to-clipboard>
            </div>
          </div>
          <div class="col-md-7 col-sm-6">
            <div class="text-right m-r-5">
              <ArticleOriginalContentRatio
                v-if="article.originalDocumentId"
                :article="article"
              />
              <BtnArticlePreview
                :article="article"
                data-test="article_btn_preview"
                :save-before-preview="true"
                :save-callback="updateDraft"
              />
              <app-article-save-draft-button
                @click="updateDraft"
                :disabled="!dataLoaded"
              >
              </app-article-save-draft-button>
              <app-button-delete
                v-if="article.setting.status==='draft'"
                @deleteRecord="deleteArticle"
                data-test="article_btn_delete"
              >
              </app-button-delete>
              <app-button-close
                route-name="article_list"
                data-test="article_btn_close"
              >
              </app-button-close>
            </div>
          </div>
        </div>
      </section>

      <app-quiz-modal
        v-if="quizModal"
        @set-data="setQuiz"
        @close="closeQuizModal"
        outlined-checkbox
      >
      </app-quiz-modal>
      <app-recipe-modal
        v-if="recipeModal"
        @set-data="setRecipe"
        @close="closeRecipeModal"
      >
      </app-recipe-modal>
      <app-article-test-modal
        v-if="testModal"
        :discriminators="articleTestDiscriminators"
        @set-data="setTest"
        @close="closeTestModal"
      >
      </app-article-test-modal>
      <app-article-print-publication-modal
        v-if="printPublicationModal"
        @set-data="setPrintPublication"
        @close="closePrintPublicationModal"
      >
      </app-article-print-publication-modal>
      <app-keydown-ctrl-s
        @action="updateDraft"
      >
      </app-keydown-ctrl-s>
      <app-before-route-leave-modal
        v-if="beforeRouteLeaveModal"
        @close="closeBeforeRouteLeaveModal"
        @leave="closeArticleEdit"
      >
      </app-before-route-leave-modal>
      <ArticleLogUnsavedVersionModal
        v-if="articleLogUnsavedVersionModal"
        :user-id="unsavedVersionInfo.userId"
        :last-modified-text="unsavedVersionInfo.lastModifiedText"
        @confirm="confirmArticleLogUnsavedVersionModal"
        @close="closeArticleLogUnsavedVersionModal"
      />
      <ArticleSaveFailedModal
        v-if="articleSaveFailedModal"
        :last-modified-text="getLastModifiedText(lastSuccessfulLogSaved)"
        @save-article="confirmArticleSaveFailedModalAndSave"
        @restore-article-log="confirmArticleSaveFailedModalAndRestore"
        @close="closeArticleSaveFailedModal"
      />
    </template>
  </app-module-view>
</template>

<script>
import { createBlock } from '@/services/nmhEditor/NmhEditorService'
import { mapGetters, mapState } from 'vuex'
import { getInstanceHash } from '@/main'
import ModuleView from '@/components/ModuleView'
import moment from 'moment/moment'
import { required, minLength, maxLength, url, requiredIf } from 'vuelidate/lib/validators'
import ArticleModel from '@/model/ArticleModel.js'
import ArticleStatus from '@/components/article/ArticleStatus'
import Input from '@/components/form/inputs/Input'
import Textarea from '@/components/form/Textarea'
import Checkbox from '@/components/form/Checkbox'
import Datepicker from '@/components/form/Datepicker'
import Select from '@/components/form/select/Select'
import MultiSelect from '@/components/form/select/MultiSelect'
import RelatedArticlesModal from '@/components/article/ArticleRelatedModal'
import RelatedArticleList from '@/components/article/ArticleRelatedList'
import RelatedDiseaseArticlesModal from '@/components/article/ArticleRelatedDiseaseModal'
import RelatedDiseaseArticleList from '@/components/article/ArticleRelatedDiseaseList'
import MediaSelectButton from '@/components/shared/MediaSelectButton'
import MediaUploadButton from '@/components/shared/MediaUploadButton'
import MediaUrlButton from '@/components/shared/MediaUrlButton'
import MediaEditButton from '@/components/shared/MediaEditButton'
import MediaEditable from '@/components/shared/MediaEditable'
import MediaService from '@/services/media/MediaService'
import YoutubeSelectButton from '@/components/shared/YoutubeSelectButton'
import VideoSelectButton from '@/components/shared/VideoSelectButton'
import ArticleGallery from '@/components/article/ArticleGallery'
import AiGenerator from '@/components/ai/AiGenerator'
import ArticleTransitionsButtons from '@/components/article/ArticleTransitionButtons'
import BtnArticlePreview from '@/components/buttons/BtnArticlePreview'
import BtnCreateCampaign from '@/components/buttons/BtnCreateCampaign'
import ArticleSaveDraftButton from '@/components/article/ArticleSaveDraftButton'
import BtnCopyToClipboard from '@/components/buttons/BtnCopyToClipboard'
import ButtonClose from '@/components/shared/ButtonClose'
import ButtonDelete from '@/components/shared/ButtonDelete'
import NotifyService from '@/services/NotifyService'
import ArticleLastChangeInfo from '@/components/article/ArticleLastChangeInfo'
import ArticleUrlInfo from '@/components/article/ArticleUrlInfo'
import ArticleSelectedTopics from '@/components/article/ArticleSelectedTopics'
import ArticleActivityTracking from '@/components/article/ArticleActivityTracking'
import BeforeRouteLeaveModal from '@/components/article/ArticleBeforeRouteLeaveModal'
import ArticleAntaresInfo from '@/components/article/ArticleAntaresInfo'
import ArticleHistoryButton from '@/components/article/ArticleHistoryButton'
import ArticleOriginalContentRatio from '@/components/article/ArticleOriginalContentRatio'
import ArticleVersionInfo from '@/components/article/ArticleVersionsInfo'
import CreateTagButton from '@/components/article/ArticleCreateTagButton'
import QuizModal from '@/components/article/ArticleQuizModal'
import RecipeModal from '@/components/article/ArticleRecipeModal'
import ArticleTestModal from '@/components/article/ArticleTestModal'
import ArticlePrintPublicationModal from '@/components/article/ArticlePrintPublicationModal'
import FlagButtons from '@/components/article/ArticleFlagButtons'
import SafetyTopics from '@/components/article/ArticleSafetyTopics'
import NatureOfTheArticle from '@/components/article/ArticleNatureOfTheArticle'
import SourceType from '@/components/article/ArticleSourceType'
import ArticleValidationService from '@/services/article/ArticleValidationService'
import ArticleService from '@/services/article/ArticleService'
import KeydownCtrlS from '@/components/shared/KeydownCtrlS'
import PreloaderFull from '@/components/preloader/PreloaderFull'
import { ROLE_SUPER_ADMIN } from '@/model/ValueObject/UserRole'
import ArticleTypeMixin from '@/components/mixins/valueObject/ArticleTypeMixin'
import ArticleLayoutMixin from '../../components/mixins/valueObject/ArticleLayoutMixin'
import ArticleTestDiscriminatorsMixin from '../../components/mixins/valueObject/ArticleTestDiscriminatorsMixin'
import PageTitles from '../../components/article/ArticlePageTitles'
import Tooltip from '../../components/tooltip/Tooltip'
import HpTopicOffer from '../../components/article/ArticleHpTopicOffer'
import WoodWingExportModal from '../../components/article/ArticleWoodWingExportModal'
import SeoTitle from '../../components/article/ArticleSeoTitle'
import GallerySelectButton from '../../components/shared/GallerySelectButton'
import EagleVideo from '@/components/shared/EagleVideo'
import YoutubeVideo from '@/components/shared/YoutubeVideo'
import ArticleTransferToExportButton from '@/components/article/ArticleTransferToExportButton'
import NmhEditor from '@/components/nmhEditor/NmhEditor'
import Progressbar from '@/components/Progressbar'
import NmhEditorBlockValidation from '@/components/nmhEditor/BlockValidation'
import GeneeaTags from '@/components/GeneeaTags/GeneeaTags'
import OneSignalArticleNotification from '@/components/article/OneSignalArticleNotification'
import { isOfflineError, notifyServiceSetGeneralErrorMsg } from '@/services/NotifyServiceSetGeneralErrorMsg'
import ErrorHandlingService from '@/services/ErrorHandlingService'
import CoreApi from '@/api/core'
import { ENTITY_LOG_ARTICLE } from '@/model/ValueObject/EntityLogTypes'
import ArticleLogUnsavedVersionModal from '@/components/article/ArticleLogUnsavedVersionModal'
import ArticleSaveFailedModal from '@/components/article/ArticleSaveFailedModal'
import config from '@/config'
import ArticleAiTools from '@/components/article/ArticleAiTools.vue'

const DEFAULT_ARTICLE_LOG_INTERVAL = 5
export default {
  name: 'ArticleEditView',
  mixins: [
    ArticleTypeMixin,
    ArticleLayoutMixin,
    ArticleTestDiscriminatorsMixin
  ],
  data () {
    return {
      authors: [],
      rubricsBySite: [],
      selectedRubrics: [],
      article: ArticleModel,
      tags: [],
      searchedTag: '',
      dataLoaded: false,
      isLoading: false,
      addingToGallery: false,
      mediasAddedToGalleryCount: 0,
      mediasAddedToGalleryProgress: 0,
      listingTeaserOn: false,
      listingImageOn: false,
      showPublishedSince: false,
      showPublishedUntil: false,
      beforeRouteLeaveModal: false,
      relatedArticleModal: false,
      relatedDiseaseArticleModal: false,
      showPaidContentCheckbox: false,
      quizModal: false,
      recipeModal: false,
      testModal: false,
      printPublicationModal: false,
      articleTestDiscriminators: [],
      generatedTitles: [],
      generatedKeywords: [],
      generatedKeywordsView: [],
      canCloseArticle: true,
      anyUserAction: false,
      routeAfterCloseArticle: '/article',
      transitionErrors: ArticleValidationService.transitionErrors,
      heroVideoBox: false,
      displayPageTitles: true,
      enabledEmbedButtons: {
        media: true,
        ooyala: false,
        livebox: true,
        youtube: true,
        quote: true,
        poll: true,
        relatedArticle: true,
        relatedDiseaseArticle: false,
        html: true,
        pageBreak: true,
        paidContentBreak: false,
        infobox: true,
        galleryLink: true,
        sportTable: false,
        sportOnline: false,
        carTable: false
      },
      originalArticle: null,
      originalBodyText: '',
      loaderTimer: null,
      showOriginalBodyText: false,
      showWoodWingExportModal: false,
      disableWoodWingExportButton: true,
      failedAddedMediaToGallery: [],
      articleLogInterval: null,
      unsavedVersionInfo: null,
      articleLogUnsavedVersionModal: false,
      articleSaveFailedModal: false,
      lastSuccessfulLogSaved: null,
      articleLogSaveError: false,
      galleryMultiDrag: false,
      aiAssistedLocked: false
    }
  },
  computed: {
    ...mapState({
      nmhEditorStoreData: state => state.nmhEditorStore.data
    }),
    ...mapGetters(['vlm', 'vlmDotyk']),
    filteredArticleFirstParagraph: {
      get () {
        return this.article.field.firstParagraph
      },
      set (value) {
        this.article.field.firstParagraph = this.filterFormattingChars(value)
      }
    },
    filteredOriginalArticleFirstParagraph: {
      get () {
        return this.originalArticle.field.firstParagraph
      },
      set (value) {
        this.originalArticle.field.firstParagraph = this.filterFormattingChars(value)
      }
    },
    filteredArticleListingTeaser: {
      get () {
        return this.article.field.listingTeaser
      },
      set (value) {
        this.article.field.listingTeaser = this.filterFormattingChars(value)
      }
    },
    alternativeTitleCheckbox: {
      set (value) {
        this.$store.commit('article/SET_ALTERNATIVE_TITLE_CHECKBOX', value)
      },
      get () {
        return this.$store.state.article.alternativeTitleCheckbox
      }
    },
    company () {
      return this.$store.getters.company
    },
    articleLocked () {
      return this.vlm && this.article.setting.transferedArticle && this.article.setting.lockedTransferredArticle
    },
    articleEditDisabled () {
      return this.$store.getters['article/articleEditDisabled']
    },
    fieldsCharsLimit () {
      return ArticleValidationService.getFieldsCharsLimit(this.article)
    },
    mainAuthors () {
      const mainAuthors = []
      this.article.expanded.authors.forEach(author => {
        const mainAuthor = {
          id: author.id,
          title: author.name
        }
        mainAuthors.push(mainAuthor)
      })
      return mainAuthors
    },
    articleSerialized () {
      return JSON.stringify(this.article)
    },
    articleTypeValuesFiltered () {
      const result = this.articleTypeValues.filter(type => !this.excludedArticleTypes.includes(type.id))
      return result.filter(type => {
        switch (type.id) {
          case 'test':
            return ['autobild', 'enjoy'].includes(this.articleSiteName)
          case 'longform':
            return ['enjoy', 'trend', 'medialne', 'reality', 'eva'].includes(this.articleSiteName)
          case 'quiz':
            return ['zdravie'].includes(this.articleSiteName) === false
          case 'disease':
            return ['zdravie'].includes(this.articleSiteName)
          case 'encyclopedy':
            return ['zahradkar'].includes(this.articleSiteName)
          default:
            return true
        }
      })
    },
    articleType () {
      return this.article.type
    },
    articleSiteName () {
      const site = this.$store.getters['site/siteById'](this.article.site)
      if (site) {
        return site.name
      }
      return ''
    },
    sites () {
      return this.$store.getters['site/enabledSites']()
    },
    rubrics () {
      if (this.vlm) {
        return this.$store.getters['rubric/rubricsWithSite'].filter(item => item.site === this.article.site)
      } else {
        return this.$store.getters['rubric/rubricsWithSite']
      }
    },
    doctors () {
      return this.$store.getters['author/all'].filter((author) => author.type === 'doctor')
    },
    gardeners () {
      return this.$store.getters['author/all'].filter((author) => author.type === 'gardener')
    },
    showGardeners () {
      if (this.articleSiteName !== 'zahradkar') {
        return false
      }
      const rubricId = this.article.mainRubric
      let rubric = this.$store.getters['rubric/rubricById'](rubricId)
      if (rubric) {
        if (rubric.parent) {
          rubric = this.$store.getters['rubric/rubricById'](rubric.parent)
        }
        return rubric.slug === 'poradna'
      }
      return false
    },
    owners () {
      return this.$store.getters['user/all']
    },
    sharedUsers () {
      return this.$store.getters['user/all']
    },
    categories () {
      return this.$store.getters['category/all']
    },
    isHeroVideoAllowed () {
      return [this.ARTICLE_TYPE_ARTICLE, this.ARTICLE_TYPE_LONGFORM].includes(this.article.type)
    },
    hasShowHeroImage () {
      return !this.hasShowHeroVideo && this.article.type !== this.ARTICLE_TYPE_DISEASE
    },
    hasShowHeroVideo () {
      return (this.isHeroVideoAllowed && this.heroVideoBox) ||
        this.article.type === this.ARTICLE_TYPE_VIDEO
    },
    hasShowBodyText () {
      return ![...this.reducedContentArticleTypes, this.ARTICLE_TYPE_VIDEO].includes(this.article.type)
    },
    articleExpandedTags () {
      return this.article.expanded.tags
    },
    showReducedMainContent () {
      return !this.reducedContentArticleTypes.includes(this.article.type)
    },
    showFirstParagraph () {
      return this.article.type !== this.ARTICLE_TYPE_DISEASE
    },
    showGallery () {
      return ![...this.reducedContentArticleTypes, this.ARTICLE_TYPE_DISEASE].includes(this.article.type)
    },
    setAbTestVariantImage () {
      return [
        this.setAbTestVariantBImage,
        this.setAbTestVariantCImage
      ]
    },
    removeAbTestVariantImage () {
      return [
        this.removeAbTestVariantBImage,
        this.removeAbTestVariantCImage
      ]
    },
    tasrOriginalContent () {
      return window.localStorage.getItem(`tasrOriginalContent_article_${this.article.id}`)
    }
  },
  validations () {
    return {
      article: {
        field: {
          alternativeTitle: {
            required: requiredIf(() => {
              return this.$store.state.article.alternativeTitleCheckbox
            }),
            minLength: minLength(this.fieldsCharsLimit.vlm.alternativeTitle.min),
            maxLength: maxLength(this.fieldsCharsLimit.vlm.alternativeTitle.max)
          },
          title: {
            required,
            minLength: minLength(this.fieldsCharsLimit[this.company].title.min),
            maxLength: maxLength(this.fieldsCharsLimit[this.company].title.max)
          },
          shortTitle: {
            required: requiredIf(() => {
              return this.article.type !== this.ARTICLE_TYPE_EXTERNAL
            }),
            minLength: (value) => {
              if (this.article.type !== this.ARTICLE_TYPE_EXTERNAL) {
                return minLength(this.fieldsCharsLimit[this.company].titleForUrl.min)(value)
              }
              return true
            },
            maxLength: (value) => {
              if (this.article.type !== this.ARTICLE_TYPE_EXTERNAL) {
                return maxLength(this.fieldsCharsLimit[this.company].titleForUrl.max)(value)
              }
              return true
            }
          },
          video: {
            minLength: minLength(1),
            maxLength: maxLength(255)
          },
          printCategory: {
            maxLength: maxLength(250)
          }
        },
        external: {
          url: {
            url,
            required: requiredIf(() => {
              return this.article.type === this.ARTICLE_TYPE_EXTERNAL
            })
          },
          siteUrl: {
            url
          },
          rubricUrl: {
            url
          }
        },
        quiz: {
          required: requiredIf(vm => {
            return vm.type === 'quiz'
          })
        },
        recipe: {
          required: requiredIf(vm => {
            return vm.type === 'recipe'
          })
        },
        articleTest: {
          required: requiredIf(vm => {
            return vm.type === 'test'
          })
        },
        orderDate: {
          required
        }
      }
    }
  },
  components: {
    OneSignalArticleNotification,
    GeneeaTags,
    EagleVideo,
    YoutubeVideo,
    ArticleTransferToExportButton,
    appModuleView: ModuleView,
    appArticleStatus: ArticleStatus,
    appInput: Input,
    appTextarea: Textarea,
    appCheckbox: Checkbox,
    appDatepicker: Datepicker,
    appSelect: Select,
    appMultiSelect: MultiSelect,
    appArticleTransitionsButtons: ArticleTransitionsButtons,
    BtnArticlePreview,
    BtnCreateCampaign,
    appArticleSaveDraftButton: ArticleSaveDraftButton,
    appButtonCopyToClipboard: BtnCopyToClipboard,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    ArticleOriginalContentRatio,
    appRelatedArticlesModal: RelatedArticlesModal,
    appRelatedDiseaseArticlesModal: RelatedDiseaseArticlesModal,
    appMediaSelectButton: MediaSelectButton,
    appMediaEditable: MediaEditable,
    appArticleGallery: ArticleGallery,
    AiGenerator,
    appRelatedArticleList: RelatedArticleList,
    appRelatedDiseaseArticleList: RelatedDiseaseArticleList,
    appMediaUploadButton: MediaUploadButton,
    appMediaUrlButton: MediaUrlButton,
    appMediaEditButton: MediaEditButton,
    appVideoSelectButton: VideoSelectButton,
    appYoutubeSelectButton: YoutubeSelectButton,
    appArticleLastChangeInfo: ArticleLastChangeInfo,
    appArticleUrlInfo: ArticleUrlInfo,
    appArticleActivityTracking: ArticleActivityTracking,
    appArticleAntaresInfo: ArticleAntaresInfo,
    appBeforeRouteLeaveModal: BeforeRouteLeaveModal,
    appArticleSelectedTopics: ArticleSelectedTopics,
    appArticleHistoryButton: ArticleHistoryButton,
    appArticleVersionInfo: ArticleVersionInfo,
    appCreateTagButton: CreateTagButton,
    appQuizModal: QuizModal,
    appRecipeModal: RecipeModal,
    appArticleTestModal: ArticleTestModal,
    appArticlePrintPublicationModal: ArticlePrintPublicationModal,
    appFlagButtons: FlagButtons,
    appSafetyTopics: SafetyTopics,
    appNatureOfTheArticle: NatureOfTheArticle,
    appSourceType: SourceType,
    appKeydownCtrlS: KeydownCtrlS,
    appPageTitles: PageTitles,
    appPreloaderFull: PreloaderFull,
    appTooltip: Tooltip,
    appHpTopicOffer: HpTopicOffer,
    appWoodWingExportModal: WoodWingExportModal,
    appGallerySelectButton: GallerySelectButton,
    appSeoTitle: SeoTitle,
    Progressbar,
    NmhEditor,
    ArticleLogUnsavedVersionModal,
    ArticleSaveFailedModal,
    ArticleAiTools
  },
  methods: {
    filterFormattingChars (string) {
      /* eslint no-control-regex: "off" */
      return string.replace(/[\x00-\x1F\x7F\x9F]/g, ' ').replace(/\s+/g, ' ')
    },
    getAuthorCustomName (author) {
      return this.$store.getters['author/getAuthorCustomName'](author)
    },
    clearRubrics () {
      if (this.vlm) {
        this.article.rubrics = []
      }
    },
    articleAiSupported () {
      this.article.setting.aiAssisted = true
      console.log(this.article)
    },
    onUserAction () {
      this.anyUserAction = true
    },
    async loadRubricsBySite () {
      await this.$store.dispatch('rubric/fetchRubricsBySiteId', this.article.site)
      this.rubricsBySite = this.$store.state.rubric.all.filter(item => item.site === this.article.site)
    },
    async loadAuthorsBySite () {
      await this.$store.dispatch('author/fetchAuthorsBySiteId', this.article.site)
      if (this.vlm) {
        this.authors = this.$store.getters['author/allBySite'](this.article.site)
      } else {
        this.authors = this.$store.state.author.all.filter(
          (author) => !['doctor', 'gardener'].includes(author.type) && (
            author.defaultSite === this.article.site || (author.defaultSite === 18 && [24, 26, 27].indexOf(this.article.site) > -1)
          )
        )
      }
    },
    unlockArticleForEdit () {
      this.$store.commit('article/setArticleEditDisabled', false)
      this.article.setting.lockedTransferredArticle = false
    },
    initFields () {
      if (this.article.listingImage) {
        if (this.article.heroImage === null) {
          this.listingImageOn = true
        }
        if (this.article.expanded.heroImage &&
          this.article.expanded.heroImage.damMediaEmbed.damId !== this.article.expanded.listingImage.damMediaEmbed.damId
        ) {
          this.listingImageOn = true
        }
      }
      // listingTeaser
      if (this.article.field.listingTeaser) {
        this.listingTeaserOn = true
      }
      // showPublishedSince
      if (new Date(this.article.publishedSince) > new Date(this.article.orderDate)) {
        this.showPublishedSince = true
      }
      // showPublishedUntil
      if (new Date(this.article.publishedUntil) > new Date() && new Date(this.article.publishedUntil) < new Date('2100-01-01T00:00:00.000000Z')) {
        this.showPublishedUntil = true
      }
      // heroVideoBox
      this.heroVideoBox = this.isHeroVideoAllowed && Boolean(this.article.field.video)
    },
    getFullArticle ({ replaceWithLoggedUnsavedVersion } = { replaceWithLoggedUnsavedVersion: false }) {
      if (this.$route.params.id === undefined) {
        return
      }
      const currentStatus = this.article.setting?.status
      const currentId = this.article.id
      const action = replaceWithLoggedUnsavedVersion ? 'article/fetchArticleLog' : 'article/fetchFullArticle'
      this.$store.dispatch(action, this.$route.params.id)
        .then(async () => {
          const article = this.$store.getters['article/fullArticle']
          if (!article.aiToolsUsed) {
            this.$set(article, 'aiToolsUsed', [])
            article.aiAssisted = false
          }
          if (!replaceWithLoggedUnsavedVersion) {
            this.isAllowedToEdit(article)
          }
          const blocks = await ArticleService.getBodyBlocks(article)
          this.$store.commit('nmhEditorStore/SET_BLOCKS', {
            init: {
              blocks,
              disabled: false
            }
          })
          this.article = article
          if (replaceWithLoggedUnsavedVersion) {
            // due to old error, some autosave versions might have an invalid 'status' and 'id',
            //   this preserves the current status and id of the article
            if (currentStatus) {
              this.article.setting.status = currentStatus
            }
            if (currentId) {
              this.article.id = currentId
            }
          }
          this.$store.commit(
            'article/SET_ALTERNATIVE_TITLE_CHECKBOX',
            !!(this.article.field?.alternativeTitle?.length)
          )
          this.$store.commit('article/setArticleEditDisabled', this.articleLocked)
          if (this.article.originalDocumentId) {
            this.originalArticle = await this.$refs.articleOriginalContentRatio.setOriginalContentRatio(this.article.originalDocumentId)
          }
          this.checkWoodWingExport(article)
          this.initFields()
          this.loadRubricsBySite()
          this.loadAuthorsBySite()
          if (!replaceWithLoggedUnsavedVersion) {
            this.checkArticleLogForUnsavedVersion()
          }
          this.dataLoaded = true
        })
        .catch(error => {
          this.dataLoaded = true
          console.error(error)
        })
    },
    isValid () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('article.notify.please_fill_all_required_fields'))
        return false
      }
      return true
    },
    prepareArticleRequest () {
      this.article.setting.editedInNewEditor = true

      const relatedArticles = this.$store.getters['article/relatedArticles'].map(article => ({
        relatedArticleDocId: article.documentId
      }))

      const relatedDiseaseArticles = this.$store.getters['article/relatedDiseaseArticles'].map(article => ({
        documentId: article.documentId
      }))

      const validBlocks = this.nmhEditorStoreData.blocks.filter(block => {
        const currentBlock = NmhEditorBlockValidation.blocks.find(item => item.type === block.type)
        const isValid = currentBlock && currentBlock.validation(block.properties)
        if (isValid && currentBlock.transform) {
          currentBlock.transform(block.properties)
        }
        return isValid
      })

      return {
        ...this.article,
        relatedArticles,
        relatedDiseaseArticles,
        bodyBlocks: [...validBlocks]
      }
    },
    async updateDraft () {
      this.dataLoaded = false

      if (!this.isValid()) {
        this.dataLoaded = true
        return
      }

      // !!! TODO fix this when merging to vlm-prod !!! - see vlm-beta for resolution
      if (this.article.originalDocumentId) {
        this.originalArticle = await this.$refs.articleOriginalContentRatio.setOriginalContentRatio(this.article.originalDocumentId)
      }

      const article = this.prepareArticleRequest()
      await this.update(article)
    },
    async update (article) {
      // -- Removal of potential duplicate tags --
      if (article.tags) {
        article.tags = article.tags?.reduce((acc, current) => {
          if (acc.indexOf(current) === -1) {
            acc.push(current)
          }
          return acc
        }, [])
      }
      if (article.expanded.tags) {
        article.expanded.tags = article.expanded.tags?.reduce((acc, current) => {
          if (!acc.some(item => item.id === current.id)) {
            acc.push(current)
          }
          return acc
        }, [])
      }
      return new Promise((resolve, reject) => {
        this.canCloseArticle = true // this also prevents autosave of unsaved version into article-log
        this.$store.dispatch('article/update', article)
          .then(() => {
            if (this.$store.getters['article/error'] === null) {
              NotifyService.setSuccessMessage(this.$t('article.notify.draft_was_saved'))
              this.getFullArticle()
              this.canCloseArticle = true
              this.anyUserAction = false
              resolve({
                done: true
              })
            } else {
              this.dataLoaded = true
              const { message, stack, status, url, payload } = this.$store.getters['article/error']
              const error500 = status === 500
              NotifyService.setErrorMessage(this.$t(`article.notify.draft_save_error${error500 ? '_500' : ''}`))
              console.error(message)
              console.error(stack)
              if (error500) {
                this.showArticleSaveFailedModal()
              }
              ErrorHandlingService.vueErrorHandler({ message, stack }, this, { url, payload })
            }
          })
      })
    },
    autoSaveAndRedirect () {
      this.updateDraft()
        .then(() => {
          this.canCloseArticle = true
          this.$store.commit('article/setAutoLogoutArticle', this.article)
          this.$router.push('/article')
        })
    },
    isAllowedToEdit (article) {
      if (article.setting.status !== 'draft') {
        this.$router.push('/article/' + article.id)
        return
      }
      const { prettyDateTime, prettyJson } = this.$options.filters
      const adminHash = getInstanceHash()
      console.debug(`${prettyDateTime(new Date())}: trying to acquire lock for adminHash: ${adminHash} / documentId: ${article.documentId} / articleId: ${article.id}`)
      this.$store.dispatch('activity/putArticleActivity', {
        articleDocumentId: article.documentId,
        articleId: article.id
      }).then(response => {
        console.debug(`${prettyDateTime(new Date())}: response-status: ${response.status} ${prettyJson(response.data)}`)
      }).catch(error => {
        console.debug(`${prettyDateTime(new Date())}: response-status: ${error.response.status} ${prettyJson(error.response.data)}`)
        this.$router.push('/article/' + article.id)
        if (error.response.status !== 409) {
          notifyServiceSetGeneralErrorMsg(error, { includingWhiteList: true })
        }
      })
    },
    findTag (query) {
      this.isLoading = true
      this.searchedTag = ''
      this.$store.dispatch('tag/fetchByTitle', { query })
        .then(() => {
          this.tags = this.$store.getters['tag/list']
          this.searchedTag = query
          this.isLoading = false
        })
    },
    addTag (tag) {
      if (this.article.expanded.tags.some((i) => i.id === tag.id) === false) {
        this.article.expanded.tags.push(tag)
        this.filterGeneratedKeywords()
      }
      this.searchedTag = ''
    },
    addTags (newTags) {
      const existingTagsIds = this.article.expanded.tags.map(tag => tag.id)
      const filteredTags = newTags.filter(tag => !existingTagsIds.includes(tag.id))
      this.article.expanded.tags = [...this.article.expanded.tags, ...filteredTags]

      this.filterGeneratedKeywords()
    },
    removeTag () {
      this.$nextTick(() => this.filterGeneratedKeywords())
    },
    setSelectedTopics (data) {
      this.article.expanded.selectedTopics = data
      this.article.selectedTopics = data.map(topic => topic.id)
    },
    onAiGeneratedTitles (generatedTitles) {
      this.article.abTestVariants = this.article.abTestVariants.map(variant => {
        const generatedTitle = generatedTitles.find(gTitle => gTitle.variant === variant.variant)
        if (generatedTitle) {
          if (generatedTitle.variant === 'b' && generatedTitle.selectedBValue) {
            return { ...variant, title: generatedTitle.selectedBValue }
          } else if (generatedTitle.variant === 'c' && generatedTitle.selectedCValue) {
            return { ...variant, title: generatedTitle.selectedCValue }
          }
        }
        return variant
      })
    },
    onAiGeneratedKeywords (keywords) {
      if (keywords?.length > 0) {
        this.generatedKeywords = Array.from(new Set(keywords.map(keyword => keyword)))
        this.filterGeneratedKeywords()
      }
    },
    onAiGeneratedPerexSelected (perex) {
      this.filteredArticleFirstParagraph = perex
    },
    filterGeneratedKeywords () {
      const tagTitles = this.article.expanded.tags.reduce((acc, tag) => {
        acc[tag.title.toLowerCase()] = true
        return acc
      }, {})
      this.generatedKeywordsView = this.generatedKeywords.filter(keyword => !tagTitles[keyword.toLowerCase()])
    },
    addRelatedArticles (relatedArticles) {
      this.canCloseArticle = false
      relatedArticles.forEach(article => {
        this.$store.commit('article/addRelatedArticle', article)
      })
    },
    addRelatedDiseaseArticles (relatedDiseaseArticles) {
      this.canCloseArticle = false
      relatedDiseaseArticles.forEach(article => {
        this.$store.commit('article/addRelatedDiseaseArticle', article)
      })
    },
    setHeroImage (medias) {
      medias.forEach(media => {
        this.article.expanded.heroImage = media
      })
      if (!this.hasShowHeroVideo) {
        this.clearVideoFields()
      }
    },
    setHeroImageFromGallery (medias) {
      medias.forEach(media => {
        MediaService.createMedia(media)
          .then(newMedia => {
            this.setHeroImage([newMedia])
          })
          .catch((error) => {
            console.log(error)
          })
      })
    },
    removeHeroImage () {
      this.article.expanded.heroImage = null
      this.clearVideoFields()
    },
    clearVideoFields () {
      this.article.field.video = ''
      this.article.field.videoType = 'none'
    },
    removeVideo () {
      this.clearVideoFields()
      this.article.expanded.heroImage = null
      this.article.heroImage = null
    },
    setListingImage (medias) {
      this.article.expanded.listingImage = medias[0]
    },
    removeListingImage () {
      this.article.expanded.listingImage = null
    },
    tryAddToGalleryAgain () {
      this.addMediaToGallery(this.failedAddedMediaToGallery)
    },
    async addMediaToGallery (medias) {
      this.mediasAddedToGalleryCount = medias.length
      this.mediasAddedToGalleryProgress = 0
      this.failedAddedMediaToGallery = []

      const createMedia = (media) => {
        if (!this.checkIfAssetExistInGallery(media)) {
          return MediaService.createMedia(media)
            .then(newMedia => {
              return newMedia
            })
            .catch((error) => {
              this.failedAddedMediaToGallery.push(media)
              console.error(error)
              const { message, stack } = error
              ErrorHandlingService.vueErrorHandler({ message, stack }, this, { url: 'POST /media', payload: media })
              return null
            })
            .finally(() => {
              this.mediasAddedToGalleryProgress += 1
            })
        }
        return Promise.resolve()
      }

      this.addingToGallery = true
      const chunkSize = MediaService.DAM_UPLOAD_CHUNK_SIZE
      // iterates backwards in chunks to 'unshift' all medias at the beginning of the gallery in the correct order
      for (let i = medias.length; i > 0; i -= chunkSize) {
        const chunkMedias = medias.slice(Math.max(i - chunkSize, 0), i)
        const promises = chunkMedias.map(createMedia)
        const createdMedias = await Promise.all(promises)
        const mediasWithoutFailedMedias = createdMedias.filter(media => Boolean(media))
        this.article.expanded.gallery.unshift(...mediasWithoutFailedMedias)
      }
      this.addingToGallery = false
    },
    addMediaToBodyText (medias) {
      medias.forEach(media => {
        const imageBlock = createBlock('imageBlock')
        imageBlock.properties.damMediaId = media.id
        this.$store.commit('nmhEditorStore/SET_POSITION_OF_THE_NEW_BLOCK', 'atTheEnd')
        this.$store.commit('nmhEditorStore/SET_BLOCKS', { setNewBlock: imageBlock })
      })
      medias.forEach(media => {
        this.$store.commit('article/removeMediaFromGallery', media)
      })
    },
    checkIfAssetExistInGallery (asset) {
      let selected = false
      this.article.expanded.gallery.forEach(media => {
        if (media.damMediaEmbed.damId === asset.damMediaEmbed.damId) {
          selected = true
          NotifyService.setWarningMessage(
            this.$t('dam.asset_exists_in_gallery', { title: asset.imageCaption }),
            '',
            6000
          )
        }
      })
      return selected
    },
    setVideo (video) {
      this.article.field.video = video.id
      this.article.field.videoType = 'livebox'
      this.setHeroImageFromGallery([video.expanded.previewImage])
    },
    setYoutubeVideo (youtubeId) {
      this.article.field.video = youtubeId
      this.article.field.videoType = 'youtube'
      MediaService.createMediaFromUrl('https://i.ytimg.com/vi/' + youtubeId + '/hqdefault.jpg')
        .then(response => {
          this.setHeroImage([response.data])
        })
        .catch((error) => {
          NotifyService.setErrorMessage(this.$t('article.notify.youtube_thumbnail_error'))
          console.error(error)
        })
    },
    deleteArticle () {
      this.$store.dispatch('article/deleteRecord', this.article)
        .then(() => {
          if (this.$store.getters['article/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.canCloseArticle = true
            this.$router.push('/article')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['article/error'])
          }
        })
        .catch(error => console.log(error))
    },
    showBeforeRouteLeaveModal () {
      this.beforeRouteLeaveModal = true
    },
    closeBeforeRouteLeaveModal () {
      this.beforeRouteLeaveModal = false
    },
    showRelatedArticleModal () {
      this.relatedArticleModal = true
    },
    closeRelatedArticleModal () {
      this.relatedArticleModal = false
    },
    showRelatedDiseaseArticleModal () {
      this.relatedDiseaseArticleModal = true
    },
    closeRelatedDiseaseArticleModal () {
      this.relatedDiseaseArticleModal = false
    },
    showQuizModal () {
      this.quizModal = true
    },
    closeQuizModal () {
      this.quizModal = false
    },
    showRecipeModal () {
      this.recipeModal = true
    },
    closeRecipeModal () {
      this.recipeModal = false
    },
    showTestModal () {
      this.testModal = true
    },
    closeTestModal () {
      this.testModal = false
    },
    showArticleLogUnsavedVersionModal () {
      this.articleLogUnsavedVersionModal = true
    },
    closeArticleLogUnsavedVersionModal () {
      this.articleLogUnsavedVersionModal = false
    },
    confirmArticleLogUnsavedVersionModal () {
      this.getFullArticle({ replaceWithLoggedUnsavedVersion: true })
      this.articleLogUnsavedVersionModal = false
    },
    showArticleSaveFailedModal () {
      this.articleSaveFailedModal = true
    },
    closeArticleSaveFailedModal () {
      this.articleSaveFailedModal = false
    },
    confirmArticleSaveFailedModalAndSave () {
      this.updateDraft()
      this.articleSaveFailedModal = false
    },
    confirmArticleSaveFailedModalAndRestore () {
      this.getFullArticle({ replaceWithLoggedUnsavedVersion: true })
      this.articleSaveFailedModal = false
    },
    showPrintPublicationModal () {
      this.printPublicationModal = true
    },
    closePrintPublicationModal () {
      this.printPublicationModal = false
    },
    removePrintPublication () {
      this.article.printPublication = null
      this.article.expanded.printPublication = null
    },
    setQuiz (quiz) {
      const selectedData = quiz.selectedData
      const rewriteArticle = quiz.rewriteArticle
      if (!rewriteArticle) {
        this.article.quiz = selectedData.id
        this.article.flag.quiz = true
        this.article.expanded.quiz = selectedData
        return
      }
      this.article.quiz = selectedData.id
      this.article.expanded.quiz = selectedData
      this.article.flag.quiz = true
      this.article.field.title = selectedData.title
      this.article.field.shortTitle = selectedData.title
      this.article.field.firstParagraph = selectedData.description
      this.article.categories = selectedData.categories
      if (selectedData.categories.length > 0) {
        this.article.expanded.categories = selectedData.categories.map(category => this.$store.getters['category/categoryById'](category))
      }
      if (selectedData.imageInfo) {
        MediaService.createMedia(selectedData.imageInfo)
          .then(media => {
            this.article.heroImage = media.id
            this.article.expanded.heroImage = media
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
    setRecipe (recipe) {
      this.article.recipe = recipe.id
      this.article.expanded.recipe = recipe
      this.article.flag.recipe = true
      this.article.field.title = recipe.title
      this.article.field.shortTitle = recipe.title
      this.article.field.firstParagraph = recipe.description
      this.article.field.video = recipe.video
      this.article.field.videoType = recipe.videoType

      if (recipe.expanded.image) {
        MediaService.createMedia(recipe.expanded.image)
          .then(media => {
            this.article.heroImage = media.id
            this.article.expanded.heroImage = media
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
    setTest (test) {
      this.article.articleTest = test[0].id
      this.article.expanded.articleTest = test[0]
    },
    setPrintPublication (printPublications) {
      this.article.printPublication = printPublications[0].id
      this.article.expanded.printPublication = printPublications[0]
    },
    closeArticleEdit () {
      this.beforeRouteLeaveModal = false
      this.canCloseArticle = true
      this.$router.push(this.routeAfterCloseArticle.path)
    },
    setOrderDateToNow () {
      if (!this.articleEditDisabled) {
        this.article.orderDate = moment().utc().toISOString()
      }
    },
    isRubricSelected (rubric, index) {
      const selectedRubrics = this.rubricsBySite.filter(rubric => {
        if (rubric.id === this.article.mainRubric) {
          return rubric
        }
      })
      if (selectedRubrics.length === 0 && index === 0) {
        this.article.mainRubric = rubric.id
        return true
      }
      return rubric.id === this.article.mainRubric
    },
    showHeroVideoBox () {
      this.heroVideoBox = true
    },
    hideHeroVideoBox () {
      this.heroVideoBox = false
    },
    showErrors (transitionErrors) {
      this.transitionErrors = transitionErrors
      let errorFields = ''
      if (transitionErrors.authors) {
        errorFields = errorFields + this.$t('article.authors') + '<br>'
      }
      if (transitionErrors.field.title) {
        errorFields = errorFields + this.$t('article.title') + '<br>'
      }
      if (transitionErrors.field.shortTitle) {
        errorFields = errorFields + this.$t('article.short_title') + '<br>'
      }
      if (transitionErrors.field.subTitle) {
        errorFields = errorFields + this.$t('article.sub_title') + '<br>'
      }
      if (transitionErrors.field.firstParagraph) {
        errorFields = errorFields + this.$t('article.first_paragraph') + '<br>'
      }
      if (transitionErrors.field.bodyText) {
        errorFields = errorFields + this.$t('article.body_text') + '<br>'
      }
      if (transitionErrors.expanded.heroImage) {
        errorFields = errorFields + this.$t(this.heroVideoBox ? 'article.hero_video' : 'article.hero_image') + '<br>'
      }
      if (transitionErrors.field.video) {
        errorFields = errorFields + this.$t('article.video_id') + '<br>'
      }
      if (transitionErrors.categories) {
        errorFields = errorFields + this.$t('article.categories') + '<br>'
      }
      if (transitionErrors.sharedUsers) {
        errorFields = errorFields + this.$t('article.sharedUsers') + '<br>'
      }
      if (transitionErrors.natureOfTheArticle) {
        errorFields = errorFields + this.$t('article.nature_of_the_article') + '<br>'
      }
      if (transitionErrors.sourceType) {
        errorFields = errorFields + this.$t('article.source_type') + '<br>'
      }
      if (transitionErrors.safetyTopics) {
        errorFields = errorFields + this.$t('article.safety_topics') + '<br>'
      }
      if (transitionErrors.abTestVariantTitle) {
        errorFields = errorFields + this.$t('article.ab_test.title') + ' ' + this.$t('article.title') + '<br>'
      }
      if (transitionErrors.geneeaTags) {
        errorFields = errorFields + this.$t('article.tags_geneea') + '<br>'
      }
      if (transitionErrors.custom.paidContentEmbedMissing) {
        NotifyService.setErrorMessage(this.$t('article.notify.paidContentEmbedMissing'), '')
      }
      if (transitionErrors.custom.paidContentNotChecked) {
        NotifyService.setErrorMessage(this.$t('article.notify.paidContentNotChecked'), '')
      }
      if (transitionErrors.custom.paidContentEmbedMultiple) {
        NotifyService.setErrorMessage(this.$t('article.notify.paidContentEmbedMultiple'), '')
      }
      if (transitionErrors.custom.pageTitlesPageBreaksCount) {
        NotifyService.setErrorMessage(this.$t('article.notify.pageTitlesPageBreaksCount'), '')
      }
      if (errorFields.length) {
        NotifyService.setErrorMessage(this.$t('article.notify.please_fill_all_required_fields'), errorFields)
      }
    },
    isSuperAdmin () {
      return this.$store.getters['user/hasRole'](ROLE_SUPER_ADMIN)
    },
    resolveTypeAndSiteNameChanges (articleType, articleSiteName) {
      // pageTitles
      this.displayPageTitles = ['trend', 'enjoy', 'medialne', 'reality'].includes(articleSiteName)
      // embeds
      this.enabledEmbedButtons.sportOnline = false
      this.enabledEmbedButtons.sportTable = false
      this.enabledEmbedButtons.carTable = false
      this.enabledEmbedButtons.paidContentBreak = false
      this.enabledEmbedButtons.relatedDiseaseArticle = false
      this.showPaidContentCheckbox = false
      if (articleSiteName === 'sport24') {
        this.enabledEmbedButtons.sportOnline = true
        this.enabledEmbedButtons.sportTable = true
      } else if (articleSiteName === 'autobild') {
        this.enabledEmbedButtons.carTable = true
      } else if (['trend', 'enjoy', 'medialne', 'reality'].includes(articleSiteName) || this.vlm) {
        this.enabledEmbedButtons.paidContentBreak = true
        this.showPaidContentCheckbox = true
      } else if (['zdravie'].includes(articleSiteName)) {
        this.enabledEmbedButtons.relatedDiseaseArticle = false
      }
      if (articleType === 'test' && articleSiteName === 'autobild') {
        this.articleTestDiscriminators = this.articleTestDiscriminatorsAutobild
      } else if (articleType === 'test' && articleSiteName === 'enjoy') {
        this.articleTestDiscriminators = this.articleTestDiscriminatorsEnjoy
      } else {
        this.articleTestDiscriminators = []
      }
      // change type when type for site doesn't exist
      if (articleType === 'test' && ['autobild', 'enjoy'].includes(articleSiteName) === false) {
        this.article.type = 'article'
      } else if (articleType === 'longform' && ['trend', 'enjoy', 'medialne', 'reality', 'eva'].includes(articleSiteName) === false) {
        this.article.type = 'article'
      }
      // auto flags
      this.article.flag.quiz = articleType === 'quiz'
      this.article.flag.recipe = articleType === 'recipe'
    },
    setAbTestVariantBImage (medias) {
      this.article.abTestVariants.find((item) => item.variant === 'b').image = medias[0]
    },
    setAbTestVariantCImage (medias) {
      this.article.abTestVariants.find((item) => item.variant === 'c').image = medias[0]
    },
    removeAbTestVariantBImage () {
      this.article.abTestVariants.find((item) => item.variant === 'b').image = null
    },
    removeAbTestVariantCImage () {
      this.article.abTestVariants.find((item) => item.variant === 'c').image = null
    },
    toggleShowWoodWingExportModal () {
      this.showWoodWingExportModal = !this.showWoodWingExportModal
    },
    async exportWoodWing ({ issue, category }) {
      try {
        await this.updateDraft()
        await this.$store.dispatch('woodWing/export', {
          article: this.article.id,
          issue,
          category
        })
        this.toggleShowWoodWingExportModal()
        NotifyService.setSuccessMessage(this.$t('woodWing.export.success'))
        this.disableWoodWingExportButton = true
      } catch (e) {
        NotifyService.setErrorMessage(this.$t('woodWing.export.error'))
      }
    },
    async checkWoodWingExport (article) {
      if (article.type === this.ARTICLE_TYPE_ARTICLE && article.documentId) {
        try {
          await this.$store.dispatch('woodWing/existsWWExport', article.documentId)
          this.disableWoodWingExportButton = true
        } catch (e) {
          this.disableWoodWingExportButton = false
        }
      }
    },
    setGalleryGroup (id) {
      this.article.galleryGroup = id
    },
    saveUnsavedVersionToArticleLog () {
      if (this.article.setting.status !== 'draft') {
        return
      }
      CoreApi().post(`entityLog/${ENTITY_LOG_ARTICLE}`, {
        entityUuid: this.article.entityUuid,
        userId: this.$store.getters['user/currentUser'].id,
        data: JSON.stringify(this.prepareArticleRequest())
      }).then(() => {
        this.lastSuccessfulLogSaved = moment()
        this.articleLogSaveError = null
      }).catch(error => {
        const lastModifiedText = this.getLastModifiedText(this.lastSuccessfulLogSaved)
        if (isOfflineError(error)) {
          if (this.lastSuccessfulLogSaved) {
            this.articleLogSaveError = this.$t('article.modal.unsaved_version.offline_saved_version', { lastModifiedText })
          } else {
            this.articleLogSaveError = this.$t('article.modal.unsaved_version.offline_no_saved_version', { lastModifiedText })
          }
        } else {
          this.articleLogSaveError = this.$t('article.modal.unsaved_version.save_log_error', { lastModifiedText })
        }
      })
    },
    checkArticleLogForUnsavedVersion () {
      CoreApi().get(`/entityLog/${ENTITY_LOG_ARTICLE}/${this.article.entityUuid}`)
        .then(response => {
          if (response.status === 204) {
            this.unsavedVersionInfo = null
          } else {
            this.unsavedVersionInfo = {
              userId: response.data.userId,
              lastModifiedText: this.getLastModifiedText(response.data.lastModified)
            }
            this.lastSuccessfulLogSaved = moment(response.data.lastModified)
            this.showArticleLogUnsavedVersionModal()
          }
        })
        .catch(error => {
          console.error(error)
          NotifyService.setErrorMessage(error)
        })
    },
    getLastModifiedText (lastModified) {
      let date
      const time = this.$options.filters.prettyTime(lastModified)
      if (moment().isSame(lastModified, 'day')) {
        date = this.$t('article.modal.unsaved_version.today')
      } else if (moment().subtract(1, 'days').isSame(lastModified, 'day')) {
        date = this.$t('article.modal.unsaved_version.yesterday')
      } else {
        date = this.$options.filters.prettyDate(lastModified)
      }
      return this.$t('article.modal.unsaved_version.lastModifiedText', { date, time })
    },
    clearLoaderTimer () {
      if (this.loaderTimer) {
        clearTimeout(this.loaderTimer)
        this.loaderTimer = null
      }
    }
  },
  watch: {
    alternativeTitleCheckbox (checked) {
      if (!checked) {
        this.article.field.alternativeTitle = ''
      }
    },
    nmhEditorStoreData: {
      deep: true,
      handler: function () {
        if (this.anyUserAction) {
          this.canCloseArticle = false
        }
      }
    },
    articleSerialized () {
      if (this.anyUserAction) {
        this.canCloseArticle = false
      }
      const title = this.article.field.title
      const shortTitle = this.article.field.shortTitle
      if (title.substring(0, title.length - 1).trim() === shortTitle ||
        shortTitle.substring(0, shortTitle.length - 1).trim() === title) {
        this.article.field.shortTitle = this.article.field.title
      }
      const seoTitle = this.article.field.subTitle
      if (
        document.activeElement === this.$refs.article_field_title.$refs.article_field_title &&
        (
          title.substring(0, title.length - 1).trim() === seoTitle ||
          seoTitle.substring(0, seoTitle.length - 1).trim() === title
        )
      ) {
        this.article.field.subTitle = this.article.field.title
      }
      if (this.showPublishedSince === false) {
        this.article.publishedSince = this.article.orderDate
      }
      if (this.article.setting.abTested && this.article.abTestVariants.length === 0) {
        this.article.abTestVariants = this._.cloneDeep(ArticleModel.abTestVariants)
        this.article.abTestVariants[0].title = 'validate'
      }
      if (this.article.setting.abTested === false) {
        this.article.abTestVariants = []
      }
      if (this.article.expanded.gallery.length === 0) {
        this.article.galleryGroup = null
      }
    },
    articleType (newValue) {
      this.resolveTypeAndSiteNameChanges(newValue, this.articleSiteName)
    },
    'article.expanded.authors' (authors) {
      if (this.vlm) {
        this.article.authors = authors.map(author => author.id).filter(Boolean)
      }
    },
    'article.expanded.categories' (categories) {
      if (this.vlm) {
        this.article.categories = categories.map(category => category.id).filter(Boolean)
      }
    },
    'article.expanded.sharedUsers' (sharedUsers) {
      if (this.vlm) {
        this.article.sharedUsers = sharedUsers.map(sharedUser => sharedUser.id).filter(Boolean)
      }
    },
    articleSiteName (newValue) {
      this.resolveTypeAndSiteNameChanges(this.articleType, newValue)
      this.$store.commit('article/SET_CURRENT_SITE_NAME', newValue)
    },
    showPublishedSince (showPublishedSince) {
      if (showPublishedSince === false) {
        this.article.publishedSince = ''
      }
    },
    showPublishedUntil (showPublishedUntil) {
      if (showPublishedUntil === false) {
        this.article.publishedUntil = ''
      }
    },
    articleExpandedTags (newData, oldData) {
      if (oldData.length !== newData.length) {
        if (newData.length === 0) {
          this.article.expanded.selectedTopics = []
          this.article.selectedTopics = []
          return
        }
        const tagIds = newData.map(tag => tag.id)
        this.article.expanded.selectedTopics = this.article.expanded.selectedTopics.filter((topic) => {
          const tagsReqAndOpt = topic.tagsRequired.concat(topic.tagsOptional)
          return tagsReqAndOpt.some((tagId) => {
            return tagIds.includes(tagId)
          })
        })
        this.article.selectedTopics = this.article.expanded.selectedTopics.map(topic => topic.id)
      }
    },
    dataLoaded (newValue) {
      if (newValue === false) {
        this.loaderTimer = setTimeout(() => {
          NotifyService.setErrorMessage(this.$t('article.notify.draft_save_error_loader_stuck'))
          // this hides the loader and allows editing in case saving got stuck
          this.dataLoaded = true
        }, 60 * 1000)
      } else {
        this.clearLoaderTimer()
      }
    }
  },
  created () {
    this.$store.commit('nmhEditorStore/SET_BLOCKS', {
      deleteAllBlocks: true
    })
    window.onbeforeunload = function () {
      return 'Do you really want to leave our brilliant application?'
    }
    window.onscroll = function () {
      if (window.pageYOffset > 150) {
        document.getElementById('article-toolbar-bottom').style.bottom = '0'
      } else {
        document.getElementById('article-toolbar-bottom').style.bottom = '-55px'
      }
    }
    this.getFullArticle()
    const articleLogIntervalSeconds = config.articleSetting.articleLogInterval()
    const timeoutSeconds = articleLogIntervalSeconds ? +articleLogIntervalSeconds : DEFAULT_ARTICLE_LOG_INTERVAL
    this.articleLogInterval = setInterval(() => {
      if (this.canCloseArticle === false) {
        this.saveUnsavedVersionToArticleLog()
      }
    }, timeoutSeconds * 1000)
  },
  beforeRouteLeave (to, from, next) {
    if (this.canCloseArticle === false) {
      this.routeAfterCloseArticle = to
      this.showBeforeRouteLeaveModal()
    } else {
      next()
    }
  },
  beforeDestroy () {
    window.onbeforeunload = null
    window.onscroll = null
    clearInterval(this.articleLogInterval)
    this.clearLoaderTimer()
  }
}
</script>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.article-edit-view {
  &__section-title {
    font: 500 1.0625rem Roboto;
    color: #8a96ac;
    opacity: .7;
  }
  &__right-buttons {
    justify-content: flex-end;
    display: flex;
    gap: 1%;
  }
}

.article-edit {
  margin-bottom: 40px;
}

.article-log-save-error {
  margin-left: 1rem;
  margin-top: 1rem;
  color: #FF3455;
}

.orderDate-set-now {
  cursor: pointer;
  text-decoration: underline;
  margin-top: 0px;
  font-size: 0.85rem;
}

#article_mainRubric.form-control {
  padding: .375rem .25rem;
}

.unlock-article {
  text-align: center;
}
.article-edit-header {
  color: #bbb;
  font-weight: 100;
}

.create-tag-button {
  padding-right: rem(17px);
  margin-top: auto;
}

.keyword-generator-button {
  margin-top: rem(5px);
  margin-bottom: rem(8px);
}

.article-toolbar-bottom {
  position: fixed;
  bottom: -55px;
  left: 0;
  width: 100%;
  padding: 10px 20px 10px 20px;
  background: #ffffff;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: 3;
}

.mini-sidebar {
  .article-toolbar-bottom {
    padding-left: 80px;
  }
}

.form-ai-heading {
  display: flex;
  gap: rem(10px);
  align-items: center;
  margin-bottom: rem(5px);
}

@media (min-width: 768px) {
  .article-toolbar-bottom {
    padding-left: 80px;
  }
}

@media (min-width: 1169px) {
  .article-toolbar-bottom {
    padding-left: 260px;
  }
}
.article-edit-view-blocks {
  box-shadow: unset !important;
}
.output-window {
  @include font(400 13px "Fira Code");
  @include padding(20px);
  background: #fff;
  border: 1px solid #ccc;
  color: #2c3e50;
  pre {
    box-shadow: none;
    margin: 0;
  }
}
.failed-files {
  margin-bottom: 1rem;
  &__label {
    color: red;
  }
}

.ai-tools-checkbox {
  display: flex;

  .ai-tools-text-input {
    margin-left: 1rem;
  }
}
</style>
