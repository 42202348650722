<template>
  <app-modal
    :title="$t('hp.info_modal_header')"
    @close="close"
  >
    <!-- Body -->
    <template slot="body">
      <app-preloader v-if="dataLoaded === false"></app-preloader>
      <div v-if="dataLoaded">
        <table class="table table-striped table-bordered">
          <tbody>
          <tr>
            <th class="text-right" width="200">{{ $t('hp.type') }}:</th>
            <td>
              <span class="label label-info">{{ contentBlockItem.type }}</span>
            </td>
          </tr>
          <tr>
            <th class="text-right">{{ $t('hp.video_id') }}:</th>
            <td>
              {{ contentBlockItem.video }}
            </td>
          </tr>
          <tr>
            <th class="text-right">{{ $t('hp.published_since') }} - {{ $t('hp.published_until') }}:</th>
            <td>
              {{ contentBlockItem.publishedSince | prettyDateTime }} -
              {{ contentBlockItem.publishedUntil | prettyDateTime }}
            </td>
          </tr>
          <tr v-if="contentBlockItem.title">
            <th class="text-right">{{ $t('hp.title') }}:</th>
            <td>
              {{ contentBlockItem.title }}
            </td>
          </tr>
          <tr v-if="contentBlockItem.alternativeTitle">
            <th class="text-right">{{ $t('hp.alternative_title') }}:</th>
            <td>
              {{ contentBlockItem.alternativeTitle }}
            </td>
          </tr>
          <tr v-if="contentBlockItem.videoInfo.meta.description">
            <th class="text-right">{{ $t('video.meta_description') }}:</th>
            <td>
              {{ contentBlockItem.videoInfo.meta.description }}
            </td>
          </tr>
          <tr>
            <th class="text-right">{{ $t('hp.video') }}:</th>
            <td>
              <div class="col-md-6">
                <app-brightcove-video
                  v-if="contentBlockItem.videoInfo.type === 'brightcove' && contentBlockItem.videoInfo.brightcoveId > 0"
                  :id="contentBlockItem.videoInfo.brightcoveId"
                >
                </app-brightcove-video>
                <app-youtube-video
                  v-if="contentBlockItem.videoInfo.type === 'youtube'"
                  :id="contentBlockItem.videoInfo.youtubeId"
                >
                </app-youtube-video>
                <app-eagle-video
                  v-if="contentBlockItem.videoInfo.type === 'livebox'"
                  :video="contentBlockItem.videoInfo"
                >
                </app-eagle-video>
                <app-ooyala-video
                  v-if="contentBlockItem.videoInfo.type === 'ooyala'"
                  :id="contentBlockItem.videoInfo.ooyalaId"
                ></app-ooyala-video>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
  </app-modal>
</template>

<script>
import Preloader from '@/components/preloader/Preloader'
import Modal from '@/components/shared/Modal'
import BrightcoveVideo from '@/components/shared/BrightcoveVideo'
import YoutubeVideo from '@/components/shared/YoutubeVideo'
import OoyalaVideo from '@/components/shared/OoyalaVideo'
import EagleVideo from '@/components/shared/EagleVideo'

export default {
  name: 'InfoModal',
  props: {
    contentBlockItem: {
      type: Object
    }
  },
  components: {
    appModal: Modal,
    appPreloader: Preloader,
    appBrightcoveVideo: BrightcoveVideo,
    appOoyalaVideo: OoyalaVideo,
    appEagleVideo: EagleVideo,
    appYoutubeVideo: YoutubeVideo
  },
  data () {
    return {
      dataLoaded: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
