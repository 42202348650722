const messages = {
  list: {
    id: 'Id',
    category: 'Category',
    service: 'Service',
    headline: 'Headline',
    author: 'Author',
    status: 'Status',
    sites: 'Sites',
    contentCreated: 'Created at',
    show: 'Show'
  },
  filter: {
    category: 'Category',
    service: 'Service',
    headline: 'Headline',
    author: 'Author',
    status: 'Status',
    search: 'Search',
    created_at_from: 'Created at from',
    created_at_to: 'Created at to'
  },
  id: 'Id',
  category: 'Category',
  service: 'Service',
  headline: 'Headline',
  slugline: 'Slugline',
  author: 'Author',
  status: 'Status',
  raw: 'Raw',
  source: 'Source',
  contentCreated: 'Created at',
  content: 'Content',
  create_article: 'Create article',
  edit_article: 'Edit article',
  article_created: 'New TASR news article draft was created!',
  article_created_error: 'Error! Problem with creating for TASR news article.',
  article_not_found: 'Article not found',
  created_articles: 'Created articles',
  create_article_ai: 'Edit and create article from news (TREND)'
}

export default messages
