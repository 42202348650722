<template>
  <div>
    <div class="chart-header">
      <Tabs
        :tab-names="['uniqueUsers', 'pageViews', 'videoViews']"
        @tab-changed="chartModeChanged"
      >
        <template #default="{ index }">
          {{ $t(['dashboard.people', 'kpi.kpi_parameters.pageViews', 'videoStats.videoplays'][index]) }}
        </template>
      </Tabs>
      <Select
        v-model="lineChartSubMode"
        :options="lineChartSubModeOptions"
        :option-value="null"
        @change="refresh({ modeChanged: true })"
        id="select_line_chart_sub_mode"
        no-label
        no-empty-value
        disable-form-group
      />
    </div>
    <div class="preloader-wrapper">
      <Preloader v-show="loading" />
    </div>
    <LineChart
      :chart-data="lineChartData"
      :height="250"
      :max-labels="25"
    />
  </div>
</template>

<script>
import LineChart from '@/components/chart/LineChart'
import Select from '@/components/form/select/Select'
import moment from 'moment'
import { dataHubRealtimeApi } from '@/api/datahub'
import Preloader from '@/components/preloader/Preloader'
import Tabs from '@/components/shared/Tabs'

export default {
  name: 'RealtimeStatisticsChart',
  props: {
    chartApiData: {
      type: Object,
      required: false
    },
    isArticleChart: {
      type: Boolean,
      default: false
    },
    documentId: {
      type: String,
      required: false
    }
  },
  components: {
    Tabs,
    Preloader,
    Select,
    LineChart
  },
  data () {
    const emptyLineChartData = {
      labels: [],
      datasets: []
    }
    return {
      loading: false,
      lineChartMode: 'uniqueUsers',
      lineChartSubMode: null,
      lineChartSubModeOptions: [],
      emptyLineChartData,
      lineChartData: emptyLineChartData,
      datasetColor: {
        uniqueUsersBorder: 'rgba(255, 178, 43, 1)',
        uniqueUsersBackground: 'rgba(255, 178, 43, 0.4)',
        pageViewsBorder: 'rgba(248, 121, 121, 1)',
        pageViewsBackground: 'rgba(248, 121, 121, 0.4)',
        videoViewsBorder: 'rgba(22, 147, 193, 1)',
        videoViewsBackground: 'rgba(22, 147, 193, 0.4)'
      }
    }
  },
  watch: {
    chartApiData () {
      this.refresh()
    }
  },
  computed: {
    baseSubModeOptions () {
      return [
        { id: 'all-heading', title: this.$t('dashboard.all'), lvl: 0, heading: true, disabled: true },
        { id: 'all', title: this.$t('dashboard.sum'), lvl: 1 }
      ]
    }
  },
  methods: {
    refresh ({ modeChanged } = { modeChanged: false }) {
      if (this.isArticleChart) {
        const articleData = this.$store.getters['realtimeStatistics/getArticleChartApiData'](this.documentId)
        if (this._.isEmpty(articleData)) {
          this.lineChartData = this.emptyLineChartData
        } else {
          if (modeChanged) {
            const updatedModes = {
              lineChartMode: this.lineChartMode,
              lineChartSubMode: this.lineChartSubMode
            }
            this.$store.commit(
              'realtimeStatistics/updateChartModes',
              { documentId: this.documentId, data: updatedModes }
            )
          } else {
            this.lineChartMode = articleData.lineChartMode
            this.lineChartSubMode = articleData.lineChartSubMode
          }
          this.lineChartData = this.createArticleChartData(articleData.apiData)
        }
      } else {
        if (this._.isEmpty(this.chartApiData)) {
          this.lineChartData = this.emptyLineChartData
        } else {
          this.lineChartData = this.createLineChartData(this.chartApiData)
        }
      }
    },
    async reload () {
      if (this.isArticleChart) {
        this.loading = true
        let articleChartApiData = []
        await dataHubRealtimeApi().get(`/article?documentId=${this.documentId}`).then(response => {
          articleChartApiData = response.data
        }).catch(error => {
          console.error(error)
        })
        const articleData = {
          apiData: articleChartApiData,
          lineChartMode: this.lineChartMode,
          lineChartSubMode: this.lineChartSubMode
        }
        this.$store.commit(
          'realtimeStatistics/addArticleChartApiData',
          { documentId: this.documentId, data: articleData }
        )
        this.refresh()
        this.loading = false
      } else {
        // pass prop 'chartApiData' if you want to reload
      }
    },
    chartModeChanged ({ tabName }) {
      this.lineChartMode = tabName
      this.refresh({ modeChanged: true })
    },
    getDatasetLabel (oneDayViews) {
      return this.$options.filters.prettyDate(Object.keys(oneDayViews[0])[0] + 'Z')
    },
    trimZeroMetrics (metrics) {
      let idx = metrics.length - 1
      while (metrics[idx] === 0) idx--
      return metrics.slice(0, idx + 1)
    },
    getChartViewsBySubMode (chartApiData) {
      const chart = this._.get(chartApiData, this.lineChartMode)
      const views = this._.get(chart, this.lineChartSubMode.id)
      this.lineChartSubModeOptions = [
        ...this.baseSubModeOptions,
        { id: 'channel', title: this.$t('dashboard.channel'), lvl: 0, heading: true, disabled: true },
        ...Object.keys(chart.channel).map(subMode => ({ id: `channel.${subMode}`, title: subMode, lvl: 1 })),
        { id: 'device', title: this.$t('dashboard.device'), lvl: 0, heading: true, disabled: true },
        ...Object.keys(chart.device).map(subMode => ({ id: `device.${subMode}`, title: this.$t(`dashboard.${subMode}`), lvl: 1 }))
      ]
      return views
    },
    getChartData (oneDayViews) {
      const labels = []
      const labelsAndMetrics = []
      let metrics = []
      let previousUtcDateTime = moment(Object.keys(oneDayViews[0])[0] + 'Z')

      const diffInDays = (a, b) => Math.abs(a.startOf('day').diff(b.startOf('day'), 'days'))

      const addToLabelsAndMetrics = () => {
        if (labelsAndMetrics.length > 0) {
          const previousMetrics = labelsAndMetrics[labelsAndMetrics.length - 1].metrics
          metrics = previousMetrics.slice(0, -1).map(() => null).concat(metrics)
        }
        labelsAndMetrics.push({
          label: this.$options.filters.prettyDate(previousUtcDateTime),
          metrics
        })
      }

      (oneDayViews.length === 1 ? [oneDayViews[0], oneDayViews[0]] : oneDayViews)
        .forEach((view, index) => {
          const dateTime = Object.keys(view)[0]
          const metric = view[dateTime]
          const utcDateTime = moment(dateTime + 'Z')
          labels.push(utcDateTime.format('HH:mm'))

          if (index < oneDayViews.length - 1 && diffInDays(utcDateTime, previousUtcDateTime) > 0) {
            metrics.push(metric)
            addToLabelsAndMetrics()
            metrics = []
          }
          metrics.push(metric)
          previousUtcDateTime = utcDateTime
        })

      addToLabelsAndMetrics()
      if (labels[labels.length - 1] === '00:00') {
        labels[labels.length - 1] = '24:00'
      }
      return { labels, metrics: labelsAndMetrics[0].metrics, labelsAndMetrics }
    },
    createLineChartData (chartApiData) {
      const views = this.getChartViewsBySubMode(chartApiData)
      const datasetMetricsToday = this.trimZeroMetrics(views.today.map(view => Object.values(view)[0]))
      const lastWeekChartData = this.getChartData(views.lastWeek)

      return {
        labels: lastWeekChartData.labels,
        datasets: [
          {
            label: this.getDatasetLabel(views.today),
            borderColor: this.datasetColor[this.lineChartMode + 'Border'],
            backgroundColor: this.datasetColor[this.lineChartMode + 'Background'],
            data: datasetMetricsToday
          },
          {
            label: this.getDatasetLabel(views.lastWeek),
            backgroundColor: 'rgba(204, 204, 204, 0.5)',
            data: lastWeekChartData.metrics
          }
        ]
      }
    },
    createArticleChartData (chartApiData) {
      const views = this.getChartViewsBySubMode(chartApiData)
      const { labels, labelsAndMetrics } = this.getChartData(views)

      const calculateRgbaAlfaStep = () => {
        const MIN_STEP = 10
        const MIN_CEIL = 40
        const MAX_CEIL = 100

        const ceil = labelsAndMetrics.length <= 2 ? MIN_CEIL : MAX_CEIL
        const step = Math.floor(ceil / labelsAndMetrics.length) - 1

        return Math.max(step, MIN_STEP)
      }

      /**
       * @param {string} rgbaColor e.g. 'rgba(248, 121, 121, 0.4)',
       * @param {number} rgbaAlfaDecimal only the decimal part e.g 5, 435, 65, etc.
       */
      const adjustRgbaAlfa = (rgbaColor, rgbaAlfaDecimal) => {
        const colorArray = rgbaColor.split('')
        colorArray[colorArray.length - 2] = rgbaAlfaDecimal.toString()
        return colorArray.join('')
      }

      const step = calculateRgbaAlfaStep()
      const datasets = labelsAndMetrics.map(({ metrics, label }, index) => {
        const rgbaAlfaDecimal = (labelsAndMetrics.length - index) * step
        const backgroundColor = adjustRgbaAlfa(this.datasetColor[this.lineChartMode + 'Background'], rgbaAlfaDecimal)
        return {
          label,
          borderColor: this.datasetColor[this.lineChartMode + 'Border'],
          backgroundColor,
          data: metrics
        }
      })

      return {
        labels,
        datasets
      }
    }
  },
  mounted () {
    this.lineChartSubModeOptions = this.baseSubModeOptions
    this.lineChartSubMode = this.baseSubModeOptions[1]
    this.refresh()
  }
}
</script>
<style lang="scss" scoped>
.chart-header {
  display: flex;
  justify-content: space-between;
  gap: rem(10px);
}
.preloader-wrapper {
  position: relative;
  width: 100%;
  height: rem(1px);
  margin-top: rem(60px);
  margin-bottom: rem(-60px);
}
</style>
