<template>
  <section class="tasr-news-create-article-buttons__header-buttons">
    <button
      type="button"
      class="btn btn-green"
      :disabled="disabledCreateArticleButton"
      @click="create(false)"
    >
      {{ $t('tasrNews.create_article') }}
    </button>
    <button
      type="button"
      class="btn btn-sm btn-green btn-generate"
      :disabled="disabledCreateArticleButton"
      @click="create(true)"
    >
      <span class="btn-generate__content">
        <IconMagicWand
          class="btn-generate__icon"
          :class="{ 'loading-animation': refactoredArticleGenerating }"
        />
      </span>
      <span class="btn-generate__label">
        {{ $t('tasrNews.create_article_ai') }}
      </span>
    </button>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { dateTimeNow } from '@/services/DateTimeService'
import NotifyService from '../../services/NotifyService'
import UserMixin from '../mixins/User'
import TasrNewsCategoryService from '../../services/tasr/TasrNewsCategoryService'
import coreApi from '@/api/core'
import IconMagicWand from '@/assets/img/svg/wand-magic-sparkles-solid.svg?inline'

const ARTICLE_AUTHOR_NAME = 'TASR'
const DEFAULT_SITE_NAME = 'trend'

export default {
  name: 'TasrNewsCreateArticleButtons',
  mixins: [UserMixin],
  components: {
    IconMagicWand
  },
  props: {
    tasrNews: {
      type: Object
    }
  },
  data () {
    return {
      disabledCreateArticleButton: false
    }
  },
  computed: {
    ...mapState('tasrNews', ['refactoredArticleGenerating']),
    aiFeaturesUsagePayload () {
      return {
        userId: this.$store.getters['user/currentUser'].id,
        siteId: null,
        articleId: null,
        articleDocumentId: null,
        aiFeatureType: 'tasr_preprocessing_trend'
      }
    },
    sites () {
      return this.$store.getters['site/enabledSites']()
    },
    getDefaultSite () {
      return this.$store.getters['site/mapNamesToSites']([DEFAULT_SITE_NAME])
    }
  },
  methods: {
    async getAuthorId () {
      try {
        const response = await coreApi().get(`/author?filter_eq[name]=${ARTICLE_AUTHOR_NAME}`)
        if (response?.data?.data?.length > 0) {
          return response.data.data[0].id
        }
      } catch (error) {
        console.error(error)
        NotifyService.setErrorMessage(error)
      }
      return undefined
    },
    async mapTasrNewsToArticle (authorId, isAiGenerated = false) {
      let site = null
      let rubric = null
      let payload = null
      // Trend.id == 18
      if (this.getUserSite() !== 18 && this.tasrNews.category) {
        const category = TasrNewsCategoryService.getCategoryById(this.tasrNews.category)
        site = (category && category.site) ? category.site : null
        rubric = (category && category.rubric) ? category.rubric : null
      }

      payload = {
        site: site !== null ? site : this.getUserSite(),
        mainRubric: rubric !== null ? rubric : await this.getUserRubric(),
        field: {
          title: this.tasrNews.headline,
          shortTitle: this.tasrNews.headline,
          subTitle: this.tasrNews.headline,
          bodyText: this.tasrNews.content
        },
        authors: [],
        expanded: {
          authors: [
            { id: authorId }
          ]
        },
        setting: {
          editorialArticle: true,
          agencyArticle: true
        },
        publishedSince: dateTimeNow(),
        orderDate: dateTimeNow()
      }

      if (isAiGenerated) {
        payload.setting.aiAssisted = true
        const aiContent = await this.getAiUpdatedTasrNewsContent(this.tasrNews.content)

        if (aiContent?.reformattedText) {
          payload.field.bodyText = aiContent.reformattedText
        } else {
          payload.field.bodyText = this.tasrNews.content
        }
      }
      return payload
    },
    async create (isAiGenerated = false) {
      this.disabledCreateArticleButton = true
      const authorId = await this.getAuthorId()
      if (!authorId) {
        this.disabledCreateArticleButton = false
        return
      }
      const payload = await this.mapTasrNewsToArticle(authorId, isAiGenerated)
      this.$store.dispatch('article/create', payload)
        .then(() => {
          this.disabledCreateQuizArticleButton = false
          if (this.$store.getters['article/error']) {
            NotifyService.setErrorMessage(this.$store.getters['article/error'])
            return
          }
          const newArticle = this.$store.getters['article/fullArticle']
          if (newArticle.id > 0) {
            this.$store.commit('tasrNews/setTasrOriginalContent', {
              articleId: newArticle.id,
              content: this.tasrNews.content
            })
            NotifyService.setSuccessMessage(this.$t('tasrNews.article_created'))
            if (isAiGenerated) {
              this.aiFeaturesUsagePayload.siteId = newArticle.site
              this.aiFeaturesUsagePayload.articleId = newArticle.id
              this.aiFeaturesUsagePayload.articleDocumentId = newArticle.documentId
              this.$store.dispatch('aiStore/logAiFeaturesUsage', this.aiFeaturesUsagePayload)
            }
            this.$emit('set-article-and-save', newArticle)
            this.$router.push('/article/' + newArticle.id + '/edit')
          }
        })
        .catch(error => {
          this.disabledCreateQuizArticleButton = false
          console.log(error)
        })
    },
    async getAiUpdatedTasrNewsContent (content) {
      try {
        const response = await this.$store.dispatch('tasrNews/fetchRefactoredArticle', {
          article: content,
          siteUuid: this.getDefaultSite[0].entityUuid
        })
        if (this.$store.getters['tasrNews/error']) {
          NotifyService.setErrorMessage(this.$store.getters['tasrNews/error'])
          return content
        }
        return response
      } catch (error) {
        console.log(error)
        return content
      }
    }
  }
}
</script>

<style lang="scss">
.tasr-news-create-article-buttons {
  &__header-buttons {
    display: flex;
    gap: rem(10px);
  }
}
.btn-generate {
  display: flex;
  gap: rem(5px);
  align-items: center;
  font-size: rem(13px);
  background: #d99eff;
  &--disabled {
    cursor: default !important;
    opacity: 1 !important;
    background: rgba(65, 184, 131, 0.5);
  }
  &__content {
    width: rem(17px);
    height: rem(17px);
  }
  &__icon {
    fill: #FFFFFF;
  }
}
.loading-animation {
  animation: blick-load 1.8s linear infinite;
}
@keyframes blick-load {
  0% {
    fill: rgba(220, 47, 67, 0.68);
    transform:rotate(0deg);
  }
  50% {
    fill: #636de5;
  }
  100% {
    fill: rgba(220, 47, 67, 0.68);
    transform:rotate(360deg);
  }
}
</style>
