const messages = {
  list: {
    id: 'Id',
    category: 'Kategória',
    service: 'Servis',
    headline: 'Titulok',
    author: 'Autor',
    status: 'Status',
    sites: 'Stránky',
    contentCreated: 'Čas správy',
    show: 'Zobraziť'
  },
  filter: {
    category: 'Kategória',
    service: 'Servis',
    headline: 'Titulok',
    author: 'Autor',
    status: 'Status',
    search: 'Hľadať',
    created_at_from: 'Čas správy od',
    created_at_to: 'Čas správy do'
  },
  id: 'Id',
  category: 'Kategória',
  service: 'Servis',
  headline: 'Titulok',
  slugline: 'Kľúčové slová',
  author: 'TASR autor',
  status: 'Status',
  raw: 'Raw',
  source: 'Zdroj',
  contentCreated: 'Čas správy',
  content: 'Obsah',
  create_article: 'Vytvor článok zo správy',
  edit_article: 'Edituj článok',
  article_created: 'Vytvorený koncept z TASR správy',
  article_created_error: 'Chyba! Problem s vytvorením TASR článku',
  article_not_found: 'Article not found',
  created_articles: 'Vytvorené články zo správy',
  create_article_ai: 'Edituj a vytvor článok zo správy (TREND)'
}

export default messages
