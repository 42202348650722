const messages = {
  siteName: 'Server',
  appId: 'AppId',
  apiKey: 'ApiKey',
  utm: 'Utm',
  limit: 'Limit',
  limit_placeholder: 'Info: 0 for unlimited daily limit',
  reset: 'Reset',
  active: 'Active',
  addSite: 'Add server'
}

export default messages
