<template>
  <app-modal
    :title="$tc('hp.create_video_modal_header')"
    @close="close"
  >
    <!-- Header -->
    <template v-slot:header>
      <button
        v-if="!videoModal"
        type="button"
        class="btn btn-success"
        @click="save"
        data-test="hp_item_create_save"
        :disabled="disabledSaveButton"
      >
        <i class="fa fa-save "></i> {{ $t('buttons.save') }}
      </button>
      <button
        v-if="videoModal"
        type="button"
        class="btn btn-default"
        @click="closeVideoModal"
        data-test="btn_back"
      >
        {{ $t('buttons.back') }}
      </button>
    </template>

    <!-- Body -->
    <template v-slot:body>
      <section
        v-if="!videoModal"
        class="content form-horizontal"
      >
        <div class="row">
          <div class="col-lg-12">
            <input type="hidden" v-model="item.id">
            <div class="row">
              <div class="col-lg-3">
                <app-datepicker
                  v-model="item.publishedSince"
                  @blur="$v.compareDates.$touch()"
                  :error="$v.compareDates.$error"
                  id="item_publishedSince"
                  :label="$t('hp.published_since')"
                  @change="checkGap"
                >
                </app-datepicker>
              </div>
              <div class="col-lg-3">
                <app-datepicker
                  v-model="item.publishedUntil"
                  @blur="$v.compareDates.$touch()"
                  :error="$v.compareDates.$error"
                  id="item_publishedUntil"
                  :label="$t('hp.published_until')"
                  @change="checkGap"
                >
                </app-datepicker>
              </div>
              <div class="col-lg-3 text-right">
                <app-select
                  v-model="item.position"
                  :options="positions"
                  id="item_position"
                  :label="contentBlock.title + ' position'"
                  @change="checkGap"
                  no-empty-value
                >
                </app-select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <app-input
                  v-model="item.video"
                  @blur="$v.item.video.$touch()"
                  @input="fetchVideo"
                  :error="$v.item.video.$error"
                  id="item_video"
                  label="Video ID"
                  disable-form-group
                  disabled
                >
                </app-input>
              </div>
              <div class="col-md-3">
                <button
                  class="btn btn-info m-t-25"
                  @click="showVideoModal"
                  data-test="choose_article"
                >
                  <i class="fas fa-video"></i>
                  {{ $t('buttons.select_video') }}
                </button>
              </div>
              <div class="col-md-6">
                <app-input
                  v-model="item.alternativeTitle"
                  id="item_alternativeTitle"
                  :label="$t('hp.alternative_title')"
                  show-counter
                >
                </app-input>
              </div>
              <div class="col-lg-12" v-if="video.id">
                <h4>{{ $t('hp.video_info') }}</h4>
                <div class="row">
                  <div class="col-lg-6">
                    <app-brightcove-video
                      v-if="isBrightcove"
                      :id="video.brightcoveId"
                    >
                    </app-brightcove-video>
                    <app-youtube-video
                      v-if="isYoutube"
                      :id="video.youtubeId"
                    >
                    </app-youtube-video>
                    <app-eagle-video
                      v-if="isLivebox"
                      :video="video"
                    >
                    </app-eagle-video>
                    <app-ooyala-video
                      v-if="isOoyala"
                      :id="video.ooyalaId"
                    ></app-ooyala-video>
                  </div>
                  <div :class="[(isBrightcove || isYoutube || isOoyala || isLivebox) ? 'col-lg-6' : 'col-lg-12']">
                    <table class="table table-striped">
                      <tbody>
                      <tr>
                        <th>{{ $t('video.meta_title') }}</th>
                        <td>{{ video.meta.title }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('video.status') }}</th>
                        <td>
                          <app-video-status :video="video"></app-video-status>
                        </td>
                      </tr>
                      <tr>
                        <th>{{ $t('video.type') }}</th>
                        <td>{{ video.type }}</td>
                      </tr>
                      <tr v-if="isBrightcove">
                        <th>{{ $t('video.ooyala_id') }}</th>
                        <td>{{ video.brightcoveId }}</td>
                      </tr>
                      <tr v-if="isYoutube">
                        <th>{{ $t('video.youtube_id') }}</th>
                        <td>{{ video.youtubeId }}</td>
                      </tr>
                      <tr v-if="isOoyala">
                        <th>{{ $t('video.ooyala_id') }}</th>
                        <td>{{ video.ooyalaId }}</td>
                      </tr>
                      <tr v-if="isLivebox">
                        <th>{{ $t('video.livebox_asset_id') }}</th>
                        <td>{{ video.liveboxAssetId }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <app-video-list
        v-if="videoModal"
        class="video-list"
        disable-breadcrumb
        @set-data="setVideo($event); fetchVideo();"
        @close="closeVideoModal"
      >
      </app-video-list>
    </template>
  </app-modal>
</template>

<script>
import Moment from 'moment'
import Input from '@/components/form/inputs/Input'
import Datepicker from '@/components/form/Datepicker'
import Select from '@/components/form/select/Select'
import { required } from 'vuelidate/lib/validators'
import ContentBlockItemModel from '@/model/ContentBlockItem'
import VideoModel from '@/model/Video'
import NotifyService from '@/services/NotifyService'
import CoreApi from '@/api/core'
import { TYPE_VIDEO } from '@/model/ValueObject/ContentBlockItemTypes'
import Modal from '@/components/shared/Modal'
import VideoList from '@/components/shared/VideoList'
import BrightcoveVideo from '@/components/shared/BrightcoveVideo'
import YoutubeVideo from '@/components/shared/YoutubeVideo'
import OoyalaVideo from '@/components/shared/OoyalaVideo'
import EagleVideo from '@/components/shared/EagleVideo'
import VideoStatus from '@/components/video/VideoStatus'

export default {
  name: 'ContentBlockItemNew',
  props: {
    contentBlockId: {
      type: Number
    },
    position: {
      type: Number,
      default: 1
    },
    publishedSince: {
      type: String
    },
    publishedUntil: {
      type: String
    }
  },
  data () {
    return {
      dataLoaded: false,
      isLoading: false,
      item: this._.cloneDeep(ContentBlockItemModel),
      video: VideoModel,
      disabledSaveButton: false,
      videoModal: false
    }
  },
  validations: {
    item: {
      publishedSince: {
        required
      },
      publishedUntil: {
        required
      },
      video: {
        required
      }
    },
    compareDates: {
      required
    }
  },
  computed: {
    isBrightcove () {
      return this.video.type === 'brightcove' && this.video.brightcoveId > 0
    },
    isOoyala () {
      return this.video.type === 'ooyala'
    },
    isYoutube () {
      return this.video.type === 'youtube'
    },
    isLivebox () {
      return this.video.type === 'livebox'
    },
    compareDates () {
      if (Moment(this.item.publishedSince).isAfter(this.item.publishedUntil)) {
        return ''
      }
      return true
    },
    contentBlock () {
      return this.$store.getters['contentBlock/getContentBlockById'](this.contentBlockId)
    },
    positions () {
      var positions = []
      if (this.contentBlock) {
        for (let i = 1; i <= this.contentBlock.numberOfItems; i++) {
          positions.push(
            {
              id: i,
              title: i
            }
          )
        }
      }
      return positions
    }
  },
  components: {
    appModal: Modal,
    appInput: Input,
    appSelect: Select,
    appDatepicker: Datepicker,
    appVideoList: VideoList,
    appBrightcoveVideo: BrightcoveVideo,
    appYoutubeVideo: YoutubeVideo,
    appOoyalaVideo: OoyalaVideo,
    appEagleVideo: EagleVideo,
    appVideoStatus: VideoStatus
  },
  methods: {
    addOneSecondToPublishedSince (publishedSince) {
      const date = Moment(publishedSince)
      date.add(1, 's')
      return date.toISOString()
    },
    prepareRequest (item) {
      if (item.publishedSince) {
        item.publishedSince = this.addOneSecondToPublishedSince(item.publishedSince)
      }
      item.video = parseInt(item.video)
      item.type = TYPE_VIDEO
      delete item.articleDocumentId
      return item
    },
    async save () {
      this.disabledSaveButton = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        this.disabledSaveButton = false
        return
      }
      this.$store.dispatch('contentBlockItem/create', this.prepareRequest(this.item))
        .then(() => {
          this.disabledSaveButton = false
          if (this.$store.getters['contentBlockItem/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
            this.$emit('change')
            this.$emit('close')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['contentBlockItem/error'])
          }
        })
        .catch(error => {
          this.disabledSaveButton = false
          console.log(error)
        })
    },
    fetchVideo () {
      if (!this.item.video) {
        return
      }
      this.video = VideoModel
      const url = `/video/${this.item.video}`
      CoreApi().get(url)
        .then(response => {
          if (response.data) {
            this.video = response.data
          } else {
            NotifyService.setErrorMessage(this.$t('hp.article_not_found'))
          }
        })
        .catch(error => console.log(error))
    },
    checkGap ($event) {
      if (event instanceof MessageEvent) {
        return
      }
      let url = '' +
        'contentBlock/' + this.item.contentBlock +
        '/checkGap?position=' + this.item.position +
        '&startDatetime=' + this.addOneSecondToPublishedSince(this.item.publishedSince) +
        '&endDatetime=' + this.item.publishedUntil

      if (this.item.id) {
        url = url + '&contentBlockItemId=' + this.item.id
      }

      CoreApi().get(url)
        .then(response => {
          if (response.status === 200) {
            if (response.data.isPositionEmpty === false) {
              NotifyService.setErrorMessage(this.$t('hp.gap_busy'))
            }
          }
        })
        .catch(error => console.log(error))
    },
    close () {
      this.$emit('close')
    },
    setImage (medias) {
      medias.forEach(media => {
        this.item.imageInfo = media
        this.item.image = media.id
      })
    },
    removeImage () {
      this.item.image = null
      this.item.imageInfo = null
    },
    showVideoModal () {
      this.videoModal = true
    },
    closeVideoModal () {
      this.videoModal = false
    },
    setVideo (video) {
      this.item.videoInfo = video
      this.item.video = video.id
      this.item.title = video.meta.title
    }
  },
  created () {
    this.item.contentBlock = this.contentBlockId
    this.item.position = this.position
    this.item.publishedSince = this.publishedSince
    this.item.publishedUntil = this.publishedUntil
    this.dataLoaded = true
  }
}
</script>

<style lang="scss" scoped>
::v-deep .video-list > div {
  display: none;
}
</style>
