<template>
  <Modal
    :title="$tc('hp.info_modal_header')"
    @close="close"
  >
    <!-- Body -->
    <template slot="body">
      <div>
        <table
          class="table table-striped table-bordered"
          v-if="contentBlockItem.type === 'external'"
        >
          <tbody>
          <tr>
            <th class="text-right" width="200">{{ $t('hp.type') }}:</th>
            <td>
              <span class="label label-info">{{ contentBlockItem.type }}</span>
            </td>
          </tr>
          <tr v-if="contentBlockItem.title">
            <th class="text-right" width="200">{{ $t('hp.title') }}:</th>
            <td>
              {{ contentBlockItem.title }}
            </td>
          </tr>
          <tr v-if="contentBlockItem.alternativeTitle">
            <th class="text-right" width="200">{{ $t('hp.alternative_title') }}:</th>
            <td>
              {{ contentBlockItem.alternativeTitle }}
            </td>
          </tr>
          <tr>
            <th class="text-right">{{ $t('hp.article.url') }}</th>
            <td>
              <a :href="contentBlockItem.url" target="_blank">{{ contentBlockItem.url }}</a>
            </td>
          </tr>
          <tr>
            <th class="text-right">{{ $t('hp.published_since') }} - {{ $t('hp.published_until') }}:</th>
            <td>
              {{ contentBlockItem.publishedSince | prettyDateTime }} -
              {{ contentBlockItem.publishedUntil | prettyDateTime }}
            </td>
          </tr>
          <tr>
            <th class="text-right">{{ $t('hp.image') }}:</th>
            <td>
              <div class="row">
                <div class="col-lg-6">
                  <app-media
                    v-if="contentBlockItem.imageInfo"
                    :media="contentBlockItem.imageInfo"
                    :width="480"
                    :height="270"
                  >
                  </app-media>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th class="text-right">{{ $t('hp.article.first_paragraph') }}</th>
            <td>{{ contentBlockItem.firstParagraph }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div>
        <table
          class="table table-striped table-bordered"
          v-if="contentBlockItem.type === 'external'"
        >
          <tbody>
          <tr>
            <th width="200" class="text-right">{{ $t('hp.article.site_name') }}:</th>
            <td width="30%">{{ contentBlockItem.externalSiteName }}</td>
            <th width="200" class="text-right">{{ $t('hp.article.site_url') }}:</th>
            <td>{{ contentBlockItem.siteUrl }}</td>
          </tr>
          <tr>
            <th class="text-right">{{ $t('hp.article.rubric_name') }}:</th>
            <td>{{ contentBlockItem.siteRubricName }}</td>
            <th class="text-right">{{ $t('hp.article.rubric_url') }}:</th>
            <td>{{ contentBlockItem.siteRubricUrl }}</td>
          </tr>
          <tr>
            <th class="text-right">{{ $t('hp.article.author') }}:</th>
            <td>{{ contentBlockItem.externalAuthor }}</td>
            <th class="text-right">{{ $t('hp.article.flags') }}:</th>
            <td>
              <app-flags :flag="contentBlockItem.flag"></app-flags>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <app-preloader v-if="!dataLoaded"></app-preloader>
      <div v-if="dataLoaded">
        <p v-if="contentBlockItem.type === 'article' && article.setting.status !== 'published'"
           class="alert alert-warning">
          <i class="fa fa-exclamation-triangle"></i>
          {{ $t('hp.article_status_warning') }}
        </p>
        <table class="table table-striped table-bordered" v-if="contentBlockItem.type === 'article'">
          <tbody>
          <tr>
            <th class="text-right" width="200">{{ $t('hp.type') }}:</th>
            <td>
              <span class="label label-info">{{ contentBlockItem.type }}</span>
            </td>
          </tr>
          <tr>
            <th class="text-right" width="250">{{ $t('hp.article.status') }}:</th>
            <td>
              <app-article-status :article="article"></app-article-status>
            </td>
          </tr>
          <tr>
            <th class="text-right">{{ $t('hp.article_document_id') }}:</th>
            <td>
              {{ article.documentId }}
            </td>
          </tr>
          <tr v-if="contentBlockItem.title">
            <th class="text-right">{{ $t('hp.title') }}:</th>
            <td>
              {{ contentBlockItem.title }}
            </td>
          </tr>
          <tr v-if="contentBlockItem.alternativeTitle">
            <th class="text-right">{{ $t('hp.alternative_title') }}:</th>
            <td>
              {{ contentBlockItem.alternativeTitle }}
            </td>
          </tr>
          <tr>
            <th class="text-right">{{ $t('hp.article.url') }}:</th>
            <td>
              <app-article-url-info
                v-if="article"
                :article="article"
                hide-copy-to-clipboard-button
                hide-refresh-url-button
                hide-labels
              >
              </app-article-url-info>
            </td>
          </tr>
          <tr>
            <th class="text-right">{{ $t('hp.published_since') }} - {{ $t('hp.published_until') }}:</th>
            <td>
              {{ contentBlockItem.publishedSince | prettyDateTime }} -
              {{ contentBlockItem.publishedUntil | prettyDateTime }}
            </td>
          </tr>
          <tr>
            <th class="text-right">{{ $t('hp.article.order_date') }}:</th>
            <td>
              {{ article.orderDate | prettyDateTime }}
            </td>
          </tr>
          <tr>
            <th class="text-right">{{ $t('hp.article.author') }}:</th>
            <td>
              <span
                v-for="(author, index) in article.expanded.authors"
                :key="`item-${index}`"
              >
                {{ author.name }}<br>
              </span>
            </td>
          </tr>
          <tr>
            <th class="text-right">{{ $t('hp.article.rubric') }}:</th>
            <td>
              {{ article.expanded.site.title }} /
              {{ article.expanded.mainRubric.title }}
            </td>
          </tr>
          <tr>
            <th class="text-right">{{ $t('hp.article.image') }}:</th>
            <td>
              <div class="row">
                <div class="col-lg-6">
                  <app-media
                    v-if="article.expanded.listingImage"
                    :media="article.expanded.listingImage"
                    :width="480"
                    :height="270"
                  >
                  </app-media>
                  <app-media
                    v-else-if="article.expanded.heroImage"
                    :media="article.expanded.heroImage"
                    :width="400"
                    :height="270"
                  >
                  </app-media>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th class="text-right">{{ $t('hp.article.first_paragraph') }}:</th>
            <td>
              {{ article.field.firstParagraph }}
            </td>
          </tr>
          <tr>
            <th class="text-right">{{ $t('hp.article.flags') }}</th>
            <td>
              <app-flags :flag="article.flag"></app-flags>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
  </Modal>
</template>

<script>
import CoreApi from '../../api/core'
import Flags from '../article/ArticleFlags'
import Media from '../shared/Media'
import Preloader from '../preloader/Preloader'
import Modal from '../shared/Modal'
import ArticleUrlInfo from '../article/ArticleUrlInfo'
import ArticleStatus from '../article/ArticleStatus'

export default {
  name: 'InfoModal',
  props: {
    contentBlockItem: {
      type: Object
    }
  },
  components: {
    Modal,
    appFlags: Flags,
    appMedia: Media,
    appPreloader: Preloader,
    appArticleUrlInfo: ArticleUrlInfo,
    appArticleStatus: ArticleStatus
  },
  data () {
    return {
      article: {},
      dataLoaded: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    getArticleByDocumentId (documentId) {
      this.dataLoaded = false
      CoreApi().get('/article?view=expanded&filter_eq[documentId]=' + documentId)
        .then(response => {
          if (response.data.data.length > 0) {
            this.article = response.data.data[0]
            this.dataLoaded = true
          }
        })
        .catch(
          error => console.log(error)
        )
    }
  },
  created () {
    if (this.contentBlockItem.articleDocumentId) {
      this.getArticleByDocumentId(this.contentBlockItem.articleDocumentId)
    }
  }
}
</script>
