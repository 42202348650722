const messages = {
  siteName: 'Server',
  appId: 'AppId',
  apiKey: 'ApiKey',
  utm: 'Utm',
  limit: 'Limit',
  limit_placeholder: 'Info: 0 pre neobmedzený denný limit',
  reset: 'Reset',
  active: 'Aktívny',
  addSite: 'Pridať server'
}

export default messages
