<template>
  <app-module-view>
    <template slot="body">
      <section>
        <section class="m-b-20">
          <div class="row">
            <div class="col-sm-6">
              <app-tasr-news-create-article-buttons
                :tasrNews="tasrNews"
                @set-article-and-save="setArticleAndSave"
              >
              </app-tasr-news-create-article-buttons>
            </div>
            <div class="col-sm-6">
              <div class="text-right m-r-5">
                <app-button-close route-name="tasrNews_list"></app-button-close>
              </div>
            </div>
          </div>
        </section>
        <div class="row">
          <div class="col-lg-8">
            <div class="card card-body">
              <h3>{{ $t('article.main_content') }}</h3>
              <table class="table table-striped table-bordered">
                <tbody>
                <tr>
                  <th width="200">{{ $t('tasrNews.headline') }}</th>
                  <td>{{ tasrNews.headline }}</td>
                </tr>
                <tr>
                  <th>{{ $t('tasrNews.content') }}</th>
                  <td>{{ tasrNews.content }}</td>
                </tr>
                <tr v-show="tasrNews.status === 'failed'">
                  <th>{{ $t('tasrNews.raw') }}</th>
                  <td>{{ tasrNews.raw }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card card-body">
              <h3>{{ $t('article.main_settings') }}</h3>
              <table class="table table-striped table-bordered">
                <tbody>
                <tr>
                  <th>{{ $t('tasrNews.contentCreated') }}</th>
                  <td>{{ tasrNews.contentCreated | prettyDateTime }}</td>
                </tr>
                <tr>
                  <th>{{ $t('tasrNews.service') }}</th>
                  <td>{{ servicePretty(tasrNews.service) }}</td>
                </tr>
                <tr>
                  <th>{{ $t('tasrNews.category') }}</th>
                  <td>{{ categoryName(tasrNews.category) }}</td>
                </tr>
                <tr>
                  <th>{{ $t('tasrNews.author') }}</th>
                  <td>{{ tasrNews.author }}</td>
                </tr>
                <tr>
                  <th>{{ $t('tasrNews.slugline') }}</th>
                  <td v-html="tasrNews.slugline"></td>
                </tr>
                <tr>
                  <th>{{ $t('tasrNews.source') }}</th>
                  <td>{{ tasrNews.source }}</td>
                </tr>
                <tr>
                  <th>{{ $t('tasrNews.status') }}</th>
                  <td>{{ statusPretty(tasrNews.status) }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="card card-body">
              <h3 class="article-edit-header">{{ $t('tasrNews.created_articles') }}</h3>
              <table class="table table-striped table-bordered">
                <tbody>
                  <tr v-for="(article, index) in articles" :key="`item-${index}`">
                    <td>
                      <router-link
                        tag="a"
                        :title="$t('tasrNews.edit_article')"
                        :to="{ name: 'article_detail', params: { id: article.id }}"
                      >
                        {{ article.field.title }}
                      </router-link>
                    </td>
                    <td>{{ article.expanded.site.shortTitle }}</td>
                    <td>
                      <router-link
                        tag="a"
                        class="btn btn-default btn-sm"
                        :title="$t('tasrNews.edit_article')"
                        :to="{ name: 'article_detail', params: { id: article.id }}"
                      >
                        <i class="fa fa-info"></i>
                      </router-link>
                    </td>
                    <td>
                      <app-article-status
                        :article="article"
                      >
                      </app-article-status>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </template>
  </app-module-view>
</template>

<script>
import appModuleView from '../../components/ModuleView'
import appButtonClose from '../shared/ButtonClose'
import TasrNewsCreateArticleButtons from '@/components/tasr/TasrNewsCreateArticleButtons'
import appArticleStatus from '../article/ArticleStatus'
import TasrNewsModel from '../../model/TasrNews.js'
import NotifyService from '../../services/NotifyService'
import ValueObjectStatusService from '../../services/tasr/ValueObjectStatusService'
import ValueObjectServiceTypeService from '../../services/tasr/ValueObjectServiceTypeService'
import TasrNewsCategoryService from '../../services/tasr/TasrNewsCategoryService'
import CoreApi from '../../api/core'

export default {
  name: 'TasrNews',
  data () {
    return {
      tasrNews: TasrNewsModel,
      error: null,
      articles: []
    }
  },
  components: {
    appModuleView,
    appButtonClose,
    appArticleStatus,
    appTasrNewsCreateArticleButtons: TasrNewsCreateArticleButtons
  },
  methods: {
    getTasrNews () {
      this.$store.dispatch('tasrNews/fetchOne', this.$route.params.id)
        .then(() => {
          this.tasrNews = this.$store.getters['tasrNews/detail']
          this.fetchArticles()
        })
    },
    deleteTasrNews () {
      this.$store.dispatch('tasrNews/deleteRecord', this.tasrNews)
        .then(() => {
          if (this.$store.getters['tasrNews/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/tasrNews')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['tasrNews/error'])
          }
        })
        .catch(error => console.log(error))
    },
    statusPretty (data) {
      return (data) ? ValueObjectStatusService.getPrettyName(data) : ''
    },
    servicePretty (data) {
      return (data) ? ValueObjectServiceTypeService.getPrettyName(data) : ''
    },
    categoryName (data) {
      const category = (data) ? TasrNewsCategoryService.getCategoryById(data) : ''
      return category ? category.title : ''
    },
    setArticleAndSave (article) {
      this.tasrNews.articles.push({ articleDocumentId: article.documentId })
      this.tasrNews.status = 'processed'
      this.$store.dispatch('tasrNews/update', this.tasrNews)
    },
    editArticle () {
      this.$router.push('/article/' + this.article.id + '/edit')
    },
    fetchArticles () {
      const articleDocumentIds = []

      this.tasrNews.articles.forEach(article => {
        articleDocumentIds.push(article.articleDocumentId)
      })

      if (articleDocumentIds.length === 0) {
        return
      }

      const url = '/article?view=expanded&filter_in[documentId]=' + articleDocumentIds.toString()
      CoreApi().get(url)
        .then(response => {
          if (response.data.totalCount > 0) {
            this.articles = response.data.data
          }
        })
        .catch(error => console.log(error))
    }
  },
  mounted () {
    this.getTasrNews()
  },
  beforeMount () {
    this.$store.dispatch('tasrNews/fetchValueObjectServiceType')
    this.$store.dispatch('tasrNews/fetchValueObjectStatus')
    this.$store.dispatch('tasrNewsCategory/fetchAll')
  }
}
</script>

<style lang="scss" scoped>
h3 {
  color: #bbb;
  font-weight: 100;
}
</style>
