const messages = {
  siteName: 'Server',
  appId: 'AppId',
  apiKey: 'ApiKey',
  utm: 'Utm',
  limit: 'Limit',
  limit_placeholder: 'Info: 0 pro neomezený denní limit',
  reset: 'Reset',
  active: 'Aktivní',
  addSite: 'Přidat server'
}

export default messages
