import _ from 'lodash'
import blocksModel from '@/model/nmhEditor/blocksModel'
import { uuid } from 'vue-uuid'

const initialState = () => {
  return {
    disabled: false,
    blocksModel: _.cloneDeep(blocksModel),
    data: {
      blocks: []
    },
    dropdowns: {
      dropdownEssential: {
        isVisible: false
      }
    },
    isEditorEditable: true,
    itemsMenu: {
      horizontal: {
        nowHovered: ''
      }
    },
    lastClickedBlock: {
      index: null,
      position: {
        x: null,
        y: null
      },
      type: null
    },
    modals: {
      deleteBlock: {
        deleteAllBlocks: false,
        isVisible: false
      },
      selectBlock: {
        isVisible: false,
        replaceMode: false
      },
      link: {
        isVisible: false,
        selectedEditor: null
      },
      inlineQuote: {
        isVisible: false,
        selectedEditor: null
      },
      copyPaste: {
        isVisible: false
      },
      source: {
        isVisible: false
      },
      htmlIdAttr: {
        isVisible: false
      },
      thirdPartyEmbed: {
        isVisible: false,
        infographic: false
      }
    },
    // each of these blocks will always be as singleton - i.e. user cannot create more than one of each
    singletonBlocks: ['sourceBlock'],
    // these blocks will be locked in place, so use it only on blocks that are always first or last // it used to be ['sourceBlock']
    draggableDisabledBlocks: [],
    // blocks with change button
    replaceableBlocks: [
      'headingBlock', 'paragraphBlock', 'linkToGalleryBlock', 'orderedListBlock', 'quoteBlock', 'unorderedListBlock'
    ],
    companySpecificSettings: {
      headingLevels: {
        nmh: [2, 3],
        vlm: [2, 3, 4]
      },
      hiddenBlocks: {
        nmh: ['onlineSportMatchBlock', 'sportTableBlock', 'thirdPartyEmbedBlock', 'quizBlock', 'sourceBlock'],
        vlm: ['onlineSportMatchBlock', 'sportTableBlock', 'carTableBlock', 'codeBlock']
      },
      hiddenFormatOptions: {
        nmh: ['insertedTag', 'subscript', 'superscript'],
        vlm: ['underline']
      }
    },
    positionOfTheNewBlock: 'atTheEnd'
  }
}

const state = initialState

/**
 * Returns original htmlId if exists, otherwise generates a unique htmlId.
 * @param state the vuex state
 * @param block the body block
 * @param ignoreOriginal set to true to always generate new id
 * @returns {string} generated htmlId
 */
const generateUniqueHtmlId = (state, block, ignoreOriginal = false) => {
  if (!ignoreOriginal && block.properties.htmlId) {
    return block.properties.htmlId // keep original
  }
  let number = state.data.blocks.filter(b => b.properties.htmlId && b.type === block.type).length + 1
  const { type } = block
  let htmlId = ''
  let alreadyExists = false
  do {
    htmlId = `${type}-${number}`
    alreadyExists = state.data.blocks.find(b => b.properties.htmlId === htmlId)
    number += 1
  } while (alreadyExists)
  return htmlId
}

const mutations = {
  SET_BLOCKS (state, data) {
    if ('init' in data) {
      state.data.blocks = []
      const blocks = data.init.blocks ?? false
      if (blocks) {
        data.init.blocks.forEach(block => {
          block.properties.htmlId = generateUniqueHtmlId(state, block)
          state.data.blocks.push(block)
        })
      }
      state.disabled = data.init.disabled ?? false
    }
    if ('changeSingleBlockPosition' in data) {
      if (data.changeSingleBlockPosition.mobile) {
        const position = data.changeSingleBlockPosition.position
        const newIndex = data.changeSingleBlockPosition.newIndex
        const block = state.data.blocks[position]
        state.data.blocks.splice(position, 1)
        state.data.blocks.splice(newIndex, 0, block)
      }
      state.data.blocks.forEach((item, index) => {
        item.position.y = index
      })
    }
    if ('changeCurrentBlockType' in data) {
      const thisBlockWIllBeAdded = data.changeCurrentBlockType
      const thisBlockWillBeReplacedAndDeleted = state.data.blocks[state.lastClickedBlock.index]
      if (
        Object.prototype.hasOwnProperty.call(thisBlockWIllBeAdded.properties, 'text') &&
        Object.prototype.hasOwnProperty.call(thisBlockWillBeReplacedAndDeleted.properties, 'text')
      ) {
        thisBlockWIllBeAdded.id = uuid.v4() // Adding a new uuid
        thisBlockWIllBeAdded.properties.text = state.data.blocks[state.lastClickedBlock.index].properties.text
        state.data.blocks.splice(thisBlockWillBeReplacedAndDeleted.position.y, 1, thisBlockWIllBeAdded)
      }
      if (
        Object.prototype.hasOwnProperty.call(thisBlockWIllBeAdded.properties, 'items') &&
        Object.prototype.hasOwnProperty.call(thisBlockWillBeReplacedAndDeleted.properties, 'text')
      ) {
        thisBlockWIllBeAdded.id = uuid.v4() // Adding a new uuid
        thisBlockWIllBeAdded.properties.items.push(state.data.blocks[state.lastClickedBlock.index].properties.text)
        state.data.blocks.splice(thisBlockWillBeReplacedAndDeleted.position.y, 1, thisBlockWIllBeAdded)
      }
      if (
        Object.prototype.hasOwnProperty.call(thisBlockWIllBeAdded.properties, 'text') &&
        Object.prototype.hasOwnProperty.call(thisBlockWillBeReplacedAndDeleted.properties, 'items')
      ) {
        thisBlockWIllBeAdded.id = uuid.v4() // Adding a new uuid
        thisBlockWIllBeAdded.properties.text = state.data.blocks[state.lastClickedBlock.index].properties.items.join()
        state.data.blocks.splice(thisBlockWillBeReplacedAndDeleted.position.y, 1, thisBlockWIllBeAdded)
      }
      if (
        Object.prototype.hasOwnProperty.call(thisBlockWIllBeAdded.properties, 'items') &&
        Object.prototype.hasOwnProperty.call(thisBlockWillBeReplacedAndDeleted.properties, 'items')
      ) {
        thisBlockWIllBeAdded.id = uuid.v4() // Adding a new uuid
        thisBlockWIllBeAdded.properties.items = state.data.blocks[state.lastClickedBlock.index].properties.items
        state.data.blocks.splice(thisBlockWillBeReplacedAndDeleted.position.y, 1, thisBlockWIllBeAdded)
      }
    }
    if ('setNewBlock' in data) {
      const newBlock = data.setNewBlock
      newBlock.properties.htmlId = generateUniqueHtmlId(state, newBlock)
      newBlock.id = uuid.v4()
      const blocksLength = state.data.blocks.length
      const creatingSingletonBlock = state.singletonBlocks.includes(newBlock.type)
      const existingSingletonBlock = state.data.blocks.find(block => state.singletonBlocks.includes(block.type))
      if (state.positionOfTheNewBlock === 'atTheEnd') {
        state.positionOfTheNewBlock = blocksLength
      }
      if (creatingSingletonBlock && existingSingletonBlock) {
        // keep the position and do not create a new block
        state.positionOfTheNewBlock = existingSingletonBlock.position.y
      } else {
        // Add a new block to a specific position
        state.data.blocks.splice(state.positionOfTheNewBlock, 0, newBlock)
      }
      newBlock.position.y = state.positionOfTheNewBlock
      state.lastClickedBlock.index = newBlock.position.y
      state.lastClickedBlock.position.x = newBlock.position.x
      state.lastClickedBlock.position.y = newBlock.position.y
      state.lastClickedBlock.type = newBlock.type
      if (newBlock.type === 'sourceBlock') {
        state.modals.source.isVisible = true
      }
      setTimeout(() => {
        const lastAddedBlock = document.querySelector(
          `.nmh-editor .block-container[data-index="${newBlock.position.y}"]`
        )
        lastAddedBlock.scrollIntoView({ behavior: 'smooth', block: 'center' })
        try {
          lastAddedBlock.querySelector('.ProseMirror').focus({ preventScroll: true })
        } catch (e) {
          return false
        }
      }, 120)
    }
    if ('deleteAllBlocks' in data) {
      state.data.blocks = []
    }
    if ('deleteSingleBlock' in data) {
      state.data.blocks.splice(state.lastClickedBlock.index, 1)
      state.data.blocks.forEach((item, index) => {
        item.position.y = index
      })
    }
    if ('deleteSingleBlockProgrammatically' in data) {
      state.data.blocks.splice(data.deleteSingleBlockProgrammatically, 1)
      state.data.blocks.forEach((item, index) => {
        item.position.y = index
      })
    }
  },
  SET_BLOCK_PROPERTIES (state, { index, property, data, type }) {
    const block = state.data.blocks[index]
    block.properties[property] = data
    if (type && block.type !== type) {
      block.type = type
      // when changing the type, we always need a new htmlId
      block.properties.htmlId = generateUniqueHtmlId(state, block, true)
    }
  },
  SET_DROPDOWN_ESSENTIAL (state, data) {
    state.dropdowns.dropdownEssential.isVisible = data === undefined ? !state.dropdowns.dropdownEssential.isVisible : data
  },
  SET_EDITOR_EDITABLE_TOGGLE (state) {
    state.isEditorEditable = !state.isEditorEditable
  },
  SET_LAST_CLICKED_BLOCK (state, { index, positionX, positionY, type }) {
    state.lastClickedBlock.index = index
    state.lastClickedBlock.position.x = positionX
    state.lastClickedBlock.position.y = positionY
    state.lastClickedBlock.type = type
  },
  SET_MODAL_DELETE_BLOCK (state, { isVisible, deleteAllBlocks }) {
    if (isVisible !== undefined) {
      state.modals.deleteBlock.isVisible = isVisible
    }
    if (deleteAllBlocks !== undefined) {
      state.modals.deleteBlock.deleteAllBlocks = deleteAllBlocks
    }
  },
  SET_MODAL_SELECT_BLOCK (state, data) {
    state.modals.selectBlock.isVisible = data
  },
  SET_MODAL_COPY_PASTE (state, data) {
    state.modals.copyPaste.isVisible = data
  },
  SET_MODAL_SOURCE (state, data) {
    state.modals.source.isVisible = data
  },
  SET_MODAL_LINK (state, data) {
    state.modals.link.isVisible = data
  },
  SET_MODAL_LINK_EDITOR (state, data) {
    state.modals.link.selectedEditor = data
  },
  SET_MODAL_INLINE_QUOTE (state, isVisible) {
    state.modals.inlineQuote.isVisible = isVisible
  },
  SET_MODAL_INLINE_QUOTE_EDITOR (state, selectedEditor) {
    state.modals.inlineQuote.selectedEditor = selectedEditor
  },
  SET_MODAL_HTML_ID_ATTR (state, data) {
    state.modals.htmlIdAttr.isVisible = data
  },
  SET_MODAL_SELECT_BLOCK_REPLACE_MODE (state, data) {
    state.modals.selectBlock.replaceMode = data
  },
  SET_MODAL_THIRD_PARTY_EMBED (state, data) {
    state.modals.thirdPartyEmbed.isVisible = data.isVisible
    state.modals.thirdPartyEmbed.infographic = data.infographic
  },
  SET_POSITION_OF_THE_NEW_BLOCK (state, data) {
    if (data === 'atTheEnd') {
      state.positionOfTheNewBlock = state.data.blocks.length
      return
    }
    state.positionOfTheNewBlock = parseInt(data) + 1
  },
  SET_ITEMS_MENU_HORIZONTAL_NOW_HOVERED (state, data) {
    state.itemsMenu.horizontal.nowHovered = data
  }
}

const actions = {
  generateUniqueHtmlId (store, block) {
    return generateUniqueHtmlId(store.state, block, true)
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
