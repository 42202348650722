<template>
  <div class="detail-info">
    <span v-if="article.author">
      {{ article.author }}
    </span>
    <span>
      {{ article.firstPublishedOrderDate | prettyDateTime }}
    </span>
    <span
      v-if="article.imageCount >= 0"
      :title="$t('dashboard.tooltip.picture_count')"
      class="detail-info__count-number"
    >
      <ImageCountIcon class="detail-info__count-number__icon" /> {{ article.imageCount }}
    </span>
    <span
      v-if="article.videoCount >= 0"
      :title="$t('dashboard.tooltip.video_count')"
      class="detail-info__count-number"
    >
      <VideoCountIcon class="detail-info__count-number__icon" /> {{ article.videoCount }}
    </span>
    <span
      class="badge"
      :class="getSiteBadgeClass(article.brand)"
    >
      {{ getSiteTitle(article.brand) }}
    </span>
    <span
      v-if="article.status && article.status.toLowerCase() !== 'none'"
      @mouseenter="loadAbTesting(article)"
    >
      <span class="detail-info__ab-testing">
        <span class="detail-info__ab-testing__label">
          {{ `A/B` }}
          <span class="detail-info__ab-testing__status">
            {{ translateAbStatus(article) }}
          </span>
        </span>
        <Tooltip
          :fixed-width="false"
          color="#6599FE"
          class="detail-info__ab-testing__tooltip"
        >
          <div
            v-if="currentAbResult && currentAbResult.documentId === article.documentId"
            class="detail-info__ab-testing__tooltip-content"
          >
            <div class="detail-info__ab-testing__heading">
              <div>{{ `AB Testing` }}</div>
              <div>
                <span class="detail-info__ab-testing__heading__status-label">{{ `${$t('dashboard.status')}:` }}</span>
                {{ translateAbStatus(currentAbResult) }}
              </div>
            </div>
            <table class="detail-info__ab-testing__variants">
              <tr>
                <th>{{ '' }}</th>
                <th>{{ $t('dashboard.impressions') }}</th>
                <th>{{ $t('dashboard.clicks') }}</th>
                <th>{{ $t('dashboard.ctr') }}</th>
              </tr>
              <tr
                v-for="(variant, index) in currentAbResult.variants"
                :key="`article-${index}`"
              >
                <td class="detail-info__ab-testing__variants__name">
                  {{ $t('dashboard.variant') }}
                  {{ variant.name | capitalize }}
                </td>
                <td> {{ variant.impressions | formatNumber }}</td>
                <td> {{ variant.clicks | formatNumber }}</td>
                <td> {{ variant.conversionRate | formatPercentage }}</td>
              </tr>
            </table>
          </div>
        </Tooltip>
      </span>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { dataHubAbnApi } from '@/api/datahub'
import ImageCountIcon from '@/assets/img/svg/image-count.svg?inline'
import VideoCountIcon from '@/assets/img/svg/video-count.svg?inline'
import Tooltip from '@/components/tooltip/Tooltip'

export default {
  name: 'RealtimeStatisticsArticleInfoColumn',
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  components: {
    ImageCountIcon,
    VideoCountIcon,
    Tooltip
  },
  data () {
    return {
      currentAbResult: null
    }
  },
  computed: {
    ...mapGetters({
      getSiteTitle: 'site/getSiteTitle'
    })
  },
  methods: {
    translateAbStatus (article) {
      return this.$t(`dashboard.ab_status_${article.status.toLowerCase()}`)
    },
    getSiteBadgeClass (brand) {
      return 'badge-' + brand
    },
    async loadAbTesting (article) {
      dataHubAbnApi().get('/result?documentId=' + article.documentId)
        .then((res) => {
          this.currentAbResult = res.data
        })
        .catch(error => {
          this.currentAbResult = null
          console.error(error)
        })
    }
  }
}
</script>
<style lang="scss">
.detail-info {
  @include font(400 14px "Roboto");
  color: #8490A7;
  span {
    padding-right: rem(10px);
  }
  &__count-number {
    @include font(700 12px "Roboto");
    color: #8A96AC;
    &__icon {
      path {
        fill: #D3DCEC;
      }
    }
  }
  &__ab-testing {
    @include font(400 14px "Roboto");
    padding-left: rem(10px);
    &__tooltip {
      top: rem(-3px);
      right: rem(17px);
      &-content {
        color: #465674;
      }
    }
    &__label {
      @include font(700 13px "Roboto");
    }
    &__status {
      padding-left: rem(3px);
      @include font(400 13px "Roboto");
    }
    &__heading {
      @include font(700 13px "Roboto");
      display: flex;
      justify-content: space-between;
      margin-bottom: rem(10px);
      &__status-label {
        @include font(400 13px "Roboto");
        padding-right: 0 !important;
      }
    }
    &__variants {
      @include font(400 14px "Roboto");
      table, td, th {
        background-color: #FAFAFC;
        padding: rem(4px);
        min-width: rem(75px);
      }
      &__name, th {
        @include font(700 13px "Roboto");
      }
    }
  }
}

.badge-plus1 {
  background-color: #1693c1;
  color: #fff;
}

.badge-plus7 {
  background-color: #0064ad;
  color: #fff;
}

.badge-emma {
  background-color: #c19d6a;
  color: #fff;
}

.badge-sport24 {
  background-color: #00c129;
  color: #fff;
}

.badge-sarm {
  background-color: #b7c100;
  color: #fff;
}

.badge-polovnictvoarybarstvo {
  background-color: #b3a981;
  color: #fff;
}

.badge-autobild {
  background-color: #e32021;
  color: #fff;
}

.badge-ncpz {
  background-color: #ec1d24;
  color: #fff;
}

.badge-eva {
  background-color: #000000;
  color: #fff;
}

.badge-zivot {
  background-color: #d90429;
  color: #fff;
}
</style>
